import React, { useState } from 'react';
import {
    Modal, Form, List, Button, Message
} from 'semantic-ui-react';
import axios from 'axios';
import qs from 'qs';
import { getCookieValue } from '../../../../utils';

import PropTypes from 'prop-types';
export const EditUserModal = ({ open, handleClose, selectedModalRow,
    groups, changePermissionCallback, handleDelete, match: { params }, userInfo, updateOwnerCallback, }) => {
    let [permissionValue, setPermissionValue] = useState('')
    let [error, setError] = useState('')
    let [submitting, setSubmitting] = useState(false)

    const unfocus = () => {
        setPermissionValue('')
        handleClose()
    }

    const filterGroups = (groups) => {
        let filtered = groups.filter(item => item.name.includes('Project'))
        if (filtered) {
            return filtered.map((val) => (
                {
                    key: val.id,
                    value: val.id,
                    text: val.name,
                    permissions: val.permissions
                }
            ))
        } else {
            setError('No groups returned, please contact your administrator')
            return []
        }
    }

    const handleChange = (e, arg) => {
        setPermissionValue(arg.value)
    }

    const submit = () => {
        const updateUserId = selectedModalRow.id
        const { pid } = params
        const group_id = permissionValue

        if (pid && group_id && updateUserId) {
            const cookieValue = getCookieValue('csrftoken');
            setSubmitting(true)
            axios({ //Update user group to new change
                method: 'put',
                url: `/api/projects/${pid}/update-user/${updateUserId}/`,
                data: qs.stringify({
                    'group_id': group_id
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": cookieValue,
                }
            }).then((res) => {
                res?.data?.group_id === 2 ? updateOwnerCallback(res.data) : updateOwnerCallback({})
                setSubmitting(false)
                changePermissionCallback(res.data)
                unfocus()

            })
                .catch((err) => {
                    setSubmitting(false)
                    setError('Error: failed backend update request, please contact your administrator')
                })
        } else {
            setError('Error: variable missing from PUT request, please contact your administrator')
        }
    }

    const disabledPermissions = (permissionValue, initValue, userIsEditingOwn) => {
        const disableConditions = [
            !permissionValue,
            initValue === permissionValue, // ? true : false
            userIsEditingOwn
        ]
        if (disableConditions.indexOf(true) === -1)
            return false
        return true
    }

    const del = () => {
        handleDelete(selectedModalRow)
        handleClose()
    }

    const renderCurrentUserMessage = () => {
        return (
            <Message
                error
                header='Notice:'
                content='Editing your own permissions will result in the inability to access certain portal functionality, this option is currently disabled'
            />
        )
    }
    const filteredGroups = filterGroups(groups)
    const initValue = filteredGroups.find((val) => val.text === selectedModalRow.user_job)?.value //Value for role dropdown on initial render
    const userIsEditingOwn = userInfo?.data?.id === selectedModalRow.id ? true : false //If a user is editing their own permissions, disable

    return (
        <Modal size='large' closeIcon open={open} onClose={unfocus} >
            <Modal.Header>Edit User Role <Button color='red' floated='right' content='Remove user' onClick={del} /></Modal.Header>
            <Modal.Content>
                {userIsEditingOwn ? renderCurrentUserMessage() : ''}
                {error ? <Message error content={error} /> : ''}
                <List divided relaxed>
                    <List.Item>
                        <List.Icon name='user' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>Name</List.Header>
                            <List.Description>{selectedModalRow.name}</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='address card' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header >User Name</List.Header>
                            <List.Description as='a'>{selectedModalRow.username}</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='mail outline' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>Email</List.Header>
                            <List.Description as='a'>{selectedModalRow.email}</List.Description>
                        </List.Content>
                    </List.Item>
                </List>
                <br />
                <Form>
                    <Form.Field>
                        <label>User Role (Permissions)</label>
                        <Form.Dropdown
                            width={6}
                            selection
                            value={permissionValue ? permissionValue : initValue}
                            options={filteredGroups}
                            onChange={handleChange}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    content={'Change'}
                    disabled={disabledPermissions(permissionValue, initValue, userIsEditingOwn)}
                    onClick={!submitting ? submit : undefined}
                    loading={submitting}

                />
            </Modal.Actions>
        </Modal>
    )
}

EditUserModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    groups: PropTypes.array,
    userInfo: PropTypes.object, //from init props
    changePermissionCallback: PropTypes.func,
    updateOwnerCallback: PropTypes.func
}