import React, { Component } from 'react';
import axios from 'axios';
import { Button, Card, Dimmer, Loader, Icon, Segment, Message } from 'semantic-ui-react';
import DataTable from 'react-data-table-component'
import { UserModal } from './Modals'
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { getCookieValue, userInfo } from '../../../utils';
import { TemplateNotification } from '../../TemplateNotification';
import { EditUserModal } from './EditUserModal';
import { TableLoader } from '../../TableLoader';
import { NoDataComponent } from '../../NoDataComponent';

class TabUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            /*  full response objects from axios  */
            users: [],
            groups: [],
            /*  modal control states  */
            add_open: false,
            edit_modal_open: false,
            /*  DataTable information  */
            tableData: [],
            loading: true,

            editPermission: false, //whether the user can edit other users

            selectedModalRow: '',
            error: ''
        }
    }
    useNull = () => null

    componentDidMount() {
        var { pid } = this.props.match.params
        axios //fetch users that have access & all groups
            .all([
                axios.get(`/api/projects/${pid}/users/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/groups/`, { withCredentials: true }).catch(this.useNull)
            ]).then(axios.spread((users, groups) => { //will block until both requests are complete
                if (users && groups)
                    this.manageState(users, groups)
                else
                    this.setState({
                        tableData: [], loading: false,
                        groups: []
                    })
            }))
    }

    /**
     * Manages state of dataTable, and permissions
     * @param {Object} - users - JSON object returned from /api/projects/x/users/
     * @param {Object} - groups - JSON object returned from /api/groups/
     */
    manageState = (users, groups) => {
        try {
            const tableData = this.filterUserTable(users, groups)
            const editPermission = this.checkEditPermissions(users)
            this.setState({
                tableData: editPermission ? tableData : [],
                groups: groups.data.results,
                editPermission: editPermission,
                loading: false,
            })

        } catch (err) {
            console.log(err)
        }
    }

    checkEditPermissions = (users) => {
        const currentUser = this.props?.userInfo?.data
        if (currentUser.is_admin) {
            return true
        } else {
            if (currentUser.id) {
                const permissionObject = users?.data.find((val) => val.user_id === currentUser.id)
                if (permissionObject && ((permissionObject.group_id === 3) || permissionObject.group_id === 2)) //User has edit permissions, as Admin role
                    return true
            }
            return false
        }
    }

    filterUserTable = (users, groups) => {
        let data = users?.data
        let tableData = []
        for (let i = 0; i < data.length; i++) { //Set tableData state
            let row = this.createTableRow(data[i], groups.data.results)
            tableData.push(row)
        }
        return tableData
    }

    /**
     * Changes modal state to open/closed
     * @param value - which modal state to target
     */
    displayModal = (e, { value }) => {
        if (this.state[value])
            this.setState({ [value]: false })
        else
            this.setState({ [value]: true })
    }

    /* *
     * Callback function that gets run when user is successfully added to project via POST, Updates table state
     * @param {Object} data - response object returned from POST to /api/projects/x/add-user/
     * */
    addUserCallback = (data) => {
        let row = this.createTableRow(data)
        this.props.updateOwnerCallback(data)
        //append new user to current table state
        this.setState({ add_open: false, tableData: [...this.state.tableData, row] })
    }

    /**
     * Callback from EditUserModal, Changes table state to correct user permissions
     * @param {Object} data
     */
    changePermissionCallback = (data) => {
        let { tableData } = this.state
        let newTableData = [...tableData] //Create new state array for copy
        let index = newTableData.findIndex((val) => val.id === data.user_id)
        newTableData[index] = this.createTableRow(data)
        this.setState({ tableData: newTableData })
    }

    /**
     * Creates a table row from data object to be used in DataTable
     * @param {Object} data
     * @returns {Object} tableRow
     */
    createTableRow = (data, groups) => {
        if (!groups)
            groups = this.state.groups

        return {
            id: data.user_id,
            user_job: groups.find(val => val.id === data.group_id).name,
            username: data?.user.username ? data.user.username : '',
            name: data?.user ? (data.user.first_name + " " + data.user.last_name) : '',
            email: data?.user.email ? data.user.email : ''
        }
    }

    /**
     * Function that closes the message upon x
     */
    handleDismiss = () => {
        this.setState({ error: '' })
    }

    handleEditModal = (e) => {
        if (e && 'id' in e) { // User is clicking a row, e is the row object
            // this.state.edit_modal_open ? this.setState({ edit_modal_open: false, selectedModalRow : }) : this.setState({ edit_modal_open: true })
            this.setState({ selectedModalRow: e, edit_modal_open: true })
        } else { // User is closing window, e is the mouse cursor event
            // this.state.edit_modal_open ? this.setState({ edit_modal_open: false }) : this.setState({ edit_modal_open: true })
            this.setState({ edit_modal_open: false })
        }

    }

    /**
     * Function that deletes a user given a Datatable row
     * @param {Object} e - event object
     * @param {Object} row - row object passed from Datatable
     */
    handleDelete = (row) => {
        const { data: currentUser } = userInfo
        const userId = row.id
        const cookieValue = getCookieValue('csrftoken');
        var { pid } = this.props.match.params
        const denialConditions = [
            currentUser.id === userId,
            this.state.tableData.length === 1
        ]
        if (denialConditions.indexOf(true) !== -1) { //WIP, dont allow users to remove self currently
            if(denialConditions[0])
                this.setState({ error: 'Cannot delete yourself from a project in current version' })
            else
                this.setState({ error: 'A project has to have at least one user at all times' })
        } else {
            if (userId && pid) {
                this.setState({ loading: true })
                axios
                    .delete(`/api/projects/${pid}/delete-user/${userId}/`, {
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded',
                            "X-CSRFToken": cookieValue,
                        }
                    })
                    .then(res => {
                        this.updateTable(userId)
                        this.setState({ loading: false })
                        this.props.updateOwnerCallback({})

                    })
                    .catch(err => {
                        this.setState({ error: err.message })
                        this.setState({ loading: false })
                    })
            } else {
                this.setState({ error: `Unable to delete user ${row.name}` })
            }
        }
    }

    /**
     * Function that updates tableState on delete of a user
     * @param {Integer} userId - users id
     */
    updateTable = (userId) => {
        let { tableData } = this.state
        let filter = tableData.filter((obj) => obj.id !== userId)
        this.setState({ tableData: filter })
    }

    /**
     * Function that returns the User Modal to render
     */
    renderUserModal = () => {
        const { groups, add_open } = this.state

        return (
            <UserModal {...this.props}
                groups={groups}
                add_open={add_open}
                addUserCallback={this.addUserCallback}
                displayModal={this.displayModal}
            />
        )
    }

    getConditionalRowStyles = memoize(() => [
        {
            when: row => row.user_job === "Pending Users",
            style: {
                backgroundColor: 'rgba(241, 231, 39, 0.94)',
                color: 'black',
            }
        },
        {
            when: row => row.name,
            style: {
                cursor: 'pointer'
            }
        }
    ])

    correctCapitalization = (name) => {
        return name.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

    }

    renderButtonGroup = (row) => {
        return (
            <Button {...row} size='tiny' icon='edit' onClick={() => this.handleEditModal(row)} />
        )
    }

    getColumns = memoize(() => [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
            maxWidth: '800px',
            cell: (row) => this.correctCapitalization(row.name)
        },
        {
            name: 'Role',
            selector: row => row.user_job,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            cell: (row) => this.state.editPermission ? this.renderButtonGroup(row) : '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ])

    render() {
        const { loading, add_open, error,
            tableData, editPermission, selectedModalRow,
            edit_modal_open, groups } = this.state
        const subHeader = (
            <div>
                <Button
                    floated='right'
                    icon
                    labelPosition='left'
                    primary
                    size='small'
                    value='add_open'
                    onClick={this.displayModal}
                >
                    <Icon name='plus' /> Add User
                </Button>
            </div>
        )

        return (
            <>
                <Segment attached='bottom'>
                    <TemplateNotification
                        type='user_tab'
                    />
                    {this.state.error ? <Message color='red' onDismiss={this.handleDismiss} content={error} /> : ""}

                    <DataTable
                        title="Project Members"
                        columns={this.getColumns()}
                        data={tableData}
                        conditionalRowStyles={this.getConditionalRowStyles()}
                        persistTableHead
                        fixedHeader={true}
                        fixedHeaderScrollHeight="300px"
                        progressComponent={<TableLoader loadingText='Loading...' active={loading} hg='200px' />}
                        progressPending={loading}
                        noDataComponent={<NoDataComponent text='You currently do not have permission to view or edit users in this project' />}
                        subHeaderComponent={editPermission ? subHeader : undefined}
                        subHeader={editPermission}

                    />
                    {add_open ? this.renderUserModal() : ''}

                </Segment>
                {
                    editPermission && selectedModalRow &&
                    <EditUserModal
                        open={edit_modal_open}
                        handleClose={this.handleEditModal}
                        selectedModalRow={selectedModalRow}
                        groups={groups}
                        changePermissionCallback={this.changePermissionCallback}
                        handleDelete={this.handleDelete}

                        {...this.props}
                    />
                }

            </>
        )
    }
}


export default TabUsers;

TabUsers.propTypes = {
    match: PropTypes.object,
    updateOwnerCallback: PropTypes.func
}
