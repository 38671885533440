import React, {Component} from 'react';
import memoize from 'memoize-one';
import axios from 'axios';
import {Message, Card, Input, Button, Checkbox, Icon, Segment, Grid} from 'semantic-ui-react';
import {getCookieValue} from '../../../utils';
import qs from 'qs';
import PropTypes from 'prop-types';
import * as _ from 'underscore';
import './TabRedcap.css';
import {UnlinkedRedcap} from './Modals';
import {BasicTable} from '../../BasicTable';
import {TemplateNotification} from '../../TemplateNotification';
import {TableLoader} from '../../TableLoader';
import {NoDataComponent} from '../../NoDataComponent';

class TabRedcap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            unlinked_projects: [],
            linked_projects: [],
            filtered_projects: [],
            filtered_projects_unlinked: [],
            error: '',
            modalOpen: false
        }
    }

    componentDidMount() {
        let {pathname} = this.props.history.location
        let pid = pathname.split('/')[2]
        axios
            .get(`/api/projects/${pid}/redcap/`, {withCredentials: true})
            .then(res => this.filterRedcapTable(res.data))
            .catch(err => this.setState({error: err.message, loading: false}))
    }

    /**
     * Function that runs when clicking tab to link a project, POST updated redcap projects
     * @param arg : object containing
     *      pid - project id of corresponding switch
     *      value - which switch is selected based on tabular index
     */
    onSwitch = (e, {pid, checked}) => {
        var {linked_projects, unlinked_projects, filtered_projects} = this.state
        const cookieValue = getCookieValue('csrftoken');
        const {pathname} = this.props.history.location
        const projectId = pathname.split('/')[2]
        let newLinked = [...linked_projects]
        let newUnlinked = [...unlinked_projects]
        let newFiltered = [...filtered_projects]
        let index

        if (checked) { //Switch flipped to linked
            index = unlinked_projects.findIndex((obj) => obj.project_id === pid)
            unlinked_projects[index].linked = true

            axios({
                method: 'post',
                url: `/api/projects/${projectId}/attach-redcap/`,
                data: qs.stringify({
                    redcap_project_id: unlinked_projects[index].project_id,
                    redcap_instance: unlinked_projects[index].instance,
                    redcap_project_name: unlinked_projects[index].project_name
                }),
                headers: {
                    "X-CSRFToken": cookieValue,
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then(() => {
                    newLinked.push(unlinked_projects[index]) //add switched project to unlinked list
                    newFiltered.push(unlinked_projects[index])
                    newUnlinked.splice(index, 1) //remove from visuals
                    this.setState({
                        linked_projects: newLinked,
                        filtered_projects: newFiltered,
                        unlinked_projects: newUnlinked
                    })
                })
                .catch(err => console.log(err))
        } else { //unlinked
            index = linked_projects.findIndex((obj) => obj.project_id === pid)

            linked_projects[index].linked = false
            axios({
                method: 'delete',
                url: `/api/projects/${projectId}/detach-redcap/${pid}/`,
                headers: {
                    "X-CSRFToken": cookieValue,
                },

            })
                .then(() => {
                    newUnlinked.push(linked_projects[index]) //add switched project to unlinked list
                    newLinked.splice(index, 1) //remove from visuals
                    newFiltered.splice(index, 1)

                    this.setState({
                        linked_projects: newLinked,
                        filtered_projects: newFiltered,
                        unlinked_projects: newUnlinked
                    })
                })
                .catch((err) => {
                    this.setState({
                        error: err.response.data.message
                    })
                })
        }


    }

    handleModalState = () => {
        this.state.modalOpen ? this.setState({modalOpen: false}) : this.setState({modalOpen: true})
    }

    /**
     * Function that updates state based on a list of redcap projects in Array format
     * @param {Object} response - Object containing response data of redcap projects returned from get/api/projects/x/redcap
     */
    filterRedcapTable = (response) => {
        try {
            let linked = [];
            let unlinked = [];

            // use this to check if project was checked
            let ids = [];

            for (const instance in response) {
                for (const user in response[instance]) {
                    for (let project of response[instance][user]) {
                        if (ids.indexOf(project.project_id) !== -1) {
                            continue;
                        }

                        project['main'] = user
                        project['instance'] = instance
                        project.linked ? linked.push(project) : unlinked.push(project)
                        ids.push(project.project_id)
                    }
                }
            }

            this.setState({
                linked_projects: linked,
                filtered_projects: linked,
                unlinked_projects: unlinked,
                filtered_projects_unlinked: unlinked,
                loading: false
            })
        } catch (err) {
            this.setState({error: err.message, loading: false})
        }
    }

    /**
     * Renders footer botton of card content
     * @return {Component} - Footer
     */
    displayFooter = () => {
        const {linked_projects, unlinked_projects} = this.state
        return (
            <div style={{paddingLeft: '16px'}}>
                <Icon name='folder open'/>Total Unlinked Projects Found : {unlinked_projects.length} &nbsp;
                <Icon style={{marginLeft: '16px'}} name='folder'/>Current Linked Projects : {linked_projects.length}
            </div>
        )
    }

    /**
     * Sorts given JSON table data by field name
     * @param {Object} rows - row data
     * @param {String} field - field name to sort by
     * @param {String} direction - ascending or descending
     */
    customSort = (rows, field, direction) => {
        var sorted = []
        switch (field) {
            case 'project_name':
                sorted = _.sortBy(rows, (obj) => obj.project_name.toUpperCase())
                break
            case 'project_id':
                sorted = _.sortBy(rows, (obj) => parseInt(obj.project_id))
                break
            case 'record_count':
                sorted = _.sortBy(rows, (obj) => parseInt(obj.record_count))
                break
            case 'last_logged_event':
                sorted = _.sortBy(rows, (obj) => new Date(obj.last_logged_event).getTime())
                break
            case 'linked':
                sorted = _.sortBy(rows, (obj) => obj.linked === true)
                break;
            case 'main':
                sorted = _.sortBy(rows, (obj) => obj.main.toUpperCase())
                break;
            case 'rma_total':
                sorted = _.sortBy(rows, (obj) => parseInt(obj.rma_total))
                break;
            default: //gets triggered on startup call, show linked projects first
                sorted = _.sortBy(rows, (obj) => obj.linked !== true)
                break
        }

        if (direction === 'asc') //to switch between ascending and descending sort options
            return sorted
        else
            return sorted.reverse()
    }

    searchTable = (e, arg) => {
        if (arg.name === 'linked') {
            const filterText = e.target.value.toLowerCase()
            const filtered = this.state.linked_projects.filter(entry => entry.project_name && entry.project_name.toLowerCase().includes(filterText))
            this.setState({filtered_projects: filtered})
        } else {
            const filterText = e.target.value.toLowerCase()
            const filtered = this.state.unlinked_projects.filter(entry => entry.project_name && entry.project_name.toLowerCase().includes(filterText))
            this.setState({filtered_projects_unlinked: filtered})
        }

    }

    // Memoized functions that provide static values are cached to prevent re-rendering on each refresh

    getColumns = memoize(() => [
        {
            name: 'Link',
            selector: row => row.linked,
            cell: row => <Checkbox style={{zIndex: '0'}} className="checkBox" checked={row.linked} pid={row.project_id}
                                   toggle
                                   onClick={this.onSwitch}/>,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Project Name',
            selector: row => row.project_name,
            sortable: true,
            grow: 3
            // width: '400px'
            // minWidth: '400px'
        },
        {
            name: 'Project Id',
            sortable: true,
            cell: row => <a target='_blank'
                            href={`https://redcap.stanford.edu/redcap_v12.5.7/index.php?pid=${row.project_id}`}>{row.project_id}</a>
            // width: '100px'
        },
        {
            name: 'Project Status',
            selector: row => row.project_status,
            sortable: true,
            // width: '100px'
        },
        {
            name: 'REDCap Instance',
            selector: row => row.instance,
            sortable: true,
            hide: 'lg'
        },        {
            name: 'Main user',
            selector: row => row.main,
            sortable: true,
            hide: 'lg'
        },
        {
            name: 'Record count',
            selector: row => row.record_count,
            sortable: true,
            hide: 'md'
            // width: '100px'
        },
        {
            name: 'Last update',
            selector: row => row.last_logged_event,
            sortable: true,
            hide: 'lg'
            // width: '175px'
        },
        {
            name: 'Total Cost',
            selector: row => row.rma_total,
            sortable: true,
            cell: row => row.rma_total ? row.rma_total : 'N/A',
            hide: 'lg'
        }
    ])


    getConditionalRowStyles = memoize(() => [
        {
            when: row => row.project_status === "Production" && row.rma_total > 0,
            style: {
                backgroundColor: 'rgba(241, 231, 39, 0.94)',
                color: 'black',
            }
        }
    ])

    getSubHeader = memoize(() =>
        <div>
            <Input
                onChange={this.searchTable}
                placeholder='Search by Project Name ...'
                icon='search'
                name='linked'
            />
        </div>
    )

    getSubHeaderUnlink = memoize(() =>
        <div>
            <Input
                onChange={this.searchTable}
                placeholder='Search by Project Name ...'
                icon='search'
                name='unlinked'
            />
        </div>
    )

    render() {
        const {loading, filtered_projects} = this.state
        return (
            <>
                <Segment attached='bottom'>
                    {this.state.error ? <Message negative size='tiny'>{this.state.error}</Message> : ''}
                    <TemplateNotification
                        type='redcap_tab'
                    />
                    <Grid.Column floated='right'>
                        <Button
                            floated='right'
                            icon
                            labelPosition='left'
                            primary
                            size='small'
                            value='add'
                            onClick={this.handleModalState}
                        >
                            <Icon name='add circle'/> New Link
                        </Button>
                    </Grid.Column>
                    <BasicTable
                        title="Linked REDCap Projects"
                        subHeaderComponent={this.getSubHeader()}
                        columns={this.getColumns()}
                        data={filtered_projects}
                        sortFunction={this.customSort}
                        conditionalRowStyles={this.getConditionalRowStyles()}
                        progressComponent={<TableLoader loadingText='Loading...' active={loading}/>}
                        progressPending={loading}
                        noDataComponent={<NoDataComponent
                            text='There are no REDCap projects currently linked with this research project'/>}
                    />
                    <Card.Content extra>
                        {this.displayFooter()}
                    </Card.Content>
                </Segment>
                <UnlinkedRedcap
                    columns={this.getColumns()}
                    subHeaderComponent={this.getSubHeaderUnlink()}
                    open={this.state.modalOpen}
                    onClose={this.handleModalState}
                    data={this.state.filtered_projects_unlinked}
                    sortFunction={this.customSort}
                />
            </>
        )
    }
}

TabRedcap.propTypes = {
    projectId: PropTypes.number,
    userInfo: PropTypes.object,
    history: PropTypes.object
}

export default TabRedcap;