import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react'

export function TableLoader({ loadingText, active, hg='100px' }) {
    return (
        <div style={{ height: hg }}>
            <Dimmer active={active} inverted>
                <Loader inverted>{loadingText}</Loader>
            </Dimmer>
        </div>
    )
}