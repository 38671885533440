import React from "react"

export const defaultAboutPanels = [
    {
        key: 'ap1',
        title: {
            content: 'What is the RIT Portal?'
        },
        content: {
            content: (
                <p>
                    The RIT Portal is a web platform that is responsible for tracking, communcating, and supporting research projects affiliated with Stanford TDS.
                    Research IT is hoping to use the Portal to facilitate better management of projects, track issues more thoroughly, and ensure easier communication with RIT personnel
                </p>
            )
        }
    },
    {
        key: 'ap2',
        title: {
            content: 'What are some of the basic functions of the RIT Portal?'
        },
        content: {
            content: (
                <p>
                    The RIT Portal is a web platform that is responsible for tracking, communcating, and supporting research projects affiliated with Stanford TDS.
                    Research IT is hoping to use the Portal to facilitate better management of projects, track issues more thoroughly, and ensure easier communication with RIT personnel
                </p>
            )
        }
    }
]


