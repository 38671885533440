import React, { Component } from 'react';
import axios from 'axios';
import { Message, Form, Icon, Button, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';


export class SearchBar extends Component {
    constructor(props) {
        super(props)
        this.typingTimeout = null
        this.state = {
            error: '',
            options: [],
            searchQuery: '',
            isLoading: false
        }
    }

    /**
     * Function that sends an Ajax request to fetch autocomplete options
     * Added debounce to limit the number of req to 500ms
     */
    performSearch = (searchQuery => {
        if (!searchQuery)
            return
        this.setState({ isLoading: true })
        axios
            .get(`/api/users/search/?term=${searchQuery}`)
            .then((res) => this.setState({ options: this.manageDropdown(res.data), isLoading: false }))
            .catch((err) => console.log(err))
    })

    /**
     * Function that manages onClick for user selection
     * @param {Object} data - JSON returned from ElasticSearch, all hits from dropdown
     * @props {Func} callback - function to fire in parent class upon selected user
     */
    userSelected = (data) => {
        if (data.value) { //if the user has a sunet ID then success
            this.props.callback(data)
            this.setState({ searchQuery: data.text, error: '' })
        } else {
            this.setState({ error: 'Given user has no sunetid' })
        }

    }

    /**
     * Renders custom items for dropdown menu upon elasticsearch hit
     * @param {Object} data - JSON returned from ElasticSearch based on input search
     */
    manageDropdown = (data) => {
        const hits = data.hits.hits
        if (hits) {
            var retval = hits.map((val, i) => (
                {
                    title: val._source.title,
                    email: val._source.email,
                    full: val._source,
                    text: (val._source.first_name + ' ' + val._source.last_name), //prop 2, ... 3
                    value: val._source.suid,
                    key: i, //i => which option selected in the hits list. 
                }
            ))
            return retval
        } else {
            return ''
        }
    }


    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    filterBy = () => true;

    render() {
        const { isLoading, error } = this.state
        return (
            <Form className='attached fluid'>
                <div >
                    {error ? <Message negative size='tiny'>{error}</Message> : ''}
                    <AsyncTypeahead
                        filterBy={this.filterBy}
                        onSearch={this.performSearch}
                        options={this.state.options}
                        useCache={true}
                        id="custom_autocomplete"
                        paginate
                        labelKey="text"
                        isLoading={isLoading}
                        minLength={3}
                        searchText={<div className='searchText'><strong>Searching ...</strong></div>}
                        maxResults={10}
                        placeholder="Search for name, sunet, email ..."
                        delay={300}
                        paginationText={<div className='item-body'>Display additional results </div>}
                        emptyLabel={<div className='emptyLabel' ><strong>No users found</strong></div>}
                        renderMenuItemChildren={(option, { text }, index) => (
                            <div
                                onClick={() => this.userSelected(option)} //will re-render anonymous function everytime, check performance
                                className='item-body'
                            >
                                <div style={{ display: 'inline' }} ><Icon name='user circle' /></div>
                                <div style={{ display: 'inline' }}>
                                    <strong style={{ fontSize: '14px' }}>{option.text}</strong>
                                    {option.value ? ` - (${option.value})` : ''}
                                    <div className='meta-text'>
                                        <small>{option.title} {option.email ? `| ${option.email}` : ''}</small>
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        {!isLoading ? <Button icon='search' className='searchIcon'></Button> : <Loader className='search-loader' active={true}></Loader>}
                    </AsyncTypeahead>
                </div>
            </Form>
        )
    }
}


SearchBar.propTypes = {
    callback: PropTypes.func //function to execute upon finding user obj
}