import React, {useState, useEffect} from 'react';
import {
	Button,
	Icon,
	Segment,
	Message,
	Divider,
	Card,
	Dimmer,
	Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from "qs";
import {getCookieValue, truncateDate} from "../../../utils";
import {BasicTable} from "../../BasicTable";
import ERegDetailModal from "./eRegDetailModal";
import memoize from 'memoize-one';

const columns = memoize(onClick =>[
	{
		name: 'ID',
		selector: row => row.id,
		sortable: true,
	},
	{
		name: 'Created at',
		selector: row => truncateDate(row.created_at),
		sortable: true,
	},
	{
		name: 'Detail',
		cell: row => row?.survey_summary ? <Button onClick={() => onClick(row)} content={'Detail'} size="small"/> : ''
	}
])

export function TabEreg({match: {params}, ...props}) {
	const urlParameters = new URLSearchParams(props?.location?.search)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [submitted, setSubmitted] = useState([])
	const [modalOpen, setModalOpen] = useState(false)
	const [selected, setSelected] = useState({})
	const [finalizing, setFinalizing] = useState(false)
	const cookieValue = getCookieValue('csrftoken');
	const {pid} = params
	const navigate = () => {
		setLoading(true)
		axios({
			method: 'post',
			url: `/api/surveys/e_reg_protocols/add/`,
			data: qs.stringify({
				'project_id': pid
			}),
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
				"X-CSRFToken": cookieValue,
			}
		}).then(res => {
			if (res?.data?.survey_url) {
				window.location.href = res?.data?.survey_url

			} else {
				setError('Survey unable to be generated, please contact administrator')
				setLoading(false)
			}
		})
			.catch(err => {
				setError('Unable to redirect, Please contact administrator')
				setLoading(false)

			})
	}
	const text = [
		'You will not be charged for this research project during the pilot period. After the pilot period, a PTA will need to be provided and research projects will be charged on a quarterly basis.',
		'You will be asked to provide regular feedback on the use of eReg to the eReg project team.',
		<>All new users will be required to complete training prior to receiving access to eReg. Click on the <strong>New
			Request</strong> button below to request eReg training.</>,
		'Follow the steps below to add new users to eReg:'
	]
	const renderButton = () => {
		if (loading) {
			return (
				<Button.Group style={{width: '170px'}}>
					<Button icon size='small' primary loading style={{width: '30px'}}/>
					<Button primary size='small' content={'Redirecting ...'}/>
				</Button.Group>
			)
		} else {
			return (
				<Button
					style={{width: '170px'}}
					icon
					labelPosition='left'
					primary
					size='medium'
					onClick={loading ? undefined : navigate}
					loading={loading ?? false}
				>
					<Icon name='add circle'/>
					New Request
				</Button>
			)
		}

	}

	const generateRecords = () => {
		setFinalizing(true)
		const survey_url = `https://redcap.stanford.edu/surveys/?s=${urlParameters.get('svh')}`
		axios.post(`/api/surveys/finalize/`,
            qs.stringify({ 'url': survey_url }), //have to stringify in order for it to be passed via "BODY" on django, cant pass as data
            {
                headers: { "X-CSRFToken": cookieValue },
            })
            .then(() => {
                window.location.replace(window.location.origin + props.location.pathname) //refresh the page. replace history
            })
            .catch(err => {
				setError(`Error finalizing eReg records : ${err?.message}`)
				setFinalizing(false)
				})
	}

	useEffect(() => {
		if(urlParameters.get('svh')){
			generateRecords()
		} else {
			axios({
				method: 'get',
				url: `/api/projects/${pid}/surveys/6/`
			}).then(res => res?.data?.length ? setSubmitted(res?.data) : [])
			.catch(err => setError(err))

		}

	}, [])

	const handleModal = (row) => {
		setSelected(row)
		setModalOpen(!modalOpen)
	}
	if(finalizing) {
		return (
			<Segment attached='bottom'>
				 <Card fluid style={{ height: '150px' }}>
					<Card.Content>
						<Dimmer active={finalizing} inverted>
							<Loader inverted>Fetching completed records from REDCap ...
								The page will refresh after after completion</Loader>
						</Dimmer>
					</Card.Content>
				</Card>
			</Segment>
		)
	} else {
		return (
			<Segment attached='bottom'>
			<div>
				{error && <Message error content={error}/>}
				<Message>
					Welcome to the eReg Pilot! <br/><br/>
					We are excited to have you participate and want to remind you of the following:
					<Message.List>
						{text.map((e, i) => <Message.Item key={i}>{e}</Message.Item>)}
						<ul>
							<li>Click on the <strong>New Request</strong> button below</li>
							<li>Complete a new User <strong>Access Request form</strong> for each user</li>
							<li>Upload the user's eReg training record<strong>*</strong></li>
							<li>Submit the request</li>
						</ul>
						<Message.Item>
							Please notify us of any eReg issues by using the
							<strong> New Request</strong> button to submit a ticket
						</Message.Item>
					</Message.List>
					<br/>
					<strong>*All users must be trained, and their training record must
						be uploaded before they can be added to eReg.</strong>
				</Message>
				{renderButton()}
				<Divider/>
				<BasicTable
					columns={columns(handleModal)}
					data={submitted}
					title='Previous submissions'
					defaultSortAsc={true}
					defaultSortField={1}
				/>
				<ERegDetailModal
					open={modalOpen}
					onClose={handleModal}
					data={selected}
				/>
			</div>
		</Segment>
		)
	}

}

TabEreg.propTypes = {
	match: PropTypes.object
}

