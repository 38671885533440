import { createContext } from 'react';

// Persistent Auth object to determine login
const Auth = {
    isAuthenticated: false,
    userInfo: {},
    setAuthentication() {
        this.isAuthenticated = true
    },

    removeAuthentication() {
        this.isAuthenticated = false
    },

    updateUserInfo(res){
        this.userInfo = res
    }
}

export const GlobalContext = createContext(Auth);