import React, { Component } from 'react';
import {
    Dropdown, Segment,
    Container, Menu, Progress,
    Button, List, Label, Accordion,
    Icon, Message, Popup, Divider
} from 'semantic-ui-react';
import DetailContent from '../../components/DetailContent';
import Header from '../../components/Header';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { EditProjectModal } from './Modals';
import { IRBModal } from './Modals';
import { GlobalContext } from '../../context';
import { getCookieValue } from '../../utils';
import { withWindowDimension } from '../../components/HOC/useWindowDimension';
import PropTypes from 'prop-types';
import './Detail.css';
import qs from 'qs';


const defaultAccessTabs = ['default', 'users', 'redcap', 'tickets'] //add temp ereg
const allAccessTabs = [
    'users', 'info', 'redcap', 'tickets', 'consultation',
    'finance', 'sow', 'e_reg'
]

const style = {
    listItem: {
        minHeight: '35px'
    },

    listContentLabel: {
        display: 'inline-block',
        border: 'none',
        verticalAlign: 'bottom',
        backgroundColor: 'transparent',
        color: 'black',
    },

    listContentButton: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginRight: '17px'
    },

    firstContentButton: {
        position: 'relative',
        top: '-5px'
    },

    accordion: {
        margin: '0',
        "&: h4:hover": {
            backgroundColor: '!important red',
            color: 'red'
        }
    },
    consultationPopup: {
        // borderRadius: 0,
        // opacity: 0.7,
        padding: '2em',
    },

    // closeButton: {
    //     position: 'absolute',
    //     top: '0px',
    //     right: '0px',
    //     margin: '0px',
    //     border: 'none',
    //     boxShadow: 'none',
    // }
}

class Detail extends Component {
    static contextType = GlobalContext //Necessary for provider

    constructor(props) {
        super(props);

        /*
            Enables linking to specific tabs within a project:

            Check if tab is valid before setting: else default to 'users'
            this.props.match.params.tab will only be set when navigating via link, not from clicking render on /home/
        */
        //Hiding SOW and Finance Tabs, replaced with info
        //These variables are used to set activeTab state upon url navigation

        const tabTitle = this.props.match.params.tab //If user is trying to navigate via URL:
        const tab = tabTitle && allAccessTabs.includes(tabTitle) ? tabTitle : 'default' //Set the tab upon first render
        // const firstTimeCreation = this.props.location?.state?.from === 'Create Project' //Coming from the project creation wizard

        // this.props.userInfo.data?.notifications?.filter( //Current notification for this project has not yet been acknowledged
        //     (e) => e.project_id === parseInt(this.props.match.params.pid) && e.notification_log_notification_id === null).length > 0
        this.myRef = React.createRef();

        this.state = {
            activeTab: tab,
            contentTab: '',
            projectDetail: '',
            owner_detail: {},
            irb: false,
            error: '',
            revealedTabs: -1, //set to -1 on default to prevent render before checking tab visibility
            modalShow: false,
            editModalShow: false,
            irbModalShow: false,
            editProjectEnabled: false,
            checklist: [],
            checklistQuestions: [],
            checklistPercentage: 5,
            activeIndex: 0,
            popupOpen: false
        };
    }

    useNull = () => null

    componentDidMount() {
        const { pid } = this.props.match.params
        axios
            .all([
                axios.get(`/api/projects/${pid}/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/users/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/irb/`, { withCredentials: true }).catch(this.useNull),
                axios.get('/api/users/current-user/', { withCredentials: true })
            ]).then(axios.spread((projectDetail, users, irbResponse, currentUser) => {
                if (!projectDetail) {//incorrect pathing no id, return to home
                    this.props.history.replace('/');
                } else {
                    let revealedTabs = this.checkTabPermissions(projectDetail)
                    let PI;
                    let current;
                    let firstTimeCreation = false

                    if (users) {
                        PI = users?.data?.find(obj => obj.group_id === 2) //check if we have a PI
                        current = users?.data?.find(obj => obj.user_id === this.context.userInfo.id)
                    }

                    if (currentUser) { //Block for updating notifications for the logged-in user
                        this.context.updateUserInfo(currentUser.data)
                        let navLocation = this.props?.location?.state?.from === 'Create Project' //Coming from the project creation wizard
                        let notification = currentUser?.data?.notifications?.filter( //Current notification for this project has not yet been acknowledged
                            (e) => e.project_id === parseInt(this.props.match.params.pid) && e.notification_log_notification_id === null).length > 0
                        firstTimeCreation = navLocation && notification
                    }

                    this.setState({
                        checklistQuestions: projectDetail.data.checklistItemQuestions,
                        checklist: projectDetail.data.checklistItem,
                        checklistPercentage: projectDetail.data.checklistItem.length * 25,
                        projectDetail: projectDetail.data,
                        owner_detail: PI ? PI : undefined,
                        revealedTabs: [...defaultAccessTabs, ...revealedTabs],
                        editProjectEnabled: current?.group_id === 2 || current?.group_id === 3,
                        irb: irbResponse ? irbResponse?.data?.protocol : false,
                        activeIndex: projectDetail?.data?.checklistItem.length === projectDetail?.data?.checklistItemQuestions.length ? -1 : 0,
                        popupOpen: firstTimeCreation
                    })
                }
            }))
    }

    // Acknowledges the consultation notification
    acknowledge = () => {
        let { pid } = this.props.match.params
        let search = this.context?.userInfo?.notifications.find((e) => e.title === 'consultation_guide' && e.project_id === parseInt(pid))

        if (search) {
            axios
                .post(`/api/notifications/${search.id}/acknowledge/`, '', {
                    headers: { "X-CSRFToken": getCookieValue('csrftoken') }
                })
                .catch(err => console.log(err))
        }
    }

    /**
     * Function that determines whether the user has access to a hidden tab
     * @arg {Object} - projectDetail: project object returned from get request
     * @return {Array} - Tabs able to be viewed
     */
    checkTabPermissions = (projectDetail) => {
        if (projectDetail && projectDetail.data) {
            let visible = [] //keeping track of return of visible tabs
            let tabViewPermissions = projectDetail.data.tabs
            for (var tab of Object.keys(tabViewPermissions)) {
                if (tabViewPermissions[tab]) //If user has permission
                    visible.push(tab)
            }
            return visible

        } else {
            return []
        }
    }

    checkViewPermissions = (qualifier) => {
        const { permissions } = this.state.projectDetail

        if (permissions) { //component loaded,
            if (permissions.find((perm) => perm.codename === qualifier))
                return true
        } //api call has not yet been performed, no state
        return false
    }

    //Change tab state, switch router handle
    changeTabs = (e, { name }) => {
        var { pid } = this.props.match.params
        this.props.history.push(`/detail/${pid}/${name}`)
        this.setState({ activeTab: name })
    }

    closePopup = () => {
        this.setState({ popupOpen: false })
        this.acknowledge()
    }
    //Callback to redirect user to notifications page upon header click
    notificationRedirect = (redirectID) => {
        axios //have to alter state of project, if coming from a different page
            .all([
                axios.get(`/api/projects/${redirectID}/`, { withCredentials: true }),
                axios.get(`/api/projects/${redirectID}/users/`, { withCredentials: true })
            ]).then(axios.spread((projectDetail, users) => {
                let viewPermission = this.checkPermissions(projectDetail)
                if (users) {
                    let PI = users?.data.find(obj => obj.group_id === 2)
                    this.setState({ projectDetail: projectDetail.data, owner_detail: PI, viewPermission: viewPermission })
                } else {
                    this.setState({ projectDetail: projectDetail.data, viewPermission: viewPermission })
                }
            })).catch(err => {
                //user navigates here without permission / forbidden -> redirect
                console.log(err)
                this.props.history.replace('/home') //redirect to home if error.
            })
    }

    //Callback passed to Info tab, updates consultation button when user submits information
    infoUpdateRedirect = () => {
        this.setState({ consultationEnabled: true })
    }

    /**
     * Returns static project typing based on integer value
     * @param {Integer} projectType
     */
    renderProjectType = (projectType) => {
        switch (projectType) {
            case (1):
                return 'Research'
            case (2):
                return 'Quality Improvement'
            case (3):
                return 'Other'
            default:
                return 'Other'
        }
    }


    /**
 * Returns static project typing based on integer value
 * @param {Integer} projectType
 */
    renderProjectStatus = (projectType) => {
        switch (projectType) {
            case (0):
                return 'Development'
            case (1):
                return 'Production'
            default:
                return 'Other'
        }
    }

    renderPopupContent = () => {
        return (
            <div>
                {/* <Button onClick={this.closePopup} icon='close' className='closeButton' compact  size='large' /> */}
                <p style={{ paddingTop: '5px' }}>This tab is used to begin begin the consultation process</p>
                {/* <p>Thank you for registering your project on R2P2. </p>
                <p>If you need to speak with our team of developers directly about Professional Services for your project, you must submit a Consultation request. Once a consultation request is received, we will assign a developer to it.
                The developer will contact you directly to arrange a meeting time to discuss the nature and scope of your request and cover pricing and timeline estimates.
                </p> */}
                {/* <Icon style={style.closeButton} name='close'/> */}
            </div>
        )
    }

    generateAlternateTitle = (name) => {
        let content
        switch (name) {
            case 'redcap':
                content = 'REDCap'
                break;
            case 'users':
                content = 'Members'
                break;
            case 'sow':
                content = 'Statements of Work'
                break;
            case 'consultation':
                content = 'Consultations'
                break;
            case 'default':
                content = 'Description'
                break;
			case 'e_reg':
				content = (
						<>
							<Label color='red' corner='right' circular empty/>
							eReg
						</>
				)

				break;
            default:
                content = name.charAt(0).toUpperCase() + name.slice(1);
        }

        if (content === 'Consultations') {
            // Created to show first-time R2P2 users where to submit a consultation.
            let PopupGuide = React.forwardRef((props, ref) =>
                <div ref={ref}>{props.children}</div>
            )
            return (
                <Menu.Item
                    className={this.state.popupOpen ? 'selectMe' : ''}
                    key={name}
                    name={name}
                    active={this.state.activeTab === name}
                    onClick={this.changeTabs}
                    content={<PopupGuide ref={this.myRef}>{content}</PopupGuide>}
                />
            )
        } else {
			return (
                <Menu.Item
                    key={name}
                    name={name}
                    active={this.state.activeTab === name}
                    onClick={this.changeTabs}
                    content={content}
                />
            )
		}
    }

    // Singular callback that handles modal state of all modals
    handleModalState = (name) => {
        let isOpen = this.state[name]
        isOpen ? this.setState({ [name]: false }) : this.setState({ [name]: true })
    }


    updateProjectInformationCallBack = (data) => {
        this.setState({ projectDetail: data })
    }

    updateIrbCallback = (data) => {
        if (data?.data?.protocol)
            this.setState({ irb: data.data.protocol })
        else
            this.setState({ irb: false })
    }

    /**
     * Updates the owner object without having to refresh the page via the users tab
     *
     * @memberof Detail
     */
    updateOwnerCallback = data => this.setState({ owner_detail: data })


    redirectAdmin = () => {
        var { pid } = this.props.match.params
        if (window.location.origin.includes('127.0.0.1')) {
            window.location = `${window.location.origin}:8000/admin/app/project/${pid}/change/`
        } else {
            window.location = `${window.location.origin}/admin/app/project/${pid}/change/`
        }
    }

    handleAccordion = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }

    // renderChecklistButtonMobile = () => {
    //     const { checklist, checklistQuestions } = this.state
    //     const completeButton = (i) => <Button onClick={this.handleChecklist} style={style.listContentButton} index={i} floated='right' color='blue' size='mini'>Complete</Button>
    //     const finishedButton = <Label color='green' icon='check' content='Finished' />

    //     return checklistQuestions.map((v, i) =>
    //     (
    //         <List.Item style={style.listItem} key={v.id} >
    //             <List.Content verticalAlign='bottom'>
    //                 <Label style={style.listContentLabel}>{v.text}</Label>
    //                 {/* {checklist.filter(e => e.id === v.id).length > 0 ? finishedButton : completeButton(v.id)} */}
    //             </List.Content>
    //         </List.Item>
    //     )
    //     )
    // }

    renderChecklistButton = () => {
        const completeButton = (i) => <Button onClick={this.handleChecklist} style={style.listContentButton} index={i} floated='right' color='blue' size='mini'>Complete</Button>
        const completeButtonFirst = <Button onClick={this.handleChecklist} style={style.firstContentButton} index={1} floated='right' size='mini' color='red' content='Complete' />
        const finishedButton = <Label style={{ float: 'right', marginRight: '17px' }} color='green' icon='check' content='Finished' />
        const { checklist, checklistQuestions } = this.state

        return checklistQuestions.map((v, i) => {
            let tt = (
                <Message size='tiny' error>
                    {v.text}
                    {completeButtonFirst}
                </Message>
            )
            let cpl = (
                <>
                    <Label style={style.listContentLabel}>{v.text}</Label>
                    {finishedButton}
                </>
            )

            return (
                <List.Item style={style.listItem} key={v.id} >
                    <List.Content verticalAlign='bottom'>
                        {i === 0 &&
                            <>
                                {checklist.filter(e => e.id === 1).length > 0 ? cpl : tt}
                            </>
                        }
                        {i !== 0 &&
                            <>
                                <Label style={style.listContentLabel}>{v.text}</Label>
                                {checklist.filter(e => e.id === v.id).length > 0 ? finishedButton : completeButton(v.id)}
                            </>
                        }

                    </List.Content>
                </List.Item>
            )
        }

        )
    }

    handleChecklist = (e, { index }) => {
        let { checklist, checklistQuestions, checklistPercentage } = this.state
        let entry = checklistQuestions.find(el => el.id == index)
        this.setState({ checklist: [...checklist, entry], checklistPercentage: checklistPercentage + 25 })
        // send ajax
        const cookieValue = getCookieValue('csrftoken');
        const { pid } = this.props?.match?.params

        axios.post(`/api/projects/${pid}/add-checklist/`,
            qs.stringify(entry), //have to stringify in order for it to be passed via "BODY" on django, cant pass as data
            {
                headers: { "X-CSRFToken": cookieValue },
            })
            .catch(err => console.log(err))

    }

    renderMobileTabs = (alternateTabNames) => {
		let {activeTab} = this.state
		let text = ''
		switch (activeTab) {
			case 'redcap':
				text = 'REDCap'
				break;
			case 'users':
				text = 'Members'
				break;
			case 'sow':
				text = 'Statements of Work'
				break;
			case 'consultation':
				text = 'Consultations'
				break;
			case 'default':
				text = 'Description'
				break;
			case 'e_reg':
				text = 'eReg'
				break;
			default:
				text = activeTab.charAt(0).toUpperCase() + activeTab.slice(1);
		}
		return (
            <Menu vertical style={{zIndex:999}}>
                <Dropdown item text={text}>
                <Dropdown.Menu>
                    {alternateTabNames}
                </Dropdown.Menu>
                </Dropdown>
            </Menu>
        )
    }

    renderTabs = (alternateTabNames) => {
        return (
            <Menu
                // compact
				// tabular
				// stackable
                borderless
				secondary
                // attached='top'
                pointing
            >
                {alternateTabNames}
            </Menu>
        )
    }

    render() {
        if (!this.context.isAuthenticated)
            return (<Redirect to='/' />)

        //Block initial render until after component mounts to determine visible tabs, otherwise default will load and attempt to update a nonexistant state
        if (this.state.revealedTabs === -1) return null

        const { projectDetail, owner_detail, revealedTabs } = this.state
        if (!revealedTabs.includes(this.state.activeTab)) //user tries to navigate to hidden tab (finance / SOW / consult)
            return (<Redirect to={`/`} />)

        const alternateTabNames = revealedTabs.map((name) => this.generateAlternateTitle(name));
        const irbDropdownText = this.state.irb ? 'Delete IRB' : 'Add IRB'
        const passtoDefault = {
            owner_detail: owner_detail,
            irb: this.state.irb,
            projectDetail: this.state.projectDetail
        }
        const { checklistPercentage, activeIndex, checklistQuestions, checklist, popupOpen } = this.state
        return (
            <div>
                <Header
                    notificationRedirect={this.notificationRedirect}
                    {...this.props}
                />
                {popupOpen &&
                    <div className='disabledContainer'>
                        <Container style={{ backgroundColor: 'white', position: 'absolute', zIndex: '10', borderRadius: '5px', padding: '15px', top: '10vh' }}>
                            <h4>PROFESSIONAL SERVICES CONSULTATIONS</h4>
                            <Button onClick={this.closePopup} icon='close' className='closeButton' compact size='huge' />

                            <Divider />
                            Thank you for registering your project on R2P2!
                            <List bulleted>
                                <List.Item>If you need to speak with our team of developers directly, you must submit a Consultation request. </List.Item>
                                <List.Item>Once a Consultation request is received, a member of Stanford TDS will reach out to you to schedule a consultation</List.Item>

                            </List>
                        </Container>
                    </div>
                }
                <Container fluid style={{ padding: '20px' }}>
                    <Segment>
                        {/* <SemanticHeader attached='top'> */}
                        <Menu attached='top'>
                            <Menu.Item>
                                <h4>{projectDetail.project_name}</h4>
                            </Menu.Item>
                            <Menu.Menu position='right'>
                                <Dropdown className='edit_project_dropdown' item text='Edit Project' direction='left' >
                                    <Dropdown.Menu>
                                        {(this.state.editProjectEnabled || this?.context?.userInfo?.is_admin) &&
                                            <>
                                                <Dropdown.Header content='Features' icon='edit' />
                                                <Dropdown.Divider />
                                                <Dropdown.Item name='irbModalShow' label={{ color: 'red', empty: true, circular: true }} text={irbDropdownText} onClick={() => this.handleModalState('irbModalShow')} />
                                                <Dropdown.Item name='editModalShow' label={{ color: 'blue', empty: true, circular: true }} text='Project Info' onClick={() => this.handleModalState('editModalShow')} />
                                            </>
                                        }
                                        {this?.context?.userInfo?.is_admin &&
                                            <>
                                                <Dropdown.Divider />
                                                <Dropdown.Header content='RIT Admin' icon='user plus' />
                                                <Dropdown.Divider />
                                                <Dropdown.Item label={{ color: 'green', empty: true, circular: true }} text='Admin project link' onClick={this.redirectAdmin} />
                                            </>
                                        }

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        </Menu>
                        {checklistQuestions.length > 0 &&
                            <Segment attached style={{ backgroundColor: '#F5F5F5' }}>
                                <Accordion>
                                    <Accordion.Title
                                        style={style.accordion}
                                        as='h4'
                                        active={activeIndex === 0}
                                        index={0}
                                        onClick={this.handleAccordion}>
                                        <div>
                                            {checklistQuestions.length === checklist.length &&
                                                <Icon style={{ verticalAlign: 'bottom' }} color='green' size='large' inverted name='check' />
                                            }
                                            R2P2 Project Checklist <Icon name='dropdown' />
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 0}>
                                        <Segment attached style={{ backgroundColor: '#F5F5F5' }}>
                                            <Progress active color='green' size='tiny' percent={checklistPercentage} />
                                            <Message
                                                floating
                                                list={['Checklist items are optional steps you can complete to review different sections of R2P2', `Once an item is completed, click the the Complete button to check it off the list`]}
                                            />

                                            <Container fluid >
                                                <List divided >
                                                    {this.renderChecklistButton()}

                                                    {/* { this.props.screenWidth < 400 ? this.renderChecklistButtonMobile() : this.renderChecklistButton()} */}
                                                </List>
                                            </Container>
                                        </Segment>
                                    </Accordion.Content>
                                </Accordion>
                            </Segment>
                        }
						<br/>
                        {/*{this.renderTabs(alternateTabNames)}*/}
						{/*{this.renderMobileTabs(alternateTabNames)}*/}

						{this.props.screenWidth < 825 ? this.renderMobileTabs(alternateTabNames) : this.renderTabs(alternateTabNames)}


                        <DetailContent
                            type={this.state.activeTab}
                            updateOwnerCallback={this.updateOwnerCallback}
                            infoUpdateRedirect={this.infoUpdateRedirect}
                            {...this.props}
                            handleParentModalState={this.handleModalState}
                            projectDetail={projectDetail}
                            defaultData={passtoDefault}
                        />

                    </Segment>
                </Container>
                <EditProjectModal
                    open={this.state.editModalShow}
                    onClose={this.handleModalState}
                    name='editModalShow'
                    pid={projectDetail.id}
                    projectName={projectDetail.project_name}
                    projectDescription={projectDetail.project_description}
                    projectType={projectDetail.project_type}
                    projectStatus={projectDetail.project_status}
                    updateProjectInformationCallBack={this.updateProjectInformationCallBack}
                    updateOwnerCallback={this.updateOwnerCallback}
                    {...this.props}
                />
                <IRBModal
                    open={this.state.irbModalShow}
                    irb={this.state.irb}
                    name='irbModalShow'
                    onClose={this.handleModalState}
                    callback={this.updateIrbCallback}
                    {...this.props}
                />
                <Popup
                    open={popupOpen}
                    size='small'
                    style={style.consultationPopup}
                    flowing
                    offset={[0, 20]}

                    // inverted
                    // trigger={<Button content='Trigger Popup' />}
                    context={this.myRef}
                    // content="Click here to begin the consultation process"
                    // content={this.renderPopupContent}
                    position='bottom right'
                >
                    {this.renderPopupContent()}
                </Popup>
                {/* <Modal
                    basic
                    // onClose={}
                    // onOpen={() => setOpen(true)}
                    open={true}
                    size='small'
                    // trigger={<Button>Basic Modal</Button>}
                >

                <Modal.Content>
                    <p>
                    Thank you for registering your project on R2P2.
                    If you need to speak with our team of developers directly about Professional Services for your project, you must submit a Consultation request.
                    Once a consultation request is received, we will assign a developer to it.
                    The developer will contact you directly to arrange a meeting time to discuss the nature and scope of your request and cover pricing and timeline estimates.
                    </p>
                </Modal.Content>
            </Modal> */}
            </div>
        );
    }
}

Detail.propTypes = {
    projectDetail: PropTypes.object,
    match: PropTypes.object,
    Auth: PropTypes.object,
    history: PropTypes.object
}

export default withWindowDimension(Detail);
