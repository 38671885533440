import React from 'react'
import {
    Button, Form,
    List, Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server';
import RITHeader from '../images/RITHeader.png'
import DOMPurify from 'isomorphic-dompurify';

//Step 4
export const StepRevise = ({onChange, submission_comment, manageSOW, data}) => {
    const sowHeader = ReactDOMServer.renderToStaticMarkup(
        <div>
            <img alt='sow-header' style={{marginBottom:'10px', maxWidth:'400px'}} src ={RITHeader}/>
        </div>

    );
    const sowFooter = ReactDOMServer.renderToStaticMarkup(
        <List style={{marginTop:'10px'}}>
            <List.Item>
                <List.Content>
                    <a href = 'https://drive.google.com/file/d/148vXiHTeuEBqH5rbzju72SRhiawyQ_ZU/view'>About Research IT Services</a>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Content>
                    <a href = 'https://drive.google.com/file/d/19CDl84UN18CtMOA_VqViptkSs_J9RK5N/view'>Terms and Conditions</a>
                </List.Content>
            </List.Item>
        </List>
    );

    

    const triggerCallback = () => {
        manageSOW(3) //3 = status code for revise 
    }

    return (
        <div>
            <Modal.Content scrolling style={{border:'solid 1px'}}>
                <div
                    style={{margin:'20px'}}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(sowHeader + data.content + sowFooter)}}
                />
            </Modal.Content>
            <h4>Please enter a revision comment regarding this statement of work</h4>
            <Form>
                <Form.Field>
                    <Form.TextArea 
                        name = 'submission_comment' 
                        onChange={onChange} 
                        value = {submission_comment} 
                        label={'Comment'}
                        placeholder={'Eg: the project title is wrong ... '}
                    />
                </Form.Field>
                <Button onClick={triggerCallback} primary>Submit</Button>
            </Form>
        </div>
    )
}

StepRevise.propTypes = {
    onChange: PropTypes.func,
    submission_comment: PropTypes.string,
    manageSOW : PropTypes.func,
    data: PropTypes.object
}