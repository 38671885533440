import React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";

export default function MaintenanceView(){

    return (
            <Container fluid style={{minHeight: '500px'}}>
                <Segment textAlign="center" style={{margin:'25px', height: '95vh'}}>
                <Header as='h2' icon style={{position:'relative', top:'20vh'}}>
                    <Icon name='settings' />
                        Maintenance
                    <Header.Subheader>
                    R2P2 is currently offline undergoing maintenance. We apologize for the inconvenience.
                    </Header.Subheader>
                </Header>
                </Segment>
            </Container>
        
        
    )
}
