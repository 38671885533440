import React, {Component} from 'react';
import DataTable from 'react-data-table-component'
import PropTypes from 'prop-types'

export class BasicTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <DataTable
                title={this.props.title ? this.props.title : ''}
                subHeader={this?.props?.subHeaderComponent ? true : false}
                subHeaderComponent = {this?.props?.subHeaderComponent ? this.props.subHeaderComponent : ''}
                subHeaderAlign='left'
                columns={this?.props?.columns}
                data= {this?.state?.data}
                fixedHeader={true}
                fixedHeaderScrollHeight="400px"
                pagination 
                paginationRowsPerPageOptions={[10,50,100]}
                paginationTotalRows={this?.props?.data?.length}
                onRowClicked={this.props.onRowClick}
                sortFunction={this.props.customSort}
                conditionalRowStyles={this.props?.conditionalRowStyles ?? undefined}
                // responsive
                persistTableHead
                Clicked
                {...this.props}
        />
        )
    }
}

BasicTable.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subHeaderComponent : PropTypes.element,
    columns: PropTypes.array,
    data: PropTypes.array,
    onRowClick : PropTypes.func,
    customSort: PropTypes.func,

}