import React, {Component} from 'react';
import {
    Tab, Card, Accordion, Segment, Icon, Container
} from 'semantic-ui-react';
import './FAQ.css';
import axios from "axios";
import Header from "../../components/Header";
import {defaultAboutPanels, defaultProjectPanels, defaultConsultationPanels} from './defaults';
class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: '',
            content: '',
        }
    }

    componentDidMount() {
        var uri = window.location.href.substr(window.location.protocol.length + window.location.hostname.length + 2)
        axios
            .get('/api/pages' + uri + '/', {withCredentials: false})
            .then(res => this.setState({content: res.data.content}))
            .catch(err => console.log(err))
    }

    panes = [
        { 
            menuItem: {key: 'about', content: <div><Icon name='settings'/> About</div>}, 
            render: () => <Tab.Pane className='faq-container'><Accordion styled fluid panels={defaultAboutPanels} /></Tab.Pane> 
        },
        { 
            menuItem: {key: 'projects', content: <div><Icon name='folder open'/> Projects</div>}, 
            render: () => <Tab.Pane className='faq-container'>{<Accordion styled fluid panels={defaultProjectPanels} />} </Tab.Pane> 
        },
        { 
            menuItem: {key: 'talk', content: <div><Icon name='talk'/> Consultations</div>}, 
            render: () => <Tab.Pane className='faq-container'>{<Accordion styled fluid panels={defaultConsultationPanels}/>} </Tab.Pane> 
        },
        { 
            menuItem: {key: 'settings', content: <div><Icon name='question'/> Tickets + Support</div>}, 
            render: () => <Tab.Pane className='faq-container'>Support here </Tab.Pane> 
        },
        { 
            menuItem: {key: 'users', content: <div><Icon name='address book'/> Users</div>}, 
            render: () => <Tab.Pane className='faq-container'>Users here </Tab.Pane> 
        },
    ]


    render() {
        return (
            <div>
                <Header handleLogout={this.handleLogout} {...this.props}/>
                <Container>
                    <Card fluid>
                        <Segment basic>
                            <Tab menu={{ pointing: true, fluid: true, vertical: true }} panes={this.panes} />
                        
                        </Segment>
                    </Card>
                </Container>
                
                
            </div>

        );
    }
}

export default FAQ;