import React, { Component } from 'react';
import axios from 'axios';
import './Home.css';
import Header from '../../components/Header';
import {
    Button, Card, Input, Icon,
    Container, Table, Divider, Label
} from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { truncateDate } from '../../utils';
import DataTable from 'react-data-table-component';
import * as _ from 'underscore';
import memoize from 'memoize-one';
import { GlobalContext } from '../../context';
import { TableLoader } from '../../components/TableLoader';


class Home extends Component {
    static contextType = GlobalContext
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: true,
            projects: [],
            filteredProjects: [],
            filteredInput: [],
            numProjects: 0, //default to one page

            //for table sorting
            column: null,
            direction: null,
            loading: true,
        }
    }

    componentDidMount() {
        axios
            .get('/api/projects/', { withCredentials: true })
            .then(res => this.setState({ projects: res.data, filteredProjects: res.data, numProjects: res.data.count, loading: false }))
            .catch(err => {
                console.log(err)
                this.setState({ projects: [], filteredProjects: [], numProjects: 0, laoding: false })
            })
    }


    //Sorting method to rerender table upon header click
    handleSort = (clickedColumn) => () => {
        const { column, projects, direction } = this.state

        if (column !== clickedColumn) { //If clicking different column or first-time
            this.setState({
                column: clickedColumn,
                // projects: _.sortBy(projects, [clickedColumn]),
                projects: _.sortBy(projects, (obj) => {
                    switch (clickedColumn) {
                        case 'project_name':
                            return obj.project_name.toUpperCase() //upper and lower case have different ASCII val
                        case 'project_description':
                            return obj.project_description.toUpperCase()
                        case 'project_created_at':
                            return obj.project_created_at
                        default:
                            return obj.projet_name.toUpperCase()
                    }
                }),
                direction: 'ascending',
            })
            return
        }
        // If clicking the same column, display inverse
        this.setState({
            projects: projects.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }


    //OnClick function to navigate to create page
    createProjectHandler = () => this.props.history.push('/create');

    getProjectList = () => {
        if (this.state.projects && this.state.projects.length > 0) {
            return this.state.projects.map((val, i) => (
                <Table.Row key={val.id}>
                    <Table.Cell>
                        {/* Pass project_detail props to next page to prevent another api call */}

                        <Link to={{
                            pathname: `/detail/${val.id}`,
                            // project_detail : val
                        }}>
                            {val.project_name + " "}

                        </Link>
                    </Table.Cell>
                    <Table.Cell>{val.project_description}</Table.Cell>
                    <Table.Cell>{truncateDate(val.project_created_at)}</Table.Cell>
                </Table.Row>
            ))
        } else {
            return (
                <Table.Row>
                    <Table.Cell>No Current Projects!</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row>
            )
        }
    }

    customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
            // use the selector function to resolve your field names by passing the sort comparitors
            const aField = selector(rowA) || ''
            const bField = selector(rowB) || ''
            let comparison = 0;
            
            if(typeof(aField) === 'string') {
                if (!aField) { //Put null values at the end of the array
                    return 1;
                 }
                 if (!bField) {
                    return -1;
                 }
                 comparison = aField.localeCompare(bField)

                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                if(aField > bField)
                    comparison = 1
                else if(aField < bField)
                    comparison = -1
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    };
    

    navigate = ({ id }) => {
        this.props.history.push(`/detail/${id}`)
    }

    searchTable = (e) => {
        const filterInput = e.target.value.toLowerCase()
        const filterId = this.state.projects.filter(entry => entry.id === parseInt(filterInput))
        const filteredName = this.state.projects.filter(entry => entry.project_name && entry.project_name.toLowerCase().includes(filterInput))
        this.setState({ filteredProjects: _.union(filteredName, filterId), filteredInput: filterInput })
    }

    getSubHeaderSearch = memoize(() =>
        <div style={{ width: '300px' }}>
            <Input
                icon='search'
                name='linked'
                onChange={this.searchTable}
                placeholder="Search name or id ..."
                fluid
            />
        </div>
    )

    getStyle = memoize(() => [
        {
            when: row => row,
            style: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ])

    getSubHeaderAdd = memoize(() =>
        <div>
            <Button
                floated='right'
                icon='plus'
                content='Register'
                labelPosition='left'
                primary
                size='small'
                onClick={this.createProjectHandler}
            />
        </div>
    )

    getColumns = memoize(() =>
        [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
                omit: this.context?.userInfo?.is_admin ? false : true,
                width: '100px'

            },
            {
                name: 'Project Name',
                selector: row => row.project_name,
                sortable: true,
                wrap: true,
                // style: {color:'red'}
                // width: '275px'
            },
            {
                name: 'Description',
                selector: row => row.project_description,
                sortable: true,
                grow: 3,
                maxWidth: '490px'
            },
            {
                name: 'Date Created',
                selector: row => row.project_created_at,
                sortable: true,
                cell: row => truncateDate(row.project_created_at),
                hide: 'md'
                // width: '175px'
            },
            {
                name: 'Last Modified',
                selector: row => row.project_updated_at,
                sortable: true,
                cell: row => truncateDate(row.project_updated_at),
                hide: 'md'
                // width: '175px'
            },
            {
                name: 'Last Viewed',
                selector: row => row.project_last_viewed_at,
                sortable: true,
                cell: row => truncateDate(row.project_last_viewed_at),
                hide: 'md'
                // width: '175px'
            },
        ]
    )



    render() {
        if (!this.context.isAuthenticated)
            return <Redirect to='/' />

        const { loading, numProjects, filteredProjects } = this.state

        return (
            <div>
                <Header {...this.props} />
                <Container fluid style={{padding:'20px'}}>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>Welcome to R2P2! <Label style={{ float: 'right' }}><Icon name='linkify' /><a href='https://medwiki.stanford.edu/display/redcap/R2P2'>R2P2 Wiki</a></Label></Card.Header>
                            <Divider />
                            <Card.Content>
                                <p>R2P2 is a research portal built to organize how you interface with <a href='https://med.stanford.edu/researchit.html'>Research IT</a> for support and services. </p>
                                <p>By registering your research project one time, all future requests will be tracked and organized.  R2P2 supports:</p>
                                <ul>
                                    <li>User Management</li>
                                    <li>Support Tickets</li>
                                    <li>Consultation Requests</li>
                                    <li>Statments of Work</li>
                                    <li>REDCap Projects</li>
                                    <li>... and more</li>
                                </ul>
                                <p>If you have a question or are in need of a consultation, please first select or create the project entry below.</p>
                            </Card.Content>
                        </Card.Content>
                    </Card>
                    <Card fluid >
                        <Card.Content>
                            <Card.Header>My Projects</Card.Header>
                            {this.getSubHeaderAdd()}
                            <DataTable
                                noHeader={true}
                                columns={this.getColumns()}
                                data={filteredProjects}
                                Clicked
                                onRowClicked={this.navigate}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                pagination
                                persistTableHead
                                paginationRowsPerPageOptions={[10, 20, 30]}
                                paginationTotalRows={numProjects}
                                progressComponent={<TableLoader loadingText='Loading...' active={loading} hg='200px' />}
                                progressPending={loading}
                                highlightOnHover
                                subHeaderComponent={this.getSubHeaderSearch()}
                                subHeaderAlign="left"
                                subHeader={true}
                                sortFunction={this.customSort}
                                responsive
                                conditionalRowStyles={this.getStyle()}
                            />
                        </Card.Content>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default Home;