import React from "react"

export const defaultProjectPanels = [
    {
        key: 'pp1',
        title: {
            content: 'How can I create a new project?'
        },
        content: {
            content: (
                <>
                    <p>
                        1. To create a project, click the create button on the home page
                    </p>
                    <img alt='step1' src='https://storage.googleapis.com/project-portal-static/FAQ/1_create.png'></img>
                </>
            )
        }
    }
]

