import React, { useEffect, useState } from 'react';
import {
    Button, Modal, Form, Message, Confirm, Tab, Card
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { getCookieValue, HelpLabel } from '../../../utils';
import TabInfo from '../../../components/DetailContent/TabInfo';

/**
 * Functional component that renders the edit project modal
 * @param {Function} showModal - function that alters the state of open/closed
 * @param {Boolean} open - variable that controls render of modal
 * @param {Integer} - pid - Project ID of the currently accessed project
 */
export const EditProjectModal = ({ open, onClose, pid, projectName, projectDescription, 
    projectType, projectStatus, updateProjectInformationCallBack, name, ...props }) => {
    
    const [updated, setUpdated] = useState(false)
    const [data, setData] = useState({
        project_name: projectName,
        project_description: projectDescription,
        project_type: projectType,
        project_status: projectStatus,
        id: pid
    });//Destructuring, data is set to default "", setData is updateFunction

    const [deleteModal, setDeleteModal] = useState(false)

    const openDeleteModal = () => {
        setDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
    }

    useEffect(() => {
        if (updated) {
            const hideSuccessMessage = setTimeout(() => {
                setUpdated(false)
            }, 4000)
            return () => clearTimeout(hideSuccessMessage)
        }
        
    }, [updated])
    // const [projectid, setPID] = useState(pid)
    const options = [
        { text: 'Research Project', value: 1 },
        { text: 'Quality Improvement', value: 2 },
        { text: 'Other', value: 3 },
    ]

    const statuses = [
        { text: 'Development', value: 0 },
        { text: 'Production', value: 1 },
        { text: 'Analysis', value: 2 },
        { text: 'Completed', value: 3 }
    ];


    const handleModalState = (e, arg) => {
        const newData = { ...data } //create new copy of data to not mutate old, need for default values on form
        newData[arg.name] = arg.value
        setData(newData)
    }

    const deleteProject = () => { //Update data upon button click
        axios({
            method: 'DELETE',
            url: `/api/projects/${pid}/`,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": getCookieValue('csrftoken'),
            }
        }).then(() => {
            document.location.href = "/";
        }).catch(err => console.log(err))

    }

    const submit = () => { //Update data upon button click
        axios({
            method: 'PUT',
            url: `/api/projects/${pid}/`,
            data: qs.stringify({
                project_name: data.project_name,
                project_description: data.project_description,
                project_type: data.project_type,
                project_status: data.project_status
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": getCookieValue('csrftoken'),
            }
        }).then(res => {
            setUpdated(true)
            setData(res.data)
            updateProjectInformationCallBack(data)
        }).catch(err => console.log(err))


    }
    
    const tabDetail = () => {
        return (
            <Card fluid>
                <Card.Content>
                    { updated && <Message color='green' content={'Updated Successfully!'}/> }
                    <Form className=' fluid'>
                        <HelpLabel
                            help="A short name or phrase that describes the project"
                            label="Project Name"
                        />
                        <Form.Input
                            icon='folder'
                            iconPosition='left'
                            onChange={handleModalState}
                            value={data.project_name}
                            name='project_name'
                            maxLength={200}
                        />

                        <HelpLabel
                            help="A short description of your project or study at a high level, similar to a journal abstract.  TDS management would like to understand what the overall objective is for the work that you and your team are doing. "
                            label="Project Description"
                        />
                        <Form.TextArea
                            name='project_description'
                            value={data.project_description}
                            onChange={handleModalState}
                        />
                        <Form.Select
                            fluid
                            label='Project Type'
                            name='project_type'
                            options={options}
                            value={data.project_type}
                            onChange={handleModalState}
                            placeholder='Select Type ...'
                        />
                        <Form.Select
                            fluid
                            label='Project Status'
                            name='project_status'
                            options={statuses}
                            value={data.project_status}
                            onChange={handleModalState}
                            placeholder='Select Status ...'
                        />
                    </Form>
                    <br/>
                    <Button floated='right' primary onClick={submit}>Save</Button>
                    </Card.Content>
            </Card>
        )
    }

    const tabExtra = () => {
        return (
            <>
                <TabInfo {...props}/>
            </>
        )
    }

    const panes = [
        { menuItem: 'Basic Details', render: () => <Tab.Pane>{tabDetail()}</Tab.Pane> },
        { menuItem: 'Extra Information', render: () => <Tab.Pane>{tabExtra()}</Tab.Pane> },
    ]

    return (
        <Modal style={{background:'#F5F5F5'}}size='large' closeIcon open={open} onClose={() => onClose(name)}>
            <Modal.Header>Edit project
            <Button
                floated='right'
                color='red'
                onClick={openDeleteModal}
                content='Delete Project'
                disabled={data.project_status === 1 ? true : false}
            />
            </Modal.Header>
            <Modal.Content style={{background: '#F5F5F5'}}>
                <br/>
                <Tab panes={panes} />
            </Modal.Content>
            <Confirm
                open={deleteModal}
                header='Confirm'
                content='Are you sure you want to delete this project?'
                onCancel={closeDeleteModal}
                onConfirm={deleteProject}
            />
        </Modal>
    )
}

EditProjectModal.propTypes = {
    showModal: PropTypes.func,
    open: PropTypes.bool,
    pid: PropTypes.number,
    onClose: PropTypes.func,
    projectName: PropTypes.string,
    updateProjectInformationCallBack: PropTypes.func,
    projectStatus: PropTypes.number,
    projectType: PropTypes.number,
    projectDescription: PropTypes.string,
    name: PropTypes.string
}