import React, {useState, useEffect} from 'react';
import {
    Button, Label, Input,
    List, Grid, Message, Divider,
    Container, Comment
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import RITHeader from '../images/RITHeader.png';
import { truncateDate, usFormatDate, getCookieValue } from '../../../../utils';
import { BasicComment } from '../../../BasicComment';
import DOMPurify from 'isomorphic-dompurify';
import axios from 'axios';
import qs from 'qs';
import '../TabStatement.css';


const style = {
    content: {
        maxHeight: "calc(80vh - 10em)", 
        overflowY: "scroll", 
        overflowX: "hidden", 
        backgroundColor: "#f8f8f9", 
        border: '1px solid rgb(192,192,192)', 
        borderRadius: '7px'
    },

    innerContent: {
        margin: '20px'
    },

    container: {
        maxHeight: "calc(80vh - 14em)", 
        overflowY: "auto" 
    },

    sowHeader: {
        marginBottom: '10px', 
        maxWidth: '400px' 
    },

    // error: {
    //     borderColor: 'red'
    // }
}

/**
 * Individual step components for Statement of Work Modal
 * @param {Function} goToStep - Function from stepWizard, provides ability to transition between steps
 * @param {Object} data - Data object of SOW
 * @param {Function} renderStatusText - Function that provides text representation for status integer
 */
export const StepInitial = ({ goToStep, data, renderStatusText, updateSelectedCallback, params}) => {
    const [comment, setComment] = useState('')
    const [feed, setFeed] = useState('')
    const [submitting, setSubmitting] = useState(false)
    // const [commentError, setCommentError] = useState(false)

    useEffect(() => {
        const comments = data?.comments?.comments
        setFeed(comments)
    }, [data]);

    const generateCommentFeed = (comments) => {
        if (comments) {
            // Find the comments with the sow title in the body to display (coming from consultation)
            let com = comments.filter(el => el.body.includes(data.title))
            com.sort((a, b) => b.created - a.created).reverse()
            
            if (!com.length)
                return null
            return (
                <div>
                    {/* {error === 1 ? <Message error content={'Error: one or more comments cannot be rendered within list of possible comments, please contact an administrator'} /> : ''} */}
                    <Comment.Group minimal>
                        {com?.map((comment, ind) => <BasicComment key={ind} comment={comment} />)}
                    </Comment.Group>
                </div>
            )
        } else {
            return null
        }
    }

    const sowHeader = ReactDOMServer.renderToStaticMarkup(
        <div>
            <img alt='sow-header' style={style.sowHeader} src={RITHeader} />
        </div>

    );

    const sowFooter = ReactDOMServer.renderToStaticMarkup(
        <List style={{ marginTop: '10px' }}>
            <List.Item>
                <List.Content>
                    <a href='https://drive.google.com/file/d/148vXiHTeuEBqH5rbzju72SRhiawyQ_ZU/view'>About Research IT Services</a>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Content>
                    <a href='https://drive.google.com/file/d/19CDl84UN18CtMOA_VqViptkSs_J9RK5N/view'>Terms and Conditions</a>
                </List.Content>
            </List.Item>
        </List>
    );

    const renderLeftSegment = () => {
        return (
            <Grid.Column width={11}>
                <Grid columns={2}>
                    <Grid.Column width={8}>
                        <List>
                            <List.Item>
                                <Label horizontal>Creation Date</Label>
                                {truncateDate(data.created_at)}
                            </List.Item>
                            <List.Item>
                                <Label horizontal>Last Updated</Label>
                                {truncateDate(data.updated_at)}
                            </List.Item>
                            <List.Item>
                                <Label horizontal>Created By</Label>
                                {
                                    data.user ? (data.user.first_name + ' ' + data.user.last_name) : ''
                                }
                            </List.Item>
                            <List.Item>
                                <Label horizontal>SOW ID</Label>
                                {data.id}
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <List>
                            <List.Item>
                                <Label horizontal>Start Date</Label>
                                {usFormatDate(data.start_date) ?? 'N/A'}
                            </List.Item>
                            <List.Item>
                                <Label horizontal>End Date</Label>
                                {usFormatDate(data.end_date) ?? 'N/A'}
                            </List.Item>
                            <List.Item>
                                <Label horizontal>Status</Label>
                                {renderStatusText(data.status)}
                            </List.Item>
                            <List.Item>
                                {
                                    data.reviewed_at ?
                                        <div>
                                            <Label horizontal>Reviewed At</Label>
                                            {truncateDate(data.reviewed_at)}
                                        </div>
                                        : ''
                                }

                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
                <List>
                    <Divider />
                    <div style={style.content}>
                        <div
                            style={ style.innerContent }
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sowHeader + data.content + sowFooter) }}
                        />
                    </div>
                </List>
                {
                    data.status === 1 &&
                    <>
                        <Divider></Divider>
                        {renderButtons(data.types)}
                    </>
                }

            </Grid.Column>
        )
    }

    const renderButtons = (type) => {
        return (
            <Button.Group floated='right'>
                {type === 1 &&
                    <>
                        <Button onClick={() => (goToStep(3))} negative>Reject</Button>
                        <Button.Or />
                    </>
                }
                <Button onClick={() => (goToStep(4))} positive>Revise</Button>
                <Button.Or />
                <Button onClick={() => (goToStep(2))} primary>Accept</Button>
            </Button.Group>
        )
    }

    const onCommentChange = ({target: {value}}) => setComment(value)
    
    const onSubmit = () => {
        const cookieValue = getCookieValue('csrftoken');
        const {pid} = params
        const {consultation__id: consultationId} = data
        // setLoading(true) //enable loader
        if(comment.length > 0 && consultationId && pid && !submitting) {
            setSubmitting(true)
            axios.post(`/api/projects/${pid}/consultation/${consultationId}/sow-add-comment/`,
                qs.stringify({ body: comment, sowId: data.id }), //have to stringify in order for it to be passed via "BODY" on django, cant pass as data
                {
                    withCredentials: true,
                    headers: { "X-CSRFToken": cookieValue },
                })
                .then(res => {
                    setFeed(res?.data?.jira?.comments?.comments)
                    updateSelectedCallback(res?.data)
                    setSubmitting(false)
                    setComment('')
                })
                .catch(err => {
                    console.log(err)
                    setSubmitting(false)
                })
        }
        
    }
    
    const renderRightSegment = () => {
        let filtered = generateCommentFeed(feed)
        const {consultation__id: consultationId} = data

        return (
            <Grid.Column width={5}>
                <h3>Comments</h3>
                <Message info>
                    <Message.List>
                        <Message.Item>This feed will show the progress of your statement of work in addition to any discussion.</Message.Item>
                    </Message.List>
                </Message>
                <div style={{marginBottom: '50px'}}>
                    <Divider />
                    <Container style={style.container}>
                        <div>
                            {filtered ? filtered : <Message style={{ textAlign: 'center' }} content='No current comments' />}
                        </div>
                    </Container>
                </div>
                <div style={{position: 'absolute', bottom: '15px', width: '100%'}}>
                    <Input 
                        // style={commentError ? style.error : undefined}
                        style={{position: 'relative', width: '100%', paddingRight: '21px'}} 
                        placeholder='Add a comment ...'
                        loading={submitting}
                        iconPosition='left'
                        action={{ 
                            disabled: consultationId ? false : true,
                            icon: 'comment alternate outline',
                            onClick: onSubmit
                        }}
                        onChange={onCommentChange}
                        value={comment}
                    />       
                </div>
            </Grid.Column>
        )
    }

    return (
        <Grid columns={2} divided>
            {renderLeftSegment()}
            {renderRightSegment()}
        </Grid>

    )
}

StepInitial.propTypes = {
    goToStep: PropTypes.func,
    open: PropTypes.bool,
    showModal: PropTypes.func,
    data: PropTypes.object,
    renderStatusText: PropTypes.func,
    updateSelectedCallback: PropTypes.func
}
