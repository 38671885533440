import React, {Component} from 'react';
import {isDevelopment} from '../../utils';
import {Redirect} from 'react-router-dom';
import { GlobalContext } from '../../context';

import './Landing.css';

class Landing extends Component {
    static contextType = GlobalContext

    setCookie(path){
        document.cookie = "redirect_uri=" + window.location.href + '; path=' + path;
    }
    // function that redirects to SSO/loginroute
    redirectSSO = () => {
        let host = window.location.host
        let clientInfo = {
                client_id: 'https://rit-portal.med.stanford.edu/',
                redirect_uri: window.location.protocol + '//' + window.location.host + '/auth/',
                authorization_endpoint: 'https://idp-proxy.med.stanford.edu/auth/realms/med-all/protocol/openid-connect/auth'
            };
        this.setCookie("/")
        if (isDevelopment && host.toLowerCase().indexOf('stanford.edu') === -1) {
            this.setCookie(":8000/")
            clientInfo = {
                client_id: 'account',
                redirect_uri: window.location.protocol + '//' + window.location.host + ':8000/auth/',
                authorization_endpoint: 'https://idp-proxy-stage.med.stanford.edu/auth/realms/projects-portal/protocol/openid-connect/auth'
            };
        }


        // Set client info and redirect
        window.OIDC.setClientInfo(clientInfo);
        window.OIDC.restoreInfo();
        window.OIDC.login({
            scope: 'openid profile email',
            response_type: 'code'
        });
    }

    render() {
        if(this.context.isAuthenticated)
            return <Redirect to='/home'/>

        return (
            <div className='landing-image'>
                <h5 className = 'ui block header stanford-bar'>Stanford Medicine TDS</h5>
                <div className='ui centered card'>
                    <div className='content'>
                        <div className='header'>Welcome to R2P2!</div>
                    </div>
                    <div className='content'>
                        <div className='meta'>
                            {/* put link to research it webpage */}
                        
                            <span>To facilitate better tracking, communication and support of your research projects, RIT is requesting all projects have an entry in the research portal. From this portal, you will be able to manage users, track issues, and communicate with RIT personnel.</span>
                        </div>
                    </div>
                    <div id='login-button' className="ui  button red" onClick={this.redirectSSO}>
                        Sign in
                    </div>
                </div>
            </div>

        );
    }
}

export default Landing;