import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Dropdown, Label, Icon, Divider } from "semantic-ui-react";
import { getCookieValue } from "../../utils";
import qs from 'qs';
import PropTypes from 'prop-types';
import { GlobalContext } from "../../context";

/**
 * Participants are watchers over consultations, issues
 * @returns 
 */
export default function Participant({ users, selectedIssue, selectedConsultation, updateParticipantCallback }) {
    const [participants, setParticipants] = useState([])
    const [dropValue] = useState('')
    const [userMap, setUserMap] = useState('')
    const context = useContext(GlobalContext);
    const urlDesignation = selectedIssue ? `/api/issues/${selectedIssue?.jira_issue_key}` : `/api/consultations/${selectedConsultation?.jira_ticket_id}`
    
    useEffect(() => {
        let currentParticipants = selectedIssue ? selectedIssue?.jira?.fields?.customfield_10028 : selectedConsultation?.full?.jira?.fields?.customfield_10028
        let emailCurrentParticipant = currentParticipants?.map(e => e.emailAddress)
        let test = [...participants, ...emailCurrentParticipant]

        setParticipants(test)
        if (users) {
            let map = {}
            for (let a of users) {
                map[a.user.email.toLowerCase()] = {
                    'full': `${a?.user?.first_name} ${a?.user?.last_name}`,
                    'username': a?.user?.username
                }
            }
            setUserMap(map)
        }

    }, [selectedIssue, selectedConsultation])

    const addParticipant = (email) => {
        // Designate which url to hit based on props passed
        axios({
            method: 'POST',
            url: `${urlDesignation}/participant`,
            data: qs.stringify({
                email: email
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": getCookieValue('csrftoken'),
            }
        }).then(res => {
            updateParticipantCallback(res, selectedIssue ? selectedIssue.id : selectedConsultation.id)
        })
    }

    const removeParticipant = (email) => {
        
        axios({
            method: 'DELETE',
            url: `${urlDesignation}/remove-participant`,
            data: qs.stringify({
                email: email
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": getCookieValue('csrftoken'),
            }
        }).then(res => {
            updateParticipantCallback(res, selectedIssue ? selectedIssue.id : selectedConsultation.id)
        })
    }

    const onDropdownChange = (e, { value, email }, arg) => {
        if (value) { //upon selection of a user
            let current = participants
            setParticipants([...current, value])
            addParticipant(value)
        }

    }

    const onDelete = (e, { value }) => {
        if (value) {
            let current = participants.filter(e => e !== value)
            setParticipants(current)
            removeParticipant(value)
        }
    }

    const renderParticipantLabel = () => {
        let a = participants
        return a.map(e => {
            let lwr = e.toLowerCase()
            return <Label email={lwr} key={lwr} style={{ marginTop: '5px', float: 'right' }}>
                {lwr in userMap ? `(${userMap[lwr]['username']}) ${userMap[lwr]['full']}` : lwr}
                <Icon value={lwr} onClick={onDelete} name='delete' />
            </Label>
        })
        
    }

    const renderDropdown = () => {
        let options = []

        if (users) { //only render if users passed
            options = users
                ?.map(e => ({
                    text: `${e?.user?.first_name} ${e?.user?.last_name} (${e?.user?.username})`,
                    value: e?.user.email.toLowerCase(),
                    key: e?.user_id,
                }))
                ?.filter(e => {return !participants.includes(e?.value)})
                ?.filter(e => e.value !== context.userInfo.email.toLowerCase()) //filter out self by email
            return (
                <div style={{ height: '100%', width: '100%', right: '14px', position: 'absolute' }} >
                    <Divider style={{ fontSize: '12px' }} horizontal>Participants</Divider>
                    <Dropdown
                        selectOnBlur={false}
                        fluid
                        selection
                        clearable
                        placeholder='Add Project Member ...'
                        onChange={onDropdownChange}
                        value={dropValue}
                        options={options}
                    />
                    <div style={{ marginTop: '5px', height: '70%', overflowY: 'auto' }}>
                        {renderParticipantLabel()}
                    </div>

                </div>
            )
        }

        return ''

    }

    return (
        renderDropdown()
    )
}

Participant.propTypes = {
    users: PropTypes.array,
    selectedIssue: PropTypes.object,
    updateParticipantCallback: PropTypes.func
}