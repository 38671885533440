import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Container, Card, Button, Loader, Message, Segment } from 'semantic-ui-react';
import Header from '../../components/Header';
import { BasicTable } from '../../components/BasicTable';
import memoize from 'memoize-one';
import * as _ from 'underscore';
import './Support.css';
import { GlobalTicketModal } from './GlobalTicketModal';
import { truncateDate } from '../../utils';
import { IssueDetailModal } from '../../components/DetailContent/TabTickets/IssueDetailModal';
import axios from 'axios';
import { GlobalContext } from '../../context';

const getConditionalRowStyles = () => [
    {
        when: row => row.status === "Resolved",
        style: {
            backgroundColor: 'rgba(105, 96, 101, 0.06)',
            color: 'grey',
        }
    }
]

class Support extends Component {
    static contextType = GlobalContext
    constructor(props) {
        super(props);
        const { search } = this.props.location
        const params = new URLSearchParams(search)

        this.state = {
            tickets: [],
            filteredTickets: [],
            addTicketModalOpen: false,
            dimmerOpen: false,
            displaySplash: true,
            detail_open: false,
            selectedIssue: {},
            data: [],
            loading : true,
            serverOpenTicketId: params.get('id') || null,
        }
    }

    componentDidMount() {
        const { username: current_username } = this.context.userInfo
        axios.get(`/api/issues/${current_username}/`, { withCredentials: true })
            .then(res => this.processData(res.data))
            .catch(err => {
                console.log(err)
                this.setState({ displaySplash: false })
            })
    }

    createTableRow = (data) => {
        if (!data.length)
            return []
            
        return (
            data
                .filter(el => el.jira.id) //filter out entries with empty jira objects
                .map(entry => {
                    let fields = entry?.jira?.fields;
                    return {
                        "id": entry?.id,
                        "summary": fields?.summary,
                        "description": fields?.description,
                        "date": truncateDate(fields?.created),
                        "type": fields?.customfield_10010?.requestType?.name,
                        "status": fields?.status?.name,
                        "project_id": entry?.project_id ? entry.project_id : '',
                        "parent_project_name": entry?.parent_project_name ? entry.parent_project_name : '',
                        "jira": entry?.jira
                    }
                })
        )
    }

    handleLoader = () => this.setState({loading: false})

    processData = (data) => {
        const { serverOpenTicketId } = this.state

        const tableData = this.createTableRow(data)

        if (serverOpenTicketId) { //User is navigating via URL direct link
            let selected = data.find(el => el.id === parseInt(serverOpenTicketId))
            if (selected) { // Ticket ID exists for this user, open the modal
                this.setState({ tickets: tableData, filteredTickets: tableData, displaySplash: false, data: data, detail_open: true, selectedIssue: selected })
            } else { //Just open support page regularly
                this.setState({ tickets: tableData, filteredTickets: tableData, displaySplash: false, data: data })
            }
        } else { //React change state, frontend
            this.setState({ tickets: tableData, filteredTickets: tableData, displaySplash: false, data: data })
        }
    }

    updateTableRow = (res) => {
        let row = this.createTableRow([res.data])
        this.setState({
            tickets: [...this.state.tickets, ...row],
            filteredTickets: [...this.state.filteredTickets, ...row],
            addTicketModalOpen: false,
            dimmerOpen: false,
            data: [...this.state.data, res.data]
        })
    }

    attachmentCallback = (res) => {
        var { data } = this.state
        var updatedIndex = (data.findIndex(obj => obj.id === res.data.id)) //Find index within data to update
        data[updatedIndex] = res.data
        this.setState({ data: data, selectedIssue: res.data })
    }

    commentCallback = (res) => {
        var { data } = this.state
        var updatedIndex = (data.findIndex(obj => obj.id === res.data.id)) //Find index within data to update
        data[updatedIndex] = res.data
        this.setState({ data: data, selectedIssue: res.data })
    }

    displayDetailModal = (row) => {
        if (this.state.detail_open) { //Clicking close
            this.setState({ detail_open: false })
        } else { //Clicking add
            let { data } = this.state
            let selected = data.find(el => el.id === row.id)
            this.setState({ detail_open: true, selectedIssue: selected }) //set selectedIssue for detail render
        }
    }

    handleTicketModal = () => {
        this.state.addTicketModalOpen ? this.setState({ addTicketModalOpen: false }) : this.setState({ addTicketModalOpen: true })
    }

    sortTickets = (rows, field, direction) => {
        var sorted = []
        switch (field) {
            case 'summary':
                sorted = _.sortBy(rows, (obj) => obj.summary.toUpperCase())
                break;
            case 'date':
                sorted = _.sortBy(rows, (obj) => new Date(obj.last_logged_event).getTime())
                break;
            case 'status':
                sorted = _.sortBy(rows, (obj) => obj.status === 'Open')
                break;
            case 'description':
                sorted = _.sortBy(rows, (obj) => obj.description.toUpperCase())
                break;
            case 'type':
                sorted = _.sortBy(rows, (obj) => obj.type.toUpperCase())
                break;
            case 'parent_project_name':
                sorted = _.sortBy(rows, (obj) => obj.parent_project_name.toUpperCase())
                break;
            default: //gets triggered on startup call, show linked projects first
                sorted = _.sortBy(rows, (obj) => obj?.description?.toUpperCase())
                break;
        }

        if (direction === 'asc') //to switch between ascending and descending sort options
            return sorted
        else
            return sorted.reverse()
    }

    searchTable = (e) => {
        const filterText = e.target.value.toLowerCase()
        const filtered = this.state.tickets.filter(entry => entry.summary && entry.summary.toLowerCase().includes(filterText))
        this.setState({ filteredTickets: filtered })
    }


    getColumns = memoize(() => [
        {
            name: 'Title',
            selector: row => row.summary,
            sortable: true,
        },
        {
            name: 'Parent Project Name',
            selector: row => row.parent_project_name,
            sortable: true,
            grow: 2,
            cell: row => row.parent_project_name ? <a target='_blank' rel="noopener noreferrer" href={`/detail/${row.project_id}`}> {row.parent_project_name} </a> : 'N/A'
        },
        {
            name: 'Date created',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            
            cell: row => <Button onClick={() => this.displayDetailModal(row)} size='tiny'>Detail</Button>,
            width: '120px'
        }
    ])

    getSubHeader = () => {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '30%', display: 'inline-block' }}>
                    <Input
                        onChange={this.searchTable}
                        placeholder='Search by Title ...'
                        icon='search'
                        fluid
                    />
                </div>

                <Button
                    size='small'
                    style={{ display: 'inline-block' }}
                    primary
                    loading={this.state.loading}
                    icon='add'
                    content={'Add Ticket'}
                    floated='right'
                    onClick={!this.state.loading ? this.handleTicketModal : undefined}
                />
            </div>
        )
    }

    getStyle = memoize(() => [
        {
            when: row => row,
            style: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === "Resolved",
            style: {
                backgroundColor: 'rgba(105, 96, 101, 0.06)',
                color: 'grey',
            }
        }
    ])

    render() {
        const { filteredTickets, addTicketModalOpen, dimmerOpen, displaySplash} = this.state
        const loader = <div style={{ display: 'inline' }}><Loader active={displaySplash} inline style={{ margin: '5px', fontSize: '15px' }} /></div>
        return (
            <div>
                <Header handleLogout={this.handleLogout} {...this.props} />
                <Container fluid style={{padding: '0px 20px 0px 20px'}}>
                    <Card fluid>
                        <Segment basic>
                            <Message info>
                                <Message.List>
                                    <Message.Item>This page will allow you to view all of your tickets across all projects in a central location</Message.Item>
                                    <Message.Item>You may submit global issues here unrelated to a project by clicking add ticket</Message.Item>
                                    <Message.Item>If you have a project specific question, please submit a ticket on the `tickets` Tab in project context </Message.Item>
                                </Message.List>
                            </Message>
                        </Segment>

                        <BasicTable
                            title={<><p style={{ marginRight: '5px', display: 'inline' }}>Support tickets</p>{loader}</>}
                            columns={this.getColumns()}
                            subHeaderComponent={this.getSubHeader()}
                            data={filteredTickets}
                            highlightOnHover
                            sortFunction={this.sortTickets}
                            // onRowClicked={this.displayDetailModal}
                            conditionalRowStyles={this.getStyle()}
                            {...this.props}
                        />
                    </Card>
                </Container>
                <GlobalTicketModal
                    open={addTicketModalOpen}
                    onClose={this.handleTicketModal}
                    callback={this.updateTableRow}
                    dimmerOpen={dimmerOpen}
                    handleLoader={this.handleLoader}
                />
                <IssueDetailModal
                    selectedIssue={this.state.selectedIssue}
                    detail_open={this.state.detail_open}
                    displayDetailModal={this.displayDetailModal}
                    attachmentCallback={this.attachmentCallback}
                    commentCallback={this.commentCallback}
                    {...this.props}
                />
            </div>
        )
    }
}

export default Support;

Support.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    replace: PropTypes.func
}