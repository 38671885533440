import React, { useContext } from 'react'
import {
    Modal, Dimmer, Loader
} from 'semantic-ui-react';
import StepWizard from 'react-step-wizard';
import PropTypes from 'prop-types'
import { StepReject } from '../StepReject'
import { StepRevise } from '../StepRevise'
import { StepInitial } from '../StepInitial'
import { StepAccept } from '../StepAccept'
import { GlobalContext } from '../../../../context';

/**
 * Detail Modal that children Steps inherit from
 * @prop {Boolean} open - whether the modal state is closed or open 
 * @prop {Function} onChange - callback function to update state of input field
 * @prop {Function} showModal - callback function that opens / closes Detail Modal via state update
 * @prop {object} data - Full data json object containing the content of the selected SOW
 * @prop {Boolean} - loading_modal - whether the modal is loading or not, to determine dimmer
 * @prop {Function} - truncateDate - converts unix timestamp into readable format
 * @prop {Function} - renderStatusText - Provides a text status based on integer field
 * @prop {String} - signature - Signature variable used in Accept step
 
 * @prop {Function} - submission_comment - comment variable used in REJECT/ACCEPT/REVISE
 */
export const DetailModal = ({ open, onChange, showModal, data, loading_modal,
    truncateDate, renderStatusText, signature, manageSOW,
    submission_comment, handleParentModalState, updateSelectedCallback, match: { params } }) => {
    const transitions = {}
    const context = useContext(GlobalContext);

    const forceModalClose = () => {
        showModal()
    }

    const renderTitle = () => {
        if (context?.userInfo?.is_admin)
            return <Modal.Header><a href={`?id=${data.id}`}>{data.title}</a></Modal.Header>
        else
            return <Modal.Header>{data.title}</Modal.Header>
    }
    
    if (data) {
        return (
            <Modal size='fullscreen' closeIcon open={open} onClose={forceModalClose} >
                {renderTitle()}
                <Modal.Content>
                    <Dimmer active={loading_modal} inverted>
                        <Loader inverted>Submitting</Loader>
                    </Dimmer>
                    <StepWizard isLazyMount transitions={transitions} initialStep={1}>
                        <StepInitial
                            step={1}
                            data={data}
                            truncateDate={(val) => truncateDate(val)}
                            renderStatusText={renderStatusText}
                            updateSelectedCallback={updateSelectedCallback}
                            params={params}
                        />
                        <StepAccept
                            step={2}
                            params={params}
                            signature={signature}
                            onChange={onChange}
                            manageSOW={manageSOW}
                            submission_comment={submission_comment}
                            handleParentModalState={handleParentModalState}
                            forceModalClose={forceModalClose}
                            type={data.types}
                        />
                        <StepReject
                            step={3}
                            submission_comment={submission_comment}
                            onChange={onChange}
                            manageSOW={manageSOW}
                        />
                        <StepRevise
                            step={4}
                            submission_comment={submission_comment}
                            onChange={onChange}
                            manageSOW={manageSOW}
                            data={data}
                        />
                    </StepWizard>
                </Modal.Content>
            </Modal>
        )
    } else { //Detail row not yet clicked, no data passed
        return ''
    }

}

DetailModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    showModal: PropTypes.func,
    data: PropTypes.object, //Full info
    loading_modal: PropTypes.bool,
    truncateDate: PropTypes.func, //Format date given unix timestamp
    renderStatusText: PropTypes.func,
    signature: PropTypes.string,
    manageSOW: PropTypes.func,
    updateSelectedCallback: PropTypes.func,
    // addSOW: PropTypes.func,
    // denySOW: PropTypes.func,
    submission_comment: PropTypes.string,
    handleParentModalState: PropTypes.func
}
