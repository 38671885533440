import React, { useState } from "react";
import { Modal, Form, Button, Message, Label, Divider} from "semantic-ui-react";
import PropTypes from 'prop-types'

export const EditFinanceModal = ({ row, open, showModal, editPTA }) => {
    const [pta, setPta] = useState(row.pta_charge_number)
    const [error, setError] = useState('')
    const handleModalState = (e, { value }) => setPta(value)
    const onSubmit = (e) => {
        const stripped = pta.trim()
        if (validate(stripped)) {
            editPTA(stripped, row.id)
        } else {
            setError('PTA number cannot be empty')
        }
    }

    const validate = (stripped) => {
        const req = [
            stripped.length > 0,
        ]
        return req.indexOf(false) == -1
    }

    return (
        <Modal size='small' closeIcon open={open} value={'add_open'} name='edit' onClose={showModal} >
            <Modal.Header>Edit PTA number</Modal.Header>
            <Modal.Content>
                {error ? <Message error content={error} /> : ''}
                <Label horizontal>
                    Linked Statement of Work: 
                </Label>
                {row.sow_finance_id ?? 'N/A'}
                <Divider />
                <Form>
                    <Form.Field>
                        <Form.Input
                            label='PTA number'
                            placeholder='Eg: 1234567890'
                            name='pta_number'
                            value={pta}
                            maxLength={128}
                            onChange={handleModalState}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Change'
                    onClick={onSubmit}
                    primary
                    disabled={row.pta_charge_number == pta}
                />
            </Modal.Actions>
        </Modal>
    )

}


EditFinanceModal.propTypes = {
    row: PropTypes.object,
    open: PropTypes.bool,
    showModal: PropTypes.func,
    editPTA: PropTypes.func
}
