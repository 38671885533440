import React, { useState } from 'react';
import {
    Modal, Form, Button, Message, Checkbox,
    Dimmer, Loader, Label, Segment, Divider, List
} from 'semantic-ui-react';
import axios from 'axios';
import qs from 'qs';
// import * as _ from 'underscore'
import { getCookieValue } from '../../../../utils';
import './RequestConsultationModal.css';
import PropTypes from 'prop-types';

/**
 * @prop {Boolean} open: State of modal
 * @prop {Function} onClose: Callback function that handles open
 * @prop {Object} params: contains pid and tab of page
 */
export const RequestConsultationModal = ({ open, onClose, match: { params } }) => {
    const [checked, setChecked] = useState(false); //Destructuring, data is set to default "", setData is updateFunction
    const [error, setError] = useState('')
    const [dimmer, setDimmer] = useState(false)

    const onChange = () => {
        setChecked(!checked)
    }

    const closeModal = () => {
        onClose()
        setChecked(false)
    }

    const onDismiss = () => {
        setError('')
    }

    const submit = (e, { reqtype }) => {
        const cookieValue = getCookieValue('csrftoken');
        var { pid } = params

        if (!reqtype) {
            setError('no type specified, please contact your administrator')
            return;
        }

        if (checked) {
            setDimmer(true)

            axios({
                method: 'post',
                url: `/api/surveys/${reqtype}/add/`,
                data: qs.stringify({
                    'project_id': pid
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": cookieValue,
                }
            }).then(res => {
                if (res?.data?.survey_url) {
                    window.location.href = res?.data?.survey_url
                } else {
                    setError('Survey unable to be generated, please contact administrator')
                    setDimmer(false)
                }
            })
                .catch(err => {
                    setError('Unable to redirect, Please contact administrator')
                    setDimmer(false)

                })
        } else {
            setError('Please check the box to submit')
        }
    }

    const renderAfterChecked = () => {
        return (
            <Form>
                <Message
                    info
                    list={[
                        'This project portal is built by RIT, but can also help direct you to other service providers within Stanford.',
                        'Please select the appropriate consultation type you would like to request.',
                        'If you need services from multiple groups, please create separate consultation requests for each group.'
                    ]}
                />
                <Segment>
                    <h4>Regular Consultation Request</h4>

                    <p>For requests involving Research IT assistance with: </p>
                    <List.List as='ul'>
                        <List.Item as='li'>New study design</List.Item>
                        <List.Item as='li'>Electronic data capture</List.Item>
                        <List.Item as='li'>Patient Surveys (e.g. ePRO)</List.Item>
                        <List.Item as='li'>Registries (with options for EPIC to REDCap Synchronization)</List.Item>
                        <List.Item as='li'>General REDCap support</List.Item>
                        <List.Item as='li'>Custom software development for research purposes</List.Item>
                    </List.List>

                    <Form.Field inline>
                        <Label color='blue' pointing='right' basic>Submit a regular consultation request:</Label>
                        <Button
                            size='mini'
                            onClick={submit}
                            primary
                            disabled={!checked}
                            content="Submit"
                            reqtype='consultation'
                        />
                    </Form.Field>

                    {/* <Divider section />

                    <h4>RIC data extract Request</h4>

                    <p>For requests involving the Research Informatics Center regarding:</p>
                    <List.List as='ul'>
                        <List.Item as='li'>Retrospective clinical data extracts</List.Item>
                        <List.Item as='li'>Electronic data capture from EPIC, Radiology/DICOM, and bedside monitoring</List.Item>
                    </List.List>
                    
                    <p className='RIC-meta'>If you support clinical research and would like your services added to this list, please contact us at rit-support@stanford.edu</p>

                    <Form.Field inline>
                        <Label color='green' pointing='right' basic>Submit a RIC data extract request:</Label>
                        <Button
                            size='mini'
                            onClick={submit}
                            color='green'
                            disabled={!checked}
                            content="Submit"
                            reqtype='data_extract'
                        />
                    </Form.Field> */}
                </Segment>


            </Form>
        )
    }

    const defaultMessage = () => {
        return (
            <>
                <Dimmer active={dimmer} inverted>
                    <Loader inverted>Redirecting...</Loader>
                </Dimmer>
                <Message
                    warning
                    header="Notice:"
                    list={[
                        'Upon clicking the submit button, you will be redirected to REDCap provide additional details of your request',
                        'After completion, a member of Stanford TDS will reach out to you to schedule a consultation',
                        'You will be able to check the details of your submission on this portal after completing the survey'
                    ]}
                />
                <Form>
                    <Form.Field
                        control={Checkbox}
                        onChange={onChange}
                        checked={checked}
                        label='I understand and have read the above'
                    />
                </Form>
                <Divider />
                {checked && renderAfterChecked()}

            </>
        )
    }

    // const RICmessage = () => {
    //     return (
    //         <>
    //             <Dimmer active={dimmer} inverted>
    //                 <Loader inverted>Redirecting...</Loader>
    //             </Dimmer>
    //             <Message
    //                 warning
    //                 header="Notice:"
    //                 list={[
    //                     'Some alternate text here about the RIC Request ... ',
    //                     'Upon clicking the submit button, you will be redirected to REDCap provide additional details of your request',
    //                     'After completion, a member of Stanford TDS will reach out to you to schedule a free one hour consultation',
    //                     'You will be able to check the details of your submission on this portal after completing the survey'
    //                 ]}
    //             />
    //             <Form>
    //                 <Form.Field
    //                     control={Checkbox}
    //                     checked={checked}
    //                     onChange={onChange}
    //                     label='I understand and have read the above'
    //                 />
    //                 <Form.Field>
    //                     <Button
    //                         onClick={submit}
    //                         primary
    //                         disabled={!checked}
    //                         content="Submit"
    //                         floated='right'
    //                         reqtype='data_extract'
    //                     />
    //                 </Form.Field>
    //             </Form>
    //         </>
    //     )
    // }

    // const panes = [
    //     { menuItem: 'Consultation', render: () => <Tab.Pane>{defaultMessage()}</Tab.Pane> },
    //     { menuItem: 'RIC', render: () => <Tab.Pane>{RICmessage()}</Tab.Pane> },
    // ]

    // const onTabChange = () => {
    //     setChecked(false)
    //     setDimmer(false)
    //     setError('')
    // }

    return (
        <Modal centered={false} size='fullscreen' closeIcon onClose={closeModal} open={open}>
            <Modal.Header>New Consultation Request</Modal.Header>
            <Modal.Content>
                {error ? <Message header='ERROR:' onDismiss={onDismiss} content={error} color='red' /> : ''}

                {/* <Tab onTabChange={onTabChange} menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} /> */}
                {defaultMessage()}

                {/* <Message
                    info
                    header="Notice:"
                    list={[
                        'Upon clicking the submit button, you will be redirected to REDCap provide additional details of your request',
                        'After completion, a member of Stanford TDS will reach out to you to schedule a free one hour consultation',
                        'You will be able to check the details of your submission on this portal after completing the survey'
                    ]}
                /> */}

                {/* <Form>
                    <Form.Field
                        control={Checkbox}
                        onChange={onChange}
                        label = 'I understand and have read the above'
                    />
                </Form> */}
            </Modal.Content>
            <Modal.Actions>
                {/* <Form.Field>
                    <Dimmer active={dimmer} inverted>
                        <Loader inverted>Redirecting...</Loader>
                    </Dimmer>
                    <Button
                        onClick={submit}
                        primary
                        disabled={!checked}
                        content="Submit"
                    />
                </Form.Field> */}
            </Modal.Actions>
        </Modal>
    )
}

RequestConsultationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    projectName: PropTypes.string,
    consultationSubmitCallback: PropTypes.func,
    match: PropTypes.object,
    name: PropTypes.string
}