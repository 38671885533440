import React from 'react'
import {
    Modal
} from 'semantic-ui-react';
import PropTypes from "prop-types";

export function ERegDetailModal({open, onClose, data}){
    const handleClose = () => {
		onClose()
    }
	const re =  /\|/g
	const replaced = data?.survey_summary?.replace(re, '')

    return (
        <Modal size='fullscreen' closeIcon open={open} onClose={handleClose}>
            <Modal.Header >
                Previous Submission Detail ({data?.id})
            </Modal.Header>
            <Modal.Content>
				<pre style={{whiteSpace: 'pre-wrap'}}>
					{replaced}
				</pre>
			</Modal.Content>
        </Modal>
    )
}

ERegDetailModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object
}

