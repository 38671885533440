import React, { useState } from 'react'
import {
    Modal, Form, Button,
    Dimmer, Loader, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types'

/**
 * Ticket Modal that allows the creation of new JIRA tickets
 * @param {Array} requestTypes - options for the select field to render in form
 * @param {Boolean} add_open - variable that controls modal state open/close
 * @param {Boolean} loading_ticket - T/F variable that determines whether dimmer is rendered upon ajax call
 * @param {Function} displayAddModal - Function that shows/hides this modal via state
 * @param {Function} addTicket - Function that handles ticket submissions via POST 
 */
export const TicketModal = ({ requestTypes, redcapProjects, add_open, loading_ticket, displayAddModal, addTicket }) => {
    const issueTypes = requestTypes?.map(val => ({ text: val.name, value: val.id }))
    const redcaps = redcapProjects?.map(val => ({ text: val?.redcap_project_name, value: val.redcap_id }))
    const [summary, setSummary] = useState('')
    const [type, setType] = useState('')
    const [redcap, setRedcap] = useState('')
    const [description, setDescription] = useState('')
    const [error, setError] = useState('')

    const onChange = (e, { name, value }) => {
        switch (name) {
            case 'summary':
                setSummary(value)
                break
            case 'type':
                setType(value)
                break
            case 'redcap':
                setRedcap(value)
                break
            case 'description':
                setDescription(value)
                break;
            default: 
                break;
        }
    }

    const onSubmit = () => {
        if (!summary || !type || !description) {
            setError('One or more required fields has been left blank, please fix the issue and try again')
        } else {
            const data = {
                'summary': summary,
                'type': type,
                'redcap': redcap,
                'description': description
            }
            addTicket(data)
            setSummary('')
            setType('')
            setDescription('')
            setRedcap('')
        }
    }
    
    return (
        <Modal size='small' closeIcon open={add_open} onClose={displayAddModal} >
            <Modal.Header>Create New Ticket</Modal.Header>
            <Modal.Content>
                <Message
                    info
                    size='tiny'
                    header='Please fill out the following form regarding your issue.'
                    content={[`You will be able to attach images and/or files after creation`]}
                />
                {error ? <Message error content={error} /> : ''}
                <Form>
                    <Form.Field>
                        <Form.Input required name={'summary'} value={summary} onChange={onChange} label={'Short title'} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Dropdown required name={'type'} value={type} onChange={onChange} selection label={'Ticket type'} options={issueTypes} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Dropdown name={'redcap'} value={redcap} disabled={redcaps?.length ? false : true} onChange={onChange} clearable selection label={'Associated REDCap project (optional)'} options={redcaps ?? []} />
                    </Form.Field>
                    <Form.Field>
                        <Form.TextArea required name={'description'} value={description} onChange={onChange} label={'Detailed description of issue'} />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Form.Field>
                    <Dimmer active={loading_ticket} inverted>
                        <Loader inverted>Submitting</Loader>
                    </Dimmer>
                    <Button onClick={onSubmit} primary>Create</Button>
                </Form.Field>
            </Modal.Actions>
        </Modal>
    )
}

TicketModal.propTypes = {
    requestTypes: PropTypes.array,
    redcapProjects: PropTypes.array,
    add_open: PropTypes.bool,
    loading_ticket: PropTypes.bool,
    displayAddModal: PropTypes.func,
    addTicket: PropTypes.func
}
