import React, { useEffect, useState, useMemo } from 'react'
import {
    Segment, Button, Message
} from 'semantic-ui-react'
import PropTypes from 'prop-types';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { CreateFinanceModal } from './Modals/Create';
import { EditFinanceModal } from './Modals/Edit';
import { NoDataComponent } from '../../NoDataComponent';
import { TableLoader } from '../../TableLoader';
import { truncateDate, getCookieValue } from '../../../utils';
import qs from 'qs';


export default function TabFinance({ match }) {
    const { pid } = match.params
    const [finances, setFinances] = useState('')
    const [selectedRow, setSelectedRow] = useState('')
    const [loading, setLoading] = useState(true)
    const [editOpen, setEditOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [sow, setSow] = useState('')
    const [error, setError] = useState('')

    const showModal = (e, { name, row }) => {
        if (name === 'edit') {
            setEditOpen(!editOpen)
            setSelectedRow(row)
        } else {
            setAddOpen(!addOpen)
        }
    }


    useEffect(() => {
        loadFinance()
    }, [pid]);

    const loadFinance = function () {
        axios
            .get(`/api/projects/${pid}/finances/`, { withCredentials: true })
            .then(res => {
                setFinances(res.data.finances)
                setSow(res.data.linked_sow)
                setLoading(false)
            }).catch(res => {
                setLoading(false)
                setError('Error fetching financial records. This is most likely a permission issue, please contact your administrator')
            })
    }

    const updateTableRow = (data) => {
        let copy = [...finances]
        let ind = copy.findIndex((el) => el.id === data.id)
        if (ind === -1)
            copy.push(data)
        else
            copy[ind] = data
        setFinances(copy)
        setLoading(false)
    }

    const editPTA = (pta, id) => {
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookieValue('csrftoken'),
            }
        }

        setLoading(true)
        showModal('', { 'name': 'edit' })
        axios
            .put(`/api/projects/${pid}/update-finance/${id}/`, qs.stringify({ 'pta_charge_number': pta }), config)
            .then(res => {
                loadFinance()
            })
            .catch(err => {
                setLoading(false)
                setError('Error updating financial records. This is most likely a permission issue, please contact your administrator')
            })
    }

    const addPTA = (data) => {
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookieValue('csrftoken'),
            }
        }

        setLoading(true)
        showModal('', {})

        axios
            .post(`/api/projects/${pid}/add-finance/`, qs.stringify(data), config)
            .then(res => {
                loadFinance()
            })
            .catch(err => {
                setLoading(false)
                setError('Error adding financial records. This is most likely a permission issue, please contact your administrator')
            })
    }


    const columns = useMemo(() => [
        {
            name: 'Finance ID',
            selector: row => row.id,
            sortable: true,
            omit: true
        },
        {
            name: 'PTA Number',
            selector: row => row.pta_charge_number,
            sortable: true,
            maxWidth: '250px'
        },
        {
            name: 'Created at',
            selector: row => truncateDate(row.finance_created_at),
            sortable: true,
        },
        {
            name: 'Last updated',
            selector: row => truncateDate(row.finance_updated_at) ?? 'N/A',
            sortable: true,
        },
        {
            name: 'Linked Statement of Work',
            cell: row => row?.sow_finance_id?.join(', '),
            sortable: true,
            // grow: 2,
        },
        {
            cell: row => <Button content='Edit' name='edit' onClick={showModal} row={row} />,
            width: '110px',

        },
    ], [finances])
    
    const subHeader = useMemo(() =>
        <Button
            onClick={showModal}
            name={'Add'}
            primary
            floated='right'
            content='Add PTA'
        />, []
    )

    return (
        <Segment attached='bottom' style={{ height: "100%" }}>
            {error && <Message color='red' content={error} /> }
            <DataTable
                title='Registered PTAs'
                columns={columns}
                data={finances}
                fixedHeader={true}
                fixedHeaderScrollHeight="300px"
                subHeaderComponent={subHeader}
                subHeader={true}
                persistTableHead
                progressComponent={<TableLoader loadingText='Loading...' active={loading} hg='200px' />}
                progressPending={loading}
                noDataComponent={<NoDataComponent text='There are no PTA numbers currently affiliated with this project' />}
            />
            {sow &&
                <CreateFinanceModal
                    open={addOpen}
                    addPTA={addPTA}
                    showModal={showModal}
                    sow={sow}
                />
            }

            {selectedRow &&
                <EditFinanceModal
                    name='edit'
                    open={editOpen}
                    showModal={showModal}
                    row={selectedRow}
                    editPTA={editPTA}
                    loading={loading}
                />
            }

        </Segment>
    )
}

TabFinance.propTypes = {
    match: PropTypes.object
}

