import React from "react"
import { Segment, Container, List } from "semantic-ui-react"

export default function TabDefault({ defaultData, ...props }) {
    const renderIRBtag = () => {
        if (defaultData.irb) {
            return (
                <>
                    <p className='detail_label'>
                        {`${defaultData.irb.protocol_department}`}
                    </p>
                    <p className='detail_label'>
                        {defaultData.irb.protocol_title}
                        {<strong>{` (IRB #${defaultData.irb.protocol_number})`}</strong>}
                    </p>
                </>
            )

        } else {
            return (
                <p className='detail_label_warning'>No current IRB linked, please link in edit project</p>
            )
        }
    }
    
    return (
        <Segment attached='bottom'>
            <Container fluid>
                <List relaxed divided>
                    <List.Item>
                        <List.Icon name='user' verticalAlign='top' />
                        <List.Content>
                            <List.Header>Principal investigator</List.Header>
                            <List.Description>
                                {defaultData?.owner_detail?.user ?
                                    <p>{defaultData.owner_detail?.user?.first_name + ' ' + defaultData.owner_detail?.user?.last_name}</p> :
                                    <p style={{ color: 'red' }}>None assigned, please add in members tab</p>
                                }
                            </List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='mail' verticalAlign='top' />
                        <List.Content>
                            <List.Header>Contact email</List.Header>
                            <List.Description>
                                {defaultData?.owner_detail?.user ?
                                    <p>{defaultData.owner_detail?.user?.email}</p> :
                                    <p style={{ color: 'red' }}>N/A</p>
                                }
                            </List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='book' verticalAlign='top' />
                        <List.Content>
                            <List.Header>Project IRB</List.Header>
                            <List.Description>
                                {renderIRBtag()}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='sidebar' verticalAlign='top' />
                        <List.Content>
                            <List.Header >Project description</List.Header>
                            <List.Description>
                                <p>{defaultData.projectDetail.project_description}</p>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Container>
        </Segment>
    )
}