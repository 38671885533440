import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import { Container, Message, Breadcrumb } from 'semantic-ui-react';
import { ChargeTable } from '.';
import * as _ from 'lodash';
import axios from 'axios';


export default function Charges(props) {
    const [data, setData] = useState([])
    const [sow, setSow] = useState('')
    const { sow_id } = props?.computedMatch?.params
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const getData = () => {
        axios.all([
            axios.get(`/api/projects/sow/${sow_id}/charges/`, { withCredentials: true }),
            axios.get(`/api/projects/sow/${sow_id}/`, { withCredentials: true }),
        ]).then(axios.spread((charges, sow) => {
            setData(charges?.data ? charges.data : [])
            setSow(sow.data)
            setIsLoading(false)
        })).catch(err => {
            if (err.response.data.message)
                setError(err?.response?.data?.message)
            else
                setError('Error: something went wrong with your fetch requests')
            setIsLoading(false)
        })

    }

    useEffect(() => {
        getData()
    }, [])
    
    const sections = [
        { key: 'Approved', content: <div>Approved</div>,  href:'/approved'},
        { key: 'Charges', content: sow.id ? `Charges (${sow.id})` : 'Charges' },
    ]
    
    return (
        <div>
            <Header {...props} />
            <Container fluid style={{ padding: '0 15px 0 15px' }}>
                {error && <Message error content={error} />}
                <ChargeTable
                    refresh={getData}
                    sow={sow}
                    previousCharges={data}
                    finance_pta_charge_number={sow?.finance_id__pta_charge_number ? sow.finance_id__pta_charge_number : undefined}
                    parentLoading={isLoading}
                    view='admin'
                    breadcrumb={<Breadcrumb icon='right angle' sections={sections} />}
                    {...props}
                />
            </Container>
        </div>
    )
}