import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Message} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {persistantNotifications, getCookieValue} from '../../utils';

/**
 * Wrapper for persistentNotifications to display Template messages on any of the tabs
 * @param {String} type - TAB the template should render on
 */
export const TemplateNotification = ({type}) => {
    const [data, setData] = useState('') //State variables
    const pern = (persistantNotifications.data)
    //Function that Acknowledges a notification / dismiss
    const handleDismiss = (e, arg) => {
        const cookieValue = getCookieValue('csrftoken');
        const {data} = arg
        if(data.id){
            axios
                .post(`/api/notifications/${data.id}/acknowledge/`, '', {
                    headers: {"X-CSRFToken" : cookieValue}
                })
                .then(res=>updatePersistentNotification(res))
                .catch(err=>console.log(err))
        }
    }

    //Cached notifications object (full) has to be updated on acknowledgement
    const updatePersistentNotification = (res) => {
        let notificationId = res.data.notification_id
        let previous = persistantNotifications.data 
        let filtered = previous.filter((obj)=> obj.id !== notificationId)
        persistantNotifications.update(filtered)
        setData('')
    }

    //equivalent to setting state on component render
    useEffect(() => {
        //Function that returns only the template object of a given type
        const filterByType = () => {
            if(pern && pern.length > 0){ //If notifications exist on this particular tab, set them.
                return (pern.filter((obj) =>
                    obj.render_location === '3' && obj.title.indexOf(type) !== -1))[0]
            }
            return ''
        }
        let notification = filterByType() //Templates should only have one peŹ
        setData(notification)
    }, [type, pern]);
    
    if(data){
        return (
            <Message
                info
                icon='info circle'
                header='Notice:'
                content={data.body}
                onDismiss={handleDismiss}
                data={data}
           />
        )
    } else {
        return ''
    }
    
}

TemplateNotification.propTypes = {
    type: PropTypes.string
}