import React, { useState, useMemo, useEffect } from "react";
import { Input, Button, Placeholder, Message, Divider, List, Label, Card } from "semantic-ui-react";
import { BasicTable } from "../../../components/BasicTable";
import { getCookieValue } from "../../../utils";
import axios from "axios";
import DOMPurify from 'isomorphic-dompurify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TableLoader } from "../../../components/TableLoader";
import { NoDataComponent } from "../../../components/NoDataComponent";
import { BasicPopup } from "../../../components/BasicPopup";
import './chargeTable.css';
import * as _ from 'underscore';

const style = {
    content: {
        maxHeight: "calc(50vh - 10em)",
        overflowY: "scroll",
        overflowX: "hidden",
        backgroundColor: "#f8f8f9",
        border: '1px solid rgb(0,0,0)',
        borderRadius: '7px'
    },
    innerContent: {
        margin: '20px'
    },

}

const conditionalRowStyles = [
    {
        when: row => row.newRow,
        style: {
            backgroundColor: 'rgba(93, 247, 103, 0.2)',
            color: 'black',
        }
    },
    {
        when: row => row.edited,
        style: {
            backgroundColor: 'rgba(238, 238, 51, 0.2)',
            color: 'black',
        }
    },
    {
        when: row => row.delete,
        style: {
            backgroundColor: 'rgba(238, 51, 51, 0.2)',
            color: 'black',
        }
    }

]

export default function ChargeTable({ refresh, sow, previousCharges, finance_pta_charge_number, parentLoading, view, breadcrumb, ...props }) {
    const [isDirty, setIsDirty] = useState(false)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [editedRowData, setEditedRowData] = useState({
        pta: null,
        description: null,
        amount: null,
        start_date: null
    })

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    })

    const [error, setError] = useState('')

    useEffect(() => {
        setData(previousCharges)
        setIsLoading(parentLoading)
    }, [previousCharges])

    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
            // use the selector function to resolve your field names by passing the sort comparitors
            const aField = selector(rowA) || ''
            const bField = selector(rowB) || ''
            let comparison = 0;

            if(typeof(aField) === 'string') {
                if (!aField) { //Put null values at the end of the array
                    return 1;
                }
                if (!bField) {
                    return -1;
                }
                comparison = aField.localeCompare(bField)

                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                if(aField > bField)
                    comparison = 1
                else if(aField < bField)
                    comparison = -1
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    }

    const setColumnValue = (e) => {
        setEditedRowData((oldState) => ({ ...oldState, [e.target.name]: e.target.value }))
    }

    const addRow = (e, arg) => {
        let greatest = 0 // no rows in table
        if (data.length)
            greatest = Math.max(...data.map(o => o.charge_id))
        if (arg.count) { // Coming from add many callback
            let args = Array.from({ length: parseInt(arg.count) }, (e, i) => {
                let current = new Date(`${arg.start_date}T00:00`)
                let newDate = new Date(current.setMonth(current.getMonth() + i)).toISOString().slice(0, 10)
                return {
                    charge_id: greatest + i + 1,
                    description: arg.description + ` ${i + 1}`,
                    start_date: newDate,
                    sow_id: sow.id,
                    finance_pta_charge_number: finance_pta_charge_number,
                    amount: String(Math.ceil(parseInt(arg.amount) / parseInt(arg.count))),
                    invoice_id: '',
                    newRow: 'yes'
                }
            })
            setData([...data, ...args])
            setIsDirty(true)

        }
    }

    // Returns true/false if all requirements are met
    const verify = (row) => {
        const requirements = [
            row?.description?.length > 0,
            row?.finance_pta_charge_number ? true : false,
            row?.amount > 0,
            /^\d*\.?\d+$/.test(row.amount), //Check if string contains only digits with/without decimal
            row?.start_date?.length > 0,
        ]
        return requirements.indexOf(false) === -1
    }

    const setDateValue = (e, arg, row) => {
        let conv = new Date(e).toISOString().slice(0, 10)
        setEditedRowData((oldState) => ({ ...oldState, start_date: conv }))
    }

    /**
     * Generates request promises to be called depending on bulk data passed in
     * @param {array} add
     * @param {array} del
     * @param {array} edit
     * @returns {array} promises
     */
    const generateRequests = (add, edit) => {
        const addRequest = add.length ?
            axios.post(`/api/projects/sow/${sow.id}/charges/add/`,
                { charges: add }, { headers: { "X-CSRFToken": getCookieValue('csrftoken') } }) : undefined;
        const editRequest = edit.length ?
            axios.delete(`/api/projects/sow/${sow.id}/charges/edit/`,
                { headers: { "X-CSRFToken": getCookieValue('csrftoken') }, data: { ids: JSON.stringify(edit) } }) : undefined;
        return [addRequest, editRequest]
    }

    const submitCharges = () => {
        let newCharges = []
        let deletedCharges = []
        let editedCharges = []

        for (let row of data) {
            if (row.newRow)
                newCharges.push(row)
            else if (row.delete)
                deletedCharges.push(row)
            else if (row.edited)
                editedCharges.push(row)
        }

        for (let charge of newCharges.concat(editedCharges)) {
            if (!verify(charge)) {
                setError('Error, one of your rows in the charge table does not have a valid value')
                return
            }
        }

        //Execute all requests if they exist
        setIsLoading(true)
        Promise.all(generateRequests(newCharges, deletedCharges.concat(editedCharges))).then((res) => {
            refresh()
            setIsDirty(false)
        }).catch(err => {
            if (err.response)
                setError(err?.response?.data?.message)
            else
                setError('Error: something went wrong')
            setIsLoading(false)
        });
    }

    const onClearInput = (e) => {
        setEditedRowData((oldState) => ({ ...oldState, [e.target.name]: '' }))
    }

    const addManyCallback = (e) => {
        addRow('', e)
    }

    const columns = useMemo(() => {
        const onEditRow = (e, { row }) => {
            setInEditMode({ status: true, rowKey: row.charge_id })
            setEditedRowData({
                finance_pta_charge_number: row.finance_pta_charge_number,
                amount: row.amount,
                description: row.description,
                start_date: row.start_date
            })
        }
        const onSaveRow = (e, { row }) => {
            let copy = [...data]
            let index = copy.findIndex((e) => e.charge_id === row.charge_id)
            copy[index] = {
                charge_id: row.charge_id,
                start_date: editedRowData.start_date ?? row.start_date,
                invoice_id: '',
                sow_id: row.sow_id,
                description: editedRowData.description ?? row.description,
                amount: editedRowData.amount ?? row.amount,
                finance_pta_charge_number: editedRowData.finance_pta_charge_number ?? row.finance_pta_charge_number,
                // for visualization purposes
                edited: row.newRow || row.delete ? undefined : true,
                newRow: row.newRow ?? undefined,
                delete: row.delete ?? undefined
            }

            setIsDirty(true)
            setData(copy)
            setInEditMode(false)
        }

        const markDeleteRow = (e, { row }) => {
            let copy = [...data]
            let filter = copy.filter(fx => {
                if (fx.charge_id === row.charge_id && !fx.newRow) { // if previously saved data deleted, mark for deletion
                    fx['delete'] = true
                    if ('edited' in fx)
                        delete fx['edited']
                }
                if (fx.charge_id === row.charge_id && fx.newRow) // delete new rows immediately
                    return false
                else
                    return true
            })

            setIsDirty(true)
            setData(filter)
        }

        return [
            {
                name: 'Charge ID',
                selector: row => row.charge_id,
                sortable: true,
                // omit: view !== 'admin'

            },
            {
                name: 'Description',
                cell: row => {
                    return inEditMode.status && row.charge_id === inEditMode.rowKey ?
                        <Input
                            onFocus={onClearInput}
                            name='description'
                            maxLength='128'
                            style={{ width: '100%', right: '10px' }}
                            size='small'
                            row_id={row.id}
                            onChange={setColumnValue}
                            value={editedRowData.description}>
                        </Input> : row.description
                },
                sortable: true,
                selector: row => row.description,
                wrap: true,
                grow: 3
            },
            {
                name: 'Start Date',
                cell: row => {
                    return inEditMode.status && row.charge_id === inEditMode.rowKey ?
                        <DatePicker
                            name='start_date'
                            withPortal
                            row_id={row.id}
                            selected={new Date()}
                            value={editedRowData.start_date}
                            onChange={(e, arg) => setDateValue(e, arg, row)}
                            popperProps={{ strategy: 'fixed', placement: 'auto' }}
                        /> : row.start_date
                },
                sortable: true,
                selector: row => row.start_date
            },
            {
                name: 'SOW ID',
                selector: row => row.sow_id,
                omit: true
            },
            {
                name: 'PTA',
                selector: row => row.finance_pta_charge_number,
                omit: view !== 'admin'

                // cell: row => {
                //     return inEditMode.status && row.id === inEditMode.rowKey ?
                //     <Input
                //         name='pta'
                //         maxLength='48'
                //         style={{width: '100%', right: '10px'}}
                //         size="small"
                //         row_id={row.id}
                //         onChange={setColumnValue}
                //         value={editedRowData.pta}>
                //     </Input> : row.pta
                // },
            },
            {
                name: 'Amount',
                cell: row => {
                    return inEditMode.status && row.charge_id === inEditMode.rowKey ?
                        <Input
                            name='amount'
                            onFocus={onClearInput}
                            maxLength='24'
                            style={{ width: '100%', right: '10px' }}
                            size="small"
                            row_id={row.id}
                            onChange={setColumnValue}
                            value={editedRowData.amount}>
                        </Input> : `$${row.amount}`
                },
                selector: row => row.amount,
                sortable: true
            },
            {
                name: 'Invoice item',
                selector: row => row.invoice_item,
                sortable: true
            },
            {
                name: 'Functions',
                cell: row => {
                    if (row.invoice_item_id)
                        return undefined
                    return inEditMode.status && row.charge_id === inEditMode.rowKey ?
                        <Button row={row} onClick={onSaveRow} compact size='tiny' color="green" icon='checkmark'></Button>
                        :
                        <>
                            <Button disabled={inEditMode.status} row={row} onClick={onEditRow} compact size='tiny' icon='edit' />
                            <Button disabled={inEditMode.status} floated="right" row={row} onClick={markDeleteRow} compact size='tiny' color="red" icon='minus'></Button>
                        </>

                },
                omit: view !== 'admin' //On SOW page, hide items.
            }
        ]
    }, [editedRowData, inEditMode, data])

    const tableHeader = () => {
        return (
            <>
                {/* <Button size='tiny' disabled={inEditMode.status} onClick={addRow} color='green' icon='plus' content='Add' /> */}
                {view === 'admin' && //render only if within admin context page
                    <BasicPopup
                        title='Add many'
                        color='green'
                        onSubmit={addManyCallback}

                    />
                }
                {/* <Button size='tiny' disabled color='green'>Add Many</Button> */}
            </>
        )
    }

    const renderSowFields = () => {
        return (
            <>
                <Label style={{ marginBottom: '10px' }} horizontal>SOW detail</Label>


                <div style={style?.content}>
                    {!sow?.content &&
                        <Placeholder fluid>
                            <Placeholder.Image square />
                        </Placeholder>
                    }
                    <div
                        style={style.innerContent}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sow?.content) }}
                    />
                </div>
                <List>
                    <List.Item>
                        <Label horizontal>Title</Label>
                        {sow?.title}
                    </List.Item>
                    {view === 'admin' &&
                        <List.Item>
                            <Label horizontal>Registered PTA for SOW #{sow?.id}</Label>
                            {finance_pta_charge_number}
                        </List.Item>
                    }
                </List>
            </>
        )
    }
    return (
        <Card fluid>
            <Card.Content>
            {breadcrumb && <><div>{breadcrumb}</div><br/></>}

                {error && <Message error content={error} />}
                {renderSowFields()}
                <Divider section />
                <BasicTable
                    data={data}
                    columns={columns}
                    subHeaderComponent={tableHeader()}
                    subHeaderAlign='right'
                    dense
                    defaultSortFieldId={1}
                    sortFunction={customSort}
                    defaultSortAsc={false}
                    conditionalRowStyles={conditionalRowStyles}
                    progressPending={isLoading}
                    noDataComponent={<NoDataComponent text='No current charges' />}
                    progressComponent={<TableLoader loadingText='Loading...' active={isLoading} hg='100px' />}
                />
                { view === 'admin' &&
                    <Button
                        floated="right"
                        disabled={inEditMode.status || !isDirty}
                        color='blue'
                        content='Save'
                        onClick={submitCharges}
                    />
                }
            </Card.Content>
        </Card>
    )
}
