import React from "react"

export const defaultConsultationPanels = [
    {
        key: 'cp1',
        title: {
            content: 'What is a consultation?'
        },
        content: {
            content: (
                <>
                    <p>
                        Consultations
                    </p>
                </>
            )
        }
    },
    {
        key: 'cp2',
        title: {
            content: 'How can I create a new consultation?'
        },
        content: {
            content: (
                <>
                    <ul>
                        <li>1. To create a new consultation, navigate to a research project</li>
                        <img alt='cp2' src='https://storage.googleapis.com/project-portal-static/FAQ/Consultation/c1.png'></img>    
                    </ul>
                    
                </>
            )
        }
    }
]

