import React, { useState, useContext } from 'react';
import {
    Modal, Form, Button,
    Dimmer, Loader, Segment, List, Header,
    Label, Grid, Popup, Feed, Icon, Message,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { truncateStringEllipses, getCookieValue } from '../../../../utils';
import axios from 'axios';
import qs from 'qs';
import DOMPurify from 'isomorphic-dompurify';
import { GlobalContext } from '../../../../context';
import Participant from '../../../../components/Participant';

export const IssueDetailModal = ({ selectedIssue, detail_open, displayDetailModal, 
    commentCallback, attachmentCallback, users, updateParticipantCallback, ...props }) => {
    
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false)
    const [attachmentLoading, setAttachmentLoading] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [attachedFile, setAttachedFile] = useState('')
    const fileInputRef = React.createRef();
    const [displayShort, setDisplay] = useState(true)
    const context = useContext(GlobalContext)
    const onFileChange = (file) => setAttachedFile(file)
    const onCommentChange = ({ target: { value } }) => setComment(value)

    const handlePopup = () => {
        if (popupOpen) {
            setPopupOpen(false)
            setAttachedFile('')
        } else {
            setPopupOpen(true)
        }
    }

    const onCommentSubmit = () => {
        setLoading(true)
        const pid = selectedIssue.project_id
        const cookieValue = getCookieValue('csrftoken');
        var url = ''
        if (pid) { //user is submitting from a project context
            url = `/api/projects/${pid}/issue/${selectedIssue.id}/add-comment/`

        } else {
            url = `/api/issues/${selectedIssue.id}/add-comment/`
        }

        axios({
            method: 'post',
            url: url,
            data: qs.stringify({
                body: comment,
                public: "true"
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": cookieValue,
            }
        }).then((res) => {
            setLoading(false)
            setComment('')
            commentCallback(res)
        })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const uploadAttachment = () => {
        setAttachmentLoading(true)
        const pid = selectedIssue.project_id
        const cookieValue = getCookieValue('csrftoken');
        var url = ''
        if (pid) {
            url = `/api/projects/${pid}/issue/${selectedIssue.id}/add-attachment/`
        } else {
            url = `/api/issues/${selectedIssue.id}/add-attachment/`
        }

        const data1 = new FormData()
        data1.append('file', attachedFile, attachedFile.name)

        axios.post(url, data1, {
            headers: { "X-CSRFToken": cookieValue }
        }).then(res => {
            setLoading(false)
            setAttachedFile('')
            handlePopup()
            attachmentCallback(res)
        })
            .catch(err => {
                console.log(err)
                setAttachmentLoading(false)
            })
    }

    const parseDisplayName = (bodyText) => {
        if (bodyText && bodyText.includes("(*") && bodyText.includes("*)")) { //New comments will have username appended to body, must parse 
            let displayName = bodyText.substring(
                bodyText.indexOf("(*") + 2,
                bodyText.lastIndexOf("*)")
            );

            let body = bodyText.substring(
                bodyText.lastIndexOf("*)") + 2,
                bodyText.length
            );
            return [displayName, body]
        }
        return ['', bodyText]
    }

    const generateCommentFeed = () => {
        let filter = selectedIssue?.jira?.fields?.comment?.comments.filter(el => el.body.indexOf("!attachment"))

        let commentFeed = filter.map((val, index) => {
            let values = parseDisplayName(val.body)

            if (val.jsdPublic) { //Ensure the comment should be shown to the user
                var truncateCommentDate = val.created.split(/[TZ.]+/)
                return (
                    <Feed.Event key={index}>
                        <Feed.Label>
                            <img alt={'avatar'} src={val.author.avatarUrls['16x16']} />
                        </Feed.Label>
                        <Feed.Content>
                            <Feed.Summary>
                                <Feed.User>{values[0]?.length ? values[0] : val?.author?.displayName}</Feed.User>
                                <Feed.Date>{truncateCommentDate[0] + " @ " + truncateCommentDate[1]}</Feed.Date>
                            </Feed.Summary>
                            <Feed.Extra text>{values[1]}</Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                )
            } else {
                return ''
            }
        })
        return (
            <Feed style={{ height: '200px', overflowY: 'auto' }}>
                {commentFeed.length > 0 ? commentFeed : <Message style={{ textAlign: 'center' }} content='No current comments' />}
            </Feed>
        )

    }

    const generateAttachmentFeed = () => {
        let sorted = selectedIssue?.jira?.fields?.attachment.sort((a, b) => {
            return (new Date(a.created).getTime() - new Date(b.created).getTime())
        })

        let attachmentFeed = sorted.map((val, index) => {
            var truncateCommentDate = val.created.split(/[TZ.]+/)
            return (
                <Feed.Event key={index}>
                    <Feed.Label>
                        <img alt={'avatar'} src={val.author.avatarUrls['16x16']} />
                    </Feed.Label>
                    <Feed.Content>
                        <Feed.Summary>
                            <Feed.User>{val.author.displayName}</Feed.User>
                            <Feed.Date>{truncateCommentDate[0] + " @ " + truncateCommentDate[1]}</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra >
                            {val.thumbnail ?
                                <a href={val.content}>
                                    <img alt='content-thumb' style={{ 'maxWidth': '64px' }} src={val.content} />
                                </a>
                                :
                                <a href={val.content}>
                                    <Icon size='big' name='file alternate' />
                                </a>
                            }
                        </Feed.Extra>
                        <Feed.Meta>{val.filename}</Feed.Meta>
                    </Feed.Content>
                </Feed.Event>
            )
        })

        return (
            <Feed style={{ height: '200px', overflowY: 'auto' }}>
                {attachmentFeed.length > 0 ? attachmentFeed : <Message style={{ textAlign: 'center' }} content='No current attachments' />}
            </Feed>
        )
    }

    const handleDescription = () => displayShort ? setDisplay(false) : setDisplay(true)

    const ellipses = selectedIssue?.jira?.fields?.description.length > 125 ? '...' : ''

    if (selectedIssue?.jira) { //ensure data has been loaded
        const creationDate = selectedIssue.created_at ? selectedIssue.created_at.split(/[TZ]+/) : ''
        const parent_project_id = selectedIssue?.project_id
        const parent_project_name = selectedIssue?.parent_project_name
        const detailedHeader = context.userInfo.is_admin ? <a target='_blank' href={selectedIssue.jira.fields.customfield_10010._links?.agent}>{selectedIssue.jira_issue_key}</a> : selectedIssue.jira_issue_key
        const { pathname } = props?.location //Current location of component
        const ticketDescription = DOMPurify.sanitize(selectedIssue?.jira?.fields?.description)?.replace(/<(.|\n)*?>/g, '')


        return (
            <Modal size='fullscreen' closeIcon open={detail_open} onClose={displayDetailModal} >
                <Modal.Header>
                    <Icon color='red' name='bug' />
                    {detailedHeader}
                </Modal.Header>
                <Modal.Content>
                    <h3>{selectedIssue?.jira?.fields?.summary}</h3>
                    <Segment>
                        <Grid>
                            <Grid.Row columns={2}>
                                {/* Only render support width of 12 if on the tickets page */}
                                <Grid.Column width={pathname !== "/support" ? 12 : 16}>
                                    <List size='medium'>
                                        <List.Item >
                                            <Label horizontal>Creation Date</Label>
                                            {creationDate ? creationDate[0] + " @ " + creationDate[1].substring(0, 8) : ''}
                                        </List.Item>
                                        <List.Item >
                                            <Label horizontal>Parent project (id)</Label>
                                            <strong>{parent_project_name ? parent_project_name + ` (${parent_project_id})` : 'N/A'}</strong>
                                        </List.Item>
                                        <List.Item>
                                            <Label>
                                                {selectedIssue.jira.fields.status.name === 'Done' ? <Icon color='green' name='checkmark' /> : <Icon color='blue' name='circle outline' />}
                                                {selectedIssue.jira.fields.status.name}
                                            </Label>
                                        </List.Item>

                                    </List>
                                    <List size='medium' style={{ marginBottom: '3px' }}>
                                        {selectedIssue.portal_redcap_id &&
                                            <>
                                                <List.Item>
                                                    <Label >Linked REDCap Project </Label>
                                                    <a href={selectedIssue?.redcap_link ?? undefined}>{selectedIssue?.redcap_name}</a>
                                                </List.Item>
                                                <List.Item>
                                                    <Label>Linked REDCap Project id </Label> {selectedIssue?.redcap_pid}
                                                </List.Item>
                                            </>
                                        }
                                    </List>
                                    <List style={{ paddingRight: '40px' }}>
                                        {displayShort ?
                                            <List.Item>{ticketDescription.substring(0, 125) + ' ' + ellipses}</List.Item> :
                                            <List.Item>{ticketDescription}</List.Item>
                                        }
                                        <br />
                                        {
                                            ticketDescription.length > 125 &&
                                            <Button
                                                hidden={true}
                                                icon={displayShort ? 'angle down' : 'angle up'}
                                                color='blue'
                                                size='mini'
                                                onClick={handleDescription}
                                                content={displayShort ? 'Show' : 'Hide'}
                                            />
                                        }
                                    </List>
                                </Grid.Column>
                                {pathname !== "/support" &&
                                    <Grid.Column width={4} >
                                        <Participant
                                            selectedIssue={selectedIssue}
                                            updateParticipantCallback={updateParticipantCallback}
                                            users={users}
                                        />
                                    </Grid.Column>
                                }

                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Modal.Content>
                <Modal.Content>
                    <Segment>
                        <Grid divided>
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <strong><h4>Comments</h4></strong>
                                    <hr />
                                    {generateCommentFeed()}
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <strong><h4>Attachments</h4></strong>
                                    <hr />
                                    {generateAttachmentFeed()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Form>
                        <Form.Field>
                            <Dimmer active={loading} inverted>
                                <Loader inverted>Submitting</Loader>
                            </Dimmer>
                            <Form.TextArea value={comment} onChange={onCommentChange}
                                placeholder={'Add a comment ...'} />

                        </Form.Field>
                        <Form.Field>
                            {/* Attachment upload button */}
                            <Popup
                                style={{ width: '300px' }}
                                position='top right'
                                wide
                                trigger={<Button icon='attach' positive content="Attach" />}
                                on='click'
                                open={popupOpen}
                                onOpen={handlePopup}
                                closeOnDocumentClick={true}
                                onClose={handlePopup}

                                content={
                                    <div>
                                        <Dimmer inverted active={attachmentLoading}>
                                            <Loader style={{ top: '55px' }} inverted>Submitting</Loader>
                                        </Dimmer>
                                        <Segment placeholder
                                            content={
                                                <div>
                                                    <Header as='h5' textAlign='center' icon>
                                                        <Icon name='file outline' />
                                                        {attachedFile ? truncateStringEllipses(attachedFile.name, 20) : 'Choose File to Upload'}
                                                    </Header>
                                                    <Button
                                                        primary
                                                        content='Add File'
                                                        onClick={() => fileInputRef.current.click()}
                                                    />
                                                </div>
                                            }
                                        />
                                        <Button
                                            fluid
                                            content='Submit'
                                            floated="left"
                                            icon="upload"
                                            positive
                                            disabled={attachedFile ? false : true}
                                            onClick={uploadAttachment}
                                        />
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            hidden
                                            onChange={(e) => onFileChange(e.target.files[0])}
                                        />
                                    </div>
                                }
                            />
                            <Button disabled={comment.length > 0 ? false : true} primary onClick={onCommentSubmit}>Save</Button>
                        </Form.Field>
                    </Form>
                </Modal.Actions>
            </Modal>
        )
    } else {
        return ''
    }
}

IssueDetailModal.propTypes = {
    selectedIssue: PropTypes.object,
    detail_open: PropTypes.bool,
    displayDetailModal: PropTypes.func,
    commentCallback: PropTypes.func,
    attachmentCallback: PropTypes.func,
    users: PropTypes.array,
    updateParticipantCallback: PropTypes.func
}
