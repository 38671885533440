import React, { Component } from 'react'
import {
    Message, Card, Button, Form, Divider, Dimmer, Loader
} from 'semantic-ui-react'
import PropTypes from 'prop-types';
import axios from 'axios'
import { getCookieValue } from '../../../utils'
import qs from 'qs';
import { TemplateNotification } from '../../TemplateNotification'

class TabInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: '',
            data: '',
            open: false,
            editPermission: false,
            updated: false,
			loading: true
        }
    }

    componentDidMount() {
        let { pid } = this?.props?.match?.params
        axios
            .all([
                axios.get(`/api/projects/${pid}/details/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/users/`, { withCredentials: true }).catch(this.useNull)
            ]).then(axios.spread((projectDetail, users) => {
                if (users && users.data) {
					this.setState(
						{
							data: projectDetail.data,
							editPermission: this.checkPermissions(users.data.results),
							loading: false
						}
					)
                } else {
                   this.setState({error: 'Error in fetching user details, please contact your administrator'})
                }
            }))
    }

    useNull = () => null

    //To remove the persistant notification from success
    componentDidUpdate() {
        if (this.state.updated) {
            this.hideSuccessMessage = setTimeout(() => {
                this.setState(() => ({ updated: false }))
            }, 4000)
        }
    }

    componentWillUnmount() { //clear this.hideSuccessMessage to prevent accidental call on unmounted component
        clearTimeout(this.hideSuccessMessage);
    }

    /**
	 * Determines whether a user has permissions to edit
	 * @return {Boolean} - T/F edit permissions
	 * @param users
	 */
    checkPermissions = (users) => {
        const { id: current_user_id, is_admin } = this.props.userInfo.data //Current user information to compare against

        if (is_admin) { //If the currently logged user is a super admin (RIT) this key will be set, otherwise it is undefinied
            return true
        } else {
            const user_index = users.findIndex(ind => ind.user_id === current_user_id)
            const current_user_obj = users[user_index]

            if (current_user_obj) {
                let permissions = current_user_obj.user.groups.permissions
                let check = permissions.find(perm => perm.codename === 'change_details')
                return !!check;
            }
        }
    }


    /**
     * Function that manages state of each form-field
     * @param {Object} arg - field arguments
     */
    onChange = (e, arg) => {
        const newData = { ...this.state.data } //create new copy of data to not mutate old, need for default values on form

		if ('checked' in arg) //checkbox has different method for state
            newData[arg.name] = arg.checked
        else if (arg.name === 'platform')
            newData[arg.name][0] = arg.value
        else
            newData[arg.name] = arg.value

        this.setState({ data: newData }) //setState
    }

    submit = () => { //Update data upon button click
        const { data } = this.state
        var { pid } = this.props.match.params

        if (data) {
            // const requiredFields = [
            //     data.timeline_information ? data.timeline_information : false,
            //     data.budgeting_constrains ? data.budgeting_constrains : false,
            // ]
            // if (requiredFields.indexOf(false) === -1) { //if both are true
			axios({
				method: 'PUT',
				url: `/api/projects/${pid}/update-details/`,
				data: qs.stringify({
					// inquiry_detail: data.inquiry_detail,
					timeline_information: data.timeline_information,
					budgeting_constrains: data.budgeting_constrains,
					plan_to_publish: data.plan_to_publish,
					funding: data.funding,
					platform: data.platform[0],
					has_phi: data.has_phi,
					department: data.department,
				}),
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
					"X-CSRFToken": getCookieValue('csrftoken'),
				}
			}).then(res => {
				this.setState({ data: res.data, updated: true, error: false })
			}).catch(err => console.log(err))
            // } else {
            //     this.setState({ error: 'Please ensure all fields are filled out before submitting' })
            // }
        }
    }


    /** Returns a message with the following attributes
     * @param header - Header text
     * @param message - Content body
     * @param type - type of message, determines color, likely 'error'
     */
    renderMessage = (header, message, type) => {
        return (
            <Message
                header={header ? header : ''}
                content={message}
                {...{ [type]: true }} //add type to props
            />
        )
    }

    render() {
        const { editPermission, error, data, updated, loading } = this.state
        const options = [ //Options for Dropdown menu, static for now
            { text: "Funded - Grant", value: 1 },
            { text: "Funded - Departmental / Gift", value: 2 },
			{ text: "Funded - Industry Sponsored", value: 3 },
            { text: "Seeking Funding", value: 10 },
            { text: "Unfunded", value: 20 },
            { text: "Funding Status Unknown", value: 99 },
        ]

        const platforms = [ //Options for Dropdown menu, static for now
            { text: "REDCap", value: 1 },
            { text: "STARR", value: 2 },
            { text: "mHealth", value: 3 },
            { text: "CHOIR", value: 4 },
            { text: "Other", value: 99 }
        ]

        const has_phi = [ //Options for Dropdown menu, static for now
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
        ]
        return (
            <Card fluid>
                <Card.Content>
                    <TemplateNotification
                        type='info_tab'
                    />
                    {error ? <Message color='red' content={error} /> : ''}
                    {updated ? <Message color='green' content="Updated Successfully!" /> : ''}
					 <Dimmer active={loading} inverted>
						<Loader inverted>Loading...</Loader>
					 </Dimmer>
                    <Form>
                        <Form.Dropdown
                            width={4}
                            options={has_phi}
                            value={data['has_phi']}
                            fluid
                            name="has_phi"
                            selection
							required
                            placeholder="Select ..."
                            label="Does your project contain PHI data?"
                            onChange={this.onChange}
                        />
                        <Divider />
                        <Form.Group widths='equal'>
                            <Form.Dropdown
                                options={platforms}
                                value={data['platform'] && data['platform'].length > 0 ? data['platform'][0].id : ''}
                                fluid
                                name="platform"
                                selection
                                placeholder="Select ..."
                                label="IaT Platform"
                                onChange={this.onChange}
                            />
                            {/* <Form.Field label='An HTML <input>' control='input' /> */}

                            <Form.Input
                                onChange={this.onChange}
                                name='department'
                                label="Department"
                                value={data['department'] ? data['department'] : ''}

                            />
                        </Form.Group>

                        <Form.Dropdown
                            options={options}
                            value={data.funding}
                            fluid
                            name="funding"
                            selection
                            placeholder="Select ..."
                            label="Funding Type"
                            onChange={this.onChange}
                        />
                        {/* <Form.TextArea
                                value={data['inquiry_detail'] ? data['inquiry_detail'] : ''}
                                label ="What can we do to help?"
                                onChange={this.onChange}
                                name = "inquiry_detail"
                            /> */}
                        <Form.TextArea
                            value={data['budgeting_constrains'] ? data['budgeting_constrains'] : ''}
                            label="Budgeting Constraints (if any)"
                            onChange={this.onChange}
                            name="budgeting_constrains"
                        />
                        <Form.TextArea
                            value={data['timeline_information'] ? data['timeline_information'] : ''}
                            label="Timeline information"
                            onChange={this.onChange}
                            name="timeline_information"
                            placeholder='Here are the deadlines I have for my project ...'
                        />
                        <Form.Checkbox
                            label='Plan to publish'
                            onChange={this.onChange}
                            name="plan_to_publish"
                            checked={data['plan_to_publish'] ? true : false}
                        />
                    </Form>
                    <Button disabled={!editPermission} primary floated='right' onClick={this.submit}>Save</Button>
                </Card.Content>
            </Card>
        )
    }
}

export default TabInfo

TabInfo.propTypes = {
    match: PropTypes.object,
    userInfo: PropTypes.object,
}

