import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    Button, Form, Message, Divider
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './StepAccept.css';

//Step 2
export const StepAccept = ({onChange, signature, manageSOW, submission_comment, handleParentModalState, forceModalClose, type, params}) => {
    const [finance, setFinance] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [registeredPta, setRegisteredPta] = useState('')
    const [newPta, setNewPta] = useState('')

    useEffect(() => {
        axios
            .all([
                axios.get(`/api/projects/${params.pid}/details/`, {withCredentials: true}).catch(useNull),
                axios.get(`/api/projects/${params.pid}/irb/`, {withCredentials: true}).catch(useNull),
                axios.get(`/api/projects/${params.pid}/finances/`, { withCredentials: true }).catch(useNull)
            ]).then(axios.spread((details, irb, finance) => {
                if(details && irb){
                    if(details?.data?.has_phi && irb.data?.id === undefined){
                        setDisabled(true)
                    }
                }
                setFinance(finance?.data?.finances)
            }))
        
    }, [params.pid]);


    const triggerCallback = () => {
        if(registeredPta) {
            manageSOW(2, {'registeredPta': registeredPta})
        } else if(newPta) {
            manageSOW(2, {'newPta': newPta})
        }
    }

    const useNull = () => null 
    const goToIRB = () => {
        forceModalClose()
        handleParentModalState('irbModalShow')
    }
    
    const handleModalState = (e, {value, name}) => {
        if(name === 'registeredPta') {
            setRegisteredPta(value)
            setNewPta('')
        } else { //Set plaintext version
            setNewPta(value)
            setRegisteredPta('')
        }
    }

    const options = finance?.map((val) => 
        ({ 
            key: val?.id,
            text: `# ${val?.pta_charge_number}`, 
            value: val?.id, 
    }))

    const acceptanceCriteria = [
        signature.length > 0,
        type === 1 ? true : Boolean(registeredPta || newPta), //If type is RMA, we require a pta number
    ]

    return (
        <div>
            {disabled && 
                <Message warning>
                    <Message.List>
                        <Message.Item>This project was created with PHI enabled and currently does not have an attached IRB</Message.Item>
                        <Message.Item>Note that some functionality on the portal may be restricted</Message.Item>
                        <Message.Item>If you would like to attach an IRB, click the following button 
                            <Button className="warning_button" size='tiny' onClick={goToIRB} key='link'>Go To Edit</Button>
                            or edit the PHI option under Edit Project
                        </Message.Item>
                    </Message.List>
                </Message>
            }
            {/* <p>If you have a corresponding PTA number please enter it here</p> */}
            { type === 2 &&
                <Message info content='This statement of work is a REDCap Maintenence Agreement, therefore requires a PTA'/>
            }
            <Form>
                <div style={{border:'solid 1px', borderColor: 'grey', borderRadius: '10px', backgroundColor: '#F5F5F5', padding: '15px'}}>
                    <Form.Select
                        options={options}
                        name='registeredPta'
                        placeholder='Please choose from the following...'
                        label='Registered PTAs'
                        required={type===2}
                        clearable
                        onChange={handleModalState}
                        disabled={!options.length}
                        value={registeredPta}
                    />
                    <Divider horizontal>Or</Divider>

                    <Form.Field>
                        <Form.Input 
                            onChange={handleModalState} 
                            name = 'newPta' 
                            required={type===2}
                            value = {newPta} 
                            label={'New PTA'}
                        />
                    </Form.Field>
                </div>
                <br/>
                <br/>
                <Form.Field>
                    <Form.Input 
                        onChange={onChange} 
                        name='signature' 
                        value = {signature} 
                        required
                        label={'Please enter your full name here for verification'}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.TextArea 
                        name = 'submission_comment' 
                        onChange={onChange} 
                        value = {submission_comment} 
                        label={'Comment (optional)'}
                    />
                </Form.Field>
                <Button disabled={acceptanceCriteria.indexOf(false) !== -1} onClick={triggerCallback} primary>Submit</Button>    
            </Form>
        </div>
    )
}

StepAccept.propTypes = {
    onChange: PropTypes.func,
    signature: PropTypes.string,
    manageSOW: PropTypes.func,
    submission_comment: PropTypes.string,
    params: PropTypes.object,
    forceModalClose: PropTypes.func,
    handleParentModalState: PropTypes.func

}

