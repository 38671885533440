import React, { useContext, useMemo, useEffect, useState } from 'react';
import { BasicTable } from '../../../components/BasicTable';
import Header from '../../../components/Header';
import { Container, Button, Message, Input, Dropdown } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { truncateDate, dateWithinOffset } from '../../../utils';
import { TableLoader } from '../../../components/TableLoader';
import { NoDataComponent } from '../../../components/NoDataComponent';
import { GlobalContext } from '../../../context';
import './approvedStatement.css';

export default function ApprovedStatement(props) {
    const history = useHistory()
    const context = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [filtered, setFiltered] = useState([])
    const [filterType, setFilterType] = useState('title')

    useEffect(() => {
        // axios.get(`api/projects/sow/charges/process-rma/`,{ withCredentials: true }) 

        axios.get(`api/projects/sow/approved-sow-summary/`, { withCredentials: true })
            .then(res => {
                setData(res?.data)
                setFiltered(res?.data)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.status !== 403)
                    setError(err.message)
                else
                    setError('You do not have permission to view this page')
                setLoading(false)
            })
    }, [])
    
    const getConditionalRowStyles = useMemo(() => [
        {
            when: row => row.number_of_charges === 0 && !dateWithinOffset(row.reviewed_at, 0 , 6, 0), //If date has no charges and time > 6 mo
            style: {
                backgroundColor: 'rgba(255, 247, 99, 0.2)',
                color: 'black',
            }
        },
        {
            when: row => row.number_of_charges === 0 && !dateWithinOffset(row.reviewed_at, 1, 0, 0), //If date has no charges and time > 1 year
            style: {
                backgroundColor: 'rgba(245, 78, 78, 0.2)',
                color: 'black',
            }
        } 
        
    ], [])

    const subHeaderSearch = useMemo(() => {
        const searchTable = (e, arg) => {
            const filterInput = e.target.value.toLowerCase()
            const filterTitle = data.filter(entry => {
                if (filterType === 'id')
                    return String(entry[filterType]).indexOf(String(filterInput)) > -1
                else
                    return entry[filterType]?.toLowerCase().includes(filterInput)
            })
            setFiltered(filterTitle)
        }
        const handleLabelChange = (e, {value}) => {
            setFilterType(value)
        }
        const options = [
            { key: 'ID', text: 'ID', value: 'id' },
            { key: 'Title', text: 'Title', value: 'title' },
            { key: 'Lead Engineer', text: 'Lead Engineer', value: 'owner_name' },
            { key: 'Approved User', text: 'Approved User', value: 'reviewer_signature' },
        ]

        return (
            <div style={{ width: '500px' }}>
                <Input
                    label={<Dropdown defaultValue='title' options={options} onChange={handleLabelChange}/>}
                    // size='small'
                    icon='search'
                    name='linked'
                    onChange={searchTable}
                    placeholder="Search by  ..."
                    fluid
                />
            </div>
        )
    }, [data, filterType])

    const columns = useMemo(() => {
        const navigate = (e, { row }) => { history.push(`/charges/${row.id}`) }
        return [
        {
            name: 'SOW ID',
            selector: row => row.id,
            sortable: true
        },
        {
            name: 'Title',
            selector: row => <a target="_blank" href={"admin/app/sow/" + row.id}>{row.title}</a>,
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Lead Engineer',
            selector: row => row.owner_name,
            sortable: true
        },
        {
            name: 'Approved User',
            selector: row => row.reviewer_signature ?? 'N/A',
            sortable: true
        },
        {
            name: 'Date approved',
            selector: row => truncateDate(row.reviewed_at),
            sortable: true
        },
        {
            name: 'Charge count',
            selector: row => row.number_of_charges,
            sortable: true
        },
        {
            cell: row => <Button compact size='mini' row={row} onClick={navigate} content='Charges' basic color='blue'/>,
            button: true
        }
    ]}, [history])

   

    return (
        <div>
            <Header {...props} />
            <Container fluid style={{ padding: '0 15px 0 15px', height: '800px' }}>
                {context.userInfo?.is_admin && error ? <Message color='red' content={error} /> : ''}

                <BasicTable
                    style={{ height: '800px' }}
                    title='Approved statements of work'
                    columns={columns}
                    data={filtered}
                    dense
                    fixedHeaderScrollHeight='100vh'
                    paginationPerPage={25}
                    progressPending={loading}
                    noDataComponent={<NoDataComponent text='No current SOW, do you have permission to view this content?' />}
                    progressComponent={<TableLoader loadingText='Loading...' active={loading} hg='200px' />}
                    defaultSortFieldId={5}
                    conditionalRowStyles={getConditionalRowStyles}
                    subHeaderComponent={subHeaderSearch}
                />
            </Container>
        </div>
    )
}