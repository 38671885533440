import React from 'react';
import './App.css';
import Home from './views/Home';
import Detail from './views/Detail';
import Landing from './views/Landing';
import ProjectWizard from './views/ProjectWizard';
import Logout from './views/Logout';
import Page from './views/Page';
import Support from './views/Support';
import FAQ from './views/FAQ';
import {ApprovedStatement} from './views/Admin/ApprovedStatement';
import {Charges} from './views/Admin/Charges';
import { GlobalContext } from './context';
import {PrivateRoute} from './components/PrivateRoute';
import {userInfo} from './utils';
import { MaintenanceView } from './views/Maintenance';

import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";


class App extends React.Component {
    static contextType = GlobalContext //Necessary for provider
    constructor(props) {
        super(props);
        this.state = {
            authenticating: true,
            failed: false
        }
    }

    async componentDidMount() {
        //check whether or not user has access to this page and is logged in
        try {
            //if user has cookie / can make a request, django logged in
            const res = await axios.get('/api/users/current-user/', {withCredentials: true});
            if (res.status === 200) { //user successfully verified
                this.context.setAuthentication()
                this.context.updateUserInfo(res.data)
                this.context.setAuthentication() //set persistent auth obj for future pages

                userInfo.update(res.data)
                this.setState({authenticating: false})

            } else {
                this.setState({failed: true})
            }


        } catch (err) {
            this.setState({failed: true})
        }
    }


    render() {
        // If login fails, route them only to landing page
        if (this.state.failed) {
             return (
                <Router>
                    <Switch>
                        <Route path='/faq' render={(props) => <Page {...props}/>}/>
                        <Route path='/' render={(props) => <Landing {...props} />}/>
                    </Switch>
                </Router>
            )
        }
        // If pending, return a blank screen
        if (this.state.authenticating) return null;

        // Else, route regularly.
        return (
            <GlobalContext.Provider value = {this.context}>
                <Router>
                    <Switch>
                        {parseInt(this.context?.userInfo?.maintenance) &&
                            <Route path='/' render={(props) => <MaintenanceView {...props} Auth={this.context}/>}/>
                        }

                        <Route path='/detail/:pid/:tab' render={(props) => <Detail {...props} Auth={this.context} userInfo={userInfo}/>}/>
                        <Route path='/detail/:pid' render={(props) => <Detail {...props} Auth={this.context} userInfo={userInfo}/>}/>
                        <Route path='/create' render={(props) => <ProjectWizard {...props} Auth={this.context} userInfo={userInfo}/>}/>
                        <Route path='/support' render={(props) => <Support {...props} Auth={this.context} userInfo={userInfo}/>}/>
                        <Route path='/home' render={(props) => <Home {...props}/>}/>

                        <Route path='/faq' render={(props) => <FAQ {...props}/>}/>
                        <Route path='/logout' render={(props) => <Logout {...props}/>}/>
                        <PrivateRoute path='/approved' render={(props) => <ApprovedStatement {...props}/>}/>
                        <PrivateRoute path='/charges/:sow_id' render={(props) => <Charges {...props}/>}/>
                        {/* <PrivateRoute path='/invoices' render={(props) => <Charges {...props}/>}/> */}
                        {/* <PrivateRoute path='/invoices/invoice_id' render={(props) => <Charges {...props}/>}/> */}
                        {/* <PrivateRoute path='/charges/' render={(props) => <Charges {...props}/>}/> */}
                        <Route path='/' render={(props) => <Landing {...props} Auth={this.context}/>}/>

                    </Switch>
                </Router>
            </GlobalContext.Provider>
        );
    }
}


export default App;
