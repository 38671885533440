import React, { Component } from 'react';
import memoize from 'memoize-one';
import {
    Segment, Loader, Button, Icon,
    Card, Dimmer, Message, Popup
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { truncateDate, getCookieValue } from '../../../utils';
import DataTable from 'react-data-table-component';
import { ConsultationDetailModal } from './ConsultationDetailModal';
import { TemplateNotification } from '../../TemplateNotification';
import { RequestConsultationModal } from './RequestConsultationModal';
import qs from 'qs';
import { NoDataComponent } from '../../NoDataComponent';
import { TableLoader } from '../../TableLoader';
import { GlobalContext } from '../../../context';

class TabConsult extends Component {
    static contextType = GlobalContext
    constructor(props) {
        super(props)
        const { search } = this.props.location
        const params = new URLSearchParams(search)

        this.state = {
            callbackSurveyId: params.get('svh') || null,
            loading: true,
            tableData: [],
            error: '',
            open: false, //modal
            selected: '',
            addConsultationModalOpen: false,
            consultationDetailId: params.get('id') || null,
            users: []

        }
    }

    componentDidMount() {
        var { pid } = this.props.match.params
        const { callbackSurveyId } = this.state
        if (callbackSurveyId) { // User is being redirected from REDCap, render record workflow
            this.generateConsultationRecords()
        } else {
            axios.all([
                axios.get(`/api/projects/${pid}/consultation/`).catch(this.useNull),
                axios.get(`/api/projects/${pid}/users/`, { withCredentials: true }).catch(this.useNull),
            ]).then(axios.spread((consultationList, users) => {
                if (!consultationList) {
                    this.setState({
                        users: users,
                        loading: false,
                        error: `Cannot access consultations, this is likely a permission issue`
                    })
                } else {
                    this.setData(consultationList.data, users)
                }
            }))
        }

    }

    useNull = () => null

    setData = (data, users) => {
        if (data) {
            const filter = data.filter(el => el?.jira?.fields?.status?.name !== "Error")
            const deleted = data.filter(el => el?.jira?.fields?.status?.name === "Error")
            let err;

            var fillTable = []
            for (var i = 0; i < filter.length; i++) {
                fillTable.push(this.createTableRow(filter[i]))
            }

            if (deleted.length > 0) {
                let ticket_ids = deleted.map(el => el?.jira_ticket_id)
                err = ticket_ids.join(', ')
            }

            if (this.state.consultationDetailId) {
                let selected = this.findSelectedRow(this.state.consultationDetailId, fillTable)
                this.setState({
                    users: users?.data?.results,
                    tableData: fillTable,
                    loading: false,
                    open: true,
                    selected: selected,
                    error: err ? `ADMIN: The following consultations have been deleted from this project and not shown: ${err}` : ''
                })
            } else {
                this.setState({
                    users: users?.data?.results,
                    tableData: fillTable,
                    loading: false,
                    error: err ? `ADMIN: The following consultations have been deleted from this project and not shown: ${err}` : ''
                })
            }


        } else {
            this.setState({ noData: 'Error fetching consultation requests, please contact your administrator', loading: false })
        }
    }

    /**
     * Returns full data object of a given row ID
     * @param {Integer} row_id 
     * @returns Object
     */
    findSelectedRow = (row_id, data = null) => {
        if (!data)
            data = this.state.data
        return data.find((el) => el.id === parseInt(row_id))
    }

    createTableRow = (consultation) => {
        if (consultation) {
            return {
                id: consultation.id,
                summary: 'Consultation Request',
                status: consultation?.jira?.fields?.status?.name,
                description_no_html: consultation?.jira?.fields?.description_no_html,
                description: consultation?.jira?.fields?.description,
                date: truncateDate(consultation.created_at),
                user: consultation.user ? consultation.user.first_name + ' ' + consultation.user.last_name : 'N/A',
                full: consultation,
                jira_ticket_id_url: "https://stanfordmed.atlassian.net/browse/" + consultation.jira_ticket_id,
                jira_ticket_id: consultation.jira_ticket_id
            }
        } else {
            return {}
        }
    }

    //Callback that triggers when user creates a new consultation request
    consultationSubmitCallback = (res) => {
        let row = this.createTableRow(res)
        this.setState({ tableData: [...this.state.tableData, row], addConsultationModalOpen: false }) //and tableData})
    }

    onConsultationModalChange = () => {
        this.state.addConsultationModalOpen ? this.setState({ addConsultationModalOpen: false }) : this.setState({ addConsultationModalOpen: true })
    }

    updateSelectedCallback = (res) => {
        let { tableData } = this.state
        let index = tableData.findIndex(el => el.id === res.id) //find and update state of full table obj
        tableData[index].full = res
        this.setState({ tableData: tableData })
    }

    updateSelectedParticipantCallback = (res, id) => {
        let { tableData } = this.state
        let index = tableData.findIndex(el => el.id === id) //find and update state of full table obj
        tableData[index].full.jira.fields.customfield_10028 = res?.data?.values
        this.setState({tableData: tableData})
    }

    //render modal open for details on consultation click
    onSelect = (e, { row }) => {
        const { tableData } = this.state
        const selected = tableData.find(obj => obj.id === row.id)
        this.setState({ open: true, selected: selected })
    }

    //Close modal handler for detail 
    onClose = () => { this.setState({ open: false, selected: '' }) }

    generateConsultationRecords = () => {
        const { callbackSurveyId } = this.state
        const cookieValue = getCookieValue('csrftoken');
        const survey_url = `https://redcap.stanford.edu/surveys/?s=${callbackSurveyId}`

        axios.post(`/api/surveys/finalize/`,
            qs.stringify({ 'url': survey_url }), //have to stringify in order for it to be passed via "BODY" on django, cant pass as data
            {
                headers: { "X-CSRFToken": cookieValue },
            })
            .then(() => {
                window.location.replace(window.location.origin + this.props.location.pathname) //refresh the page. replace history
            })
            .catch(err => this.setState({ error: `Error finalizing consultation records : ${err?.message}`, callbackSurveyId: null, loading: false }))

    }

    getSubheader = memoize(() => this.renderAddButton())

    getColumns = memoize(() => [
        {
            name: 'Description',
            selector: row => row.description_no_html,
            sortable: true,
            maxWidth: '400px',
            grow: 2,
        },
        {
            name: 'Date Issued',
            selector: row => row.date,
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            maxWidth: '100px'
        },
        {
            name: 'Submitted by',
            selector: row => row.user,
            sortable: true,
        },
        {
            name: 'Jira URL',
            selector: row=>row.jira_ticket_id,
            sortable: true,
            cell: row => <a target='_blank' rel="noopener noreferrer" href={row.jira_ticket_id_url}> {row.jira_ticket_id} </a>,
            omit: !this?.props?.userInfo?.data?.is_admin
        },
        {
            cell: row => <Button row={row} size='tiny' content='Details' onClick={this.onSelect} />,
            width: '151px'
        }
    ])

    renderPopup = () => {
        return (
            <Popup
                content='Please submit information regarding your research project on the Details tab before requesting a consultation'
                position='left center'
                trigger={
                    <Button
                        floated='right'
                        size='small'
                        icon
                        color='red'
                        basic
                        labelPosition='left'
                    >
                        <Icon name='add circle' /> New Request
                    </Button>

                }
            />
        )
    }

    renderAddButton = () => {
        return (
            <div>
                <Button
                    floated='right'
                    icon
                    labelPosition='left'
                    primary
                    size='small'
                    value='add'
                    onClick={this.onConsultationModalChange}
                >
                    <Icon name='add circle' /> New Request
                </Button>
            </div>

        )
    }


    render() {
        const { loading, tableData, error, selected, open, callbackSurveyId, users } = this.state

        if (callbackSurveyId) { // User is being redirected from REDCap, render record workflow
            return (
                <Segment attached='bottom'>
                    <Card fluid style={{ height: '150px' }}>
                        <Card.Content>
                            <Dimmer active={loading} inverted>
                                <Loader inverted>Loading ... The page will refresh after after completion</Loader>
                            </Dimmer>
                        </Card.Content>
                    </Card>
                </Segment>
            )
        } else { //resume normal functionality 
            return (
                <Segment attached='bottom'>
                    <TemplateNotification
                        type='consult_tab'
                    />
                    {this.context.userInfo?.is_admin && error ? <Message color='red' content={error} /> : ''}

                    <DataTable
                        title='Consultation Requests'
                        columns={this.getColumns()}
                        data={tableData}
                        Clicked
                        fixedHeader={true}
                        fixedHeaderScrollHeight="300px"
                        subHeaderComponent={this.getSubheader()}
                        subHeader={true}
                        noDataComponent={<NoDataComponent text='There are no currently submitted consultations. To schedule a meeting with RIT, please click new request' />}
                        progressComponent={<TableLoader loadingText='Loading...' active={loading} />}
                        progressPending={loading}
                        pagination
                    />

                    {selected &&
                        <ConsultationDetailModal
                            open={open}
                            onClose={this.onClose}
                            selected={selected}
                            updateSelectedCallback={this.updateSelectedParticipantCallback}
                            users={users}
                            {...this.props}
                        />
                    }
                    <RequestConsultationModal
                        open={this.state.addConsultationModalOpen}
                        name='modalShow'
                        onClose={this.onConsultationModalChange}
                        projectDetail={this.props.projectDetail}
                        consultationSubmitCallback={this.consultationSubmitCallback}
                        {...this.props}
                    />
                </Segment>
            )
        }

    }
}

export default TabConsult

TabConsult.propTypes = {
    match: PropTypes.object,
    userInfo: PropTypes.object,
    projectDetail: PropTypes.object,
    location: PropTypes.object
}

