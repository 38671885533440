import React, { Component } from 'react';
import {Modal, Form, Message, Button} from 'semantic-ui-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {SearchBar} from '../../../SearchBar';
// import {_} from 'underscore';
import * as _ from 'underscore'
import qs from 'qs';
import {getCookieValue} from '../../../../utils';
// import DataTable from 'react-data-table-component';

export class UserModal extends Component{
    constructor(props){
        super(props)
        this.state = {
            selected_group: '',
            selected_user : {},
            error: '',
            loading: false

        }
    }

    filterGroups = (groups) => {
        let filtered = groups.filter(item=> item.name.includes('Project'))

        try {
            return filtered.map((val) => (
                {
                    key: val.id,
                    value: val.id,
                    text: val.name,
                    permissions: val.permissions
                }
            ))
        } catch(err) { //groups call returned an empty response 
            console.log(err)
            return []
        }
    }

    //Set selected_group ID upon dropdown select
    onGroupSelect = (e, data) => { this.setState({selected_group:data.value}) }

    //Given user object returned from ElasticSerch, add it to state
    onUserSelect = (user) => {
        this.setState({selected_user: user})
    }

    //Add user to a project given form completion 
    addUser = () => {
        const {selected_group, selected_user} = this.state
        const {pid} = this.props.match.params
        const cookieValue = getCookieValue('csrftoken');
        
        if(selected_user.full.suid){ //Can only add / create user if sunet ID is public
            this.setState({loading: true})
            axios({ //this is only accessible for admins
                method: 'post',
                url: `/api/projects/${pid}/add-user/`,
                data: qs.stringify({
                    username: selected_user.full.suid,
                    group_id: selected_group,
                    user_job: 'coordinator',
                    email: selected_user.full.email,
                    first_name: selected_user.full.first_name,
                    last_name: selected_user.full.last_name
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": cookieValue,
                }
                }).then((res)=> { 
                    this.setState({loading: false})
                    this.props.addUserCallback(res.data)
                    
                    //Send Notifications here, email for user creation
                })
                .catch((err)=>{
                    
                    this.setState({error: err.response.data.message, loading: false})
                })
        } else {
            this.setState({error: 'Could not find associated SUNET ID for given user, ensure they have one and that their profile is public'})
        }

        


    }

    //Handler for modal close
    handleClose = (e, {value}) => {
        this.setState({selected_group: '', selected_user:[]}) //Reset field values
        this.props.displayModal(e, {value}) //Call regular handler in parent 
    }

    render(){
        const {add_open, groups} = this.props
        const {selected_group, loading, selected_user, error} = this.state
        var disabled = true
        
        if(selected_group && !(_.isEmpty(selected_user)))
            disabled = false
        else
            disabled = true
        
        return (
            <Modal style={{top:'300px'}} size='small' closeIcon open={add_open} value = {'add_open'} onClose={this.handleClose} >
            <Modal.Header>Add a new user</Modal.Header>
                <Modal.Content>
                {error ? <Message negative size='tiny'>{error}</Message>: '' }
                    <p>Please select a user and role permission </p>
                    <SearchBar callback = {this.onUserSelect}/>
                    <br></br>
                    <Form>
                        <Form.Dropdown value ={selected_group}
                            width={6} 
                            label={"User Role (Permissions)"} 
                            selection 
                            onChange = {this.onGroupSelect}
                            options={this.filterGroups(groups)}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        disabled={disabled} 
                        onClick = {!loading ? this.addUser : undefined}
                        loading={loading} 
                        primary
                        content='Create'
                    />
                </Modal.Actions>
            </Modal>
        )
    }

}

UserModal.propTypes = {
    add_open: PropTypes.bool,
    groups: PropTypes.array,
    displayModal : PropTypes.func,
    addUserCallback : PropTypes.func
}

// export class ImportModal extends Component{
//     constructor(props){
//         super(props)
//         this.state = {
//             tableData: [],
//         }
//     }

//     componentDidMount(){
     

//     }

//     //Parse redcap data and pull all users into a DataTable list
//     filterRedcapTable = (linkedRedcap, allRedcap) => {
//         const username = Object.keys(allRedcap.data)[0]
//         let displayUsers = {}
//         let tableData = []

//         if(username !== "error"){ 
//             var parsedRedcap = allRedcap.data[username]
//             for(let i=0 ; i < linkedRedcap.data.length ; i++){ //iterate through all previously linked projects
//                 for(let j = 0 ; j < parsedRedcap.length ; j++){ //iterate through all total external redcap projects
//                     if(parseInt(parsedRedcap[j].project_id) === linkedRedcap.data[i].redcap_id){ //if linked, iterate through users
//                         for(let k = 0 ; k < parsedRedcap[j].users.length ; k ++ ){
//                             if(!(parsedRedcap[j].users[k].username in displayUsers)) //if username hasn't been stored
//                                 displayUsers[parsedRedcap[j].users[k].username] = parsedRedcap[j].users[k] //store the whole object under username
//                         }
//                     }
//                 }
//             }
            
//             for(let key in displayUsers) //format correctly for dataTable component
//                 tableData.push(displayUsers[key])
            
//             this.setState({tableData: tableData})
            
//             // this.setState({redcap_projects:parsedRedcap, loading:false, hidden: false})
//         } else {//IP Address whitelist response, doesn't hit catch statement
//             // this.setState({error: allRedcap.data[username] ,loading:false, hidden: false})
//             console.log('err')
//         }
//     }

//     //Called upon checkbox change
//     onSwitch = (e, arg) => {
//         console.log(e, arg)
//     }

//     //Necessary to pass forward ref to DataTable to use non-default checkbox
//     renderCheckbox = () => {
       
          
//         // return FancySlider
//     }        

//     render(){
//         const {import_open} = this.props
//         const columns = [
//             {
//                 name: 'First Name',
//                 selector: 'user_firstname',
//                 sortable: true,
//                 wrap: true,
//                 maxWidth: '800px'
//             },
//             {
//                 name: 'Last Name',
//                 selector: 'user_lastname',
//                 sortable: true,
//             },
//             {
//                 name: 'Username',
//                 selector: 'username',
//                 sortable: true,
//             },
//             {
//                 name: 'Email',
//                 selector: 'user_email',
//                 sortable: true,
//             },
//         ]
//         const FancySlider = React.forwardRef(({ onClick, ...rest }, ref) => (
//             <Checkbox style={{"marginLeft":"5px"}} onClick = {this.onSwitch} toggle ref={ref}></Checkbox>
//         ));
//         return (
//             <Modal size='large' closeIcon open={import_open} value = {'import_open'} onClose={this.props.displayModal} >
//                 <Modal.Header>Import users from Redcap</Modal.Header>
//                 <Modal.Content>
//                     <DataTable
//                         title="Select which users you'd like to add to the project" 
//                         columns={columns}
//                         data={this.state.tableData}
//                         Clicked
//                         onRowClicked={this.displayDetailModal}
//                         fixedHeader={true}
//                         fixedHeaderScrollHeight="300px"
//                         highlightOnHover
//                         selectableRows={true}
//                         selectableRowsHighlight = {true}
//                         selectableRowsComponent = {FancySlider}
//                         selectableRowsNoSelectAll = {true}
//                     />
//                 </Modal.Content>
//                 <Modal.Actions>
//                     <Button onClick = {this.addTicket} primary>Add Selected</Button>
//                 </Modal.Actions>
//             </Modal>
//         )
//     }   
// }

// ImportModal.propTypes = {
//     import_open: PropTypes.bool,
//     displayModal : PropTypes.func,
//     importUserCallback: PropTypes.func,
//     history: PropTypes.object

// }