/*
  Utility file for common javascript functionality
  Also includes objects that require persistance through routing.
*/


//Check if env is dev or prod
import {Label, Popup} from "semantic-ui-react";
import React from "react";

export const isDevelopment = () => {
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        return true;
    else
        return false;
}

//Parses cookie given by Django and returns its value
export const getCookieValue = (name) => {
  var cookies = document.cookie.split(';');
  var cookieValue = null;
  
  for(let i = 0 ; i < cookies.length; i++){
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
      }
  }
  return cookieValue
}

export const userInfo = {
  data: {}, 

  update(res){
    this.data = res
  }
}

/** Notification render_locations:
  ('1', 'Header'),
	('2', 'Project Summary'),
	('3', 'Tab'),
 */
//Persistent Notification object to prevent excessive calls to /api/notifications. Should only update when clicking dropdown
export const persistantNotifications = {
  data: {},
  
  update(payload){
    this.data = payload
  }
}

//Export unix timestamp as readable format
export const truncateDate = (date) => {
  if(date){
      const newDate = new Date(date) // formated_Date - SDK returned date
      return (`${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`)
  }
}

export const dateWithinOffset = (date, yearOffset=0, monthOffset=0, dayOffset=0) => {
    let currentDate = new Date()
    let compareDate = new Date(date) // formated_Date - SDK returned date

    if (yearOffset)
      compareDate.setFullYear(compareDate.getFullYear() + yearOffset)
    if (monthOffset)
      compareDate.setMonth(compareDate.getMonth() + monthOffset)
    if (dayOffset)
      compareDate.setDate(compareDate.getDate() + dayOffset)
    return currentDate - compareDate > 0 ? false : true
}

export const usFormatDate = (date) => {
  if(date){
      let truncateDate = date.split(/[-]+/)
        return truncateDate[1] + "-" + truncateDate[2] + "-" + truncateDate[0]
  }
}

//Truncates string of a given length with ellipses
export const truncateStringEllipses = (text, length) => {
  if(length && text){
    if(text.length > length){
      let truncate = text.substring(0, length)
      return (truncate + ' ...')
    } else {
      return text
    }
  }
}


export const parseError = (err) => {
  var error = ''
  for(var key of Object.keys(err.response.data))
      error+= err.response.data[key]                        
  return error
}


// Used to display helper information
export const HelpLabel = ({label, help}) => (
    <>
        <label style={{display: 'inline'}}><strong>{label}</strong></label>
        <Popup
            content={help}
            position='right center'
            trigger={(
                <Label circular basic size='mini' style={{position:'relative', top:'-5px', left:'3px'}}>
                    ?
                </Label>
            )}
        />
    </>
)


