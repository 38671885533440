import React from 'react';
import TabRedcap from './TabRedcap'
import TabUsers from './TabUsers'
import TabTickets from './TabTickets'
import TabStatement from './TabStatement'
import TabFinance from './TabFinance'
import TabDefault from './TabDefault'
import TabConsult from './TabConsult'
import TabEreg from './TabEreg'
import PropTypes from 'prop-types'


//Any props not explicitly specified here will be included under the ...props designation
const DetailContent = ({type, infoUpdateRedirect, handleParentModalState, projectDetail,  ...props}) => {
    switch(type){
        case 'default':
            return <TabDefault {...props}/>
        case 'users':
            return <TabUsers updateOwnerCallback= {props.updateOwnerCallback} {...props}/>
        case 'e_reg':
            return <TabEreg {...props}/>
        case 'finance':
            return <TabFinance {...props}/>
        case 'sow':
            return <TabStatement handleParentModalState = {handleParentModalState} {...props}/>
        case 'redcap':
            return <TabRedcap {...props}/>
        case 'tickets':
            return <TabTickets {...props}/>
        case 'consultation':
            return <TabConsult projectDetail={projectDetail} {...props}/>
        default:
            return <TabDefault {...props}/>
    }
}

DetailContent.propTypes = {
    type: PropTypes.string,
    infoUpdateRedirect: PropTypes.func,
    props: PropTypes.object, //contains all the auth, history props
    handleParentModalState: PropTypes.func,
    projectDetail: PropTypes.object,
    updateOwnerCallback: PropTypes.func
}

export default DetailContent;
