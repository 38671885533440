import React, { Component } from 'react';
import {
    Message, Button, Form, Label, Divider, List, Segment, Radio
} from 'semantic-ui-react';
import axios from 'axios';
import { getCookieValue, HelpLabel } from '../../../../utils';
import qs from 'qs';
import PropTypes from 'prop-types'


//Step 4 Create project
export class WizardCreateProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project_name: '',
            project_description: '',
            error: '',
            radio_value: '',
            disabled: false
        }
    }

    componentDidMount() {
        this.props.updateProgress(90)
    }

    onChange = (e, arg) => {
        this.setState({ [arg.name]: arg.value })
    }

    onRadioChange = (e, { value }) => this.setState({ radio_value: value })

    createProject = (e) => {
        this.setState({ disabled: true })
        const cookieValue = getCookieValue('csrftoken');
        const { irb } = this.props.form
        const conditions = [
            this.state.project_name.length > 0,
            this.state.project_description.length > 0,
            this.state.radio_value !== ''
        ]

        if (conditions.indexOf(false) === -1) { //ensure fields arent null
            //have to use base axios instead of axios.post : in order to accept urlencoded body
            axios({
                method: 'post',
                url: '/api/projects/',
                data: qs.stringify({
                    project_name: this.state.project_name,
                    project_description: this.state.project_description,
                    project_type: this.state.radio_value,
                    irb_number: (irb?.protocol_number === -1 || !irb?.protocol_number) ? "" : parseInt(this.props.form.irb.protocol_number)
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": cookieValue,
                }
            }).then((res) => { //navigate to detail page of recently created project
                this.props.history.replace(`/detail/${res.data.id}`, { from: "Create Project" });
            })
                .catch((err) => {
                    this.setState({ error: 'Error creating project, please contact your administrator' })
                });

            e.preventDefault();
        } else {
            this.setState({ error: 'One of the following fields is blank, please fill in the corresponding information', disabled: false })
        }
    }

    renderPersistantIRB = () => {
        return (
            <>
                <Divider />
                <strong>IRB Information</strong>
                {/* <Divider horizontal>IRB information</Divider> */}
                <Segment>
                    <List divided verticalAlign='middle'>
                        <List.Item>
                            <Label icon='hashtag' content='IRB number' horizontal />
                            <strong>{this.props.form.irb?.protocol_number}</strong>
                        </List.Item>
                        <List.Item>
                            <Label icon='folder outline' content='Protocol Title' horizontal />
                            <strong>{this.props.form.irb?.protocol_title}</strong>
                        </List.Item>
                        <List.Item>
                            <Label icon='building outline' content='Department' horizontal />
                            <strong>{this.props.form.irb?.protocol_department}</strong>
                        </List.Item>
                    </List>
                    <Message
                        size='tiny'
                        content={<Message.Item><i>Completing this form will create a new project under the above IRB</i></Message.Item>}
                    />
                </Segment>
                <Divider hidden />
            </>
        )
    }

    render() {
        const { error, disabled } = this.state
        const options = [
            { text: 'Research Project', value: 1 },
            { text: 'Quality Improvement', value: 2 },
            { text: 'Other', value: 3 },
        ]

        const radios = options.map(e =>
            <Form.Field key={e.text}>
                <Radio
                    label={e.text}
                    name='radioGroup'
                    value={e.value}
                    checked={this.state.radio_value === e.value}
                    onChange={this.onRadioChange}
                />
            </Form.Field>
        )


        return (
            <div>
                <Form className='attached fluid'>
                    <h3>Tell us about your project</h3>
                    <p>Please keep in mind your project name and description should <strong>not</strong> reference any particular question, but should instead describe the research project itself</p>
                    <Divider />
                    {error ? <Message negative>{error}</Message> : ''}
                    <HelpLabel
                        help="A short name or phrase that describes the project"
                        label="Project Name"

                    />
                    <Form.Input
                        icon='folder'
                        iconPosition='left'
                        onChange={this.onChange}
                        name='project_name'
                        placeholder='e.g. SnapDX Centrifugal handifuge'
                        maxLength={64}
                    />

                    <HelpLabel
                        help="A short description of your project or study at a high level, similar to a journal abstract.  TDS management would like to understand what the overall objective is for the work that you and your team are doing. "
                        label="Project Description"
                    />
                    <Form.TextArea
                        name='project_description'
                        onChange={this.onChange}
                        placeholder={
                            `This project description should resemble a basic abstract with 2-4 sentences describing the goals and purpose of the project.\nYou will be able to ask a specific questions regarding your project after creation.`
                        }
                    />
                    <h5><strong>Project Type</strong></h5>
                    {radios}
                    {this.props.form.irb ? this.renderPersistantIRB() : ''}
                </Form>
                <br />
                <Button
                    floated='left'
                    color='red'
                    onClick={() => this.props.goToStep(3)}
                    content='Back'
                    icon='left arrow'
                    labelPosition='left'
                />

                <Button
                    floated='right'
                    primary
                    onClick={this.createProject}
                    content='Create'
                    icon='plus'
                    labelPosition='right'
                    disabled={disabled}
                />

            </div>
        );
    }
}

WizardCreateProject.propTypes = {
    goToStep: PropTypes.func,
    form: PropTypes.object,
    updateProgress: PropTypes.func
}