import React, { useState } from 'react';
import {
    Button, Divider, Label, List, Segment, Dimmer, Loader,
    Modal, Form, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { getCookieValue, truncateDate } from '../../../utils';

export const IRBModal = ({ open, irb, onClose, name, callback, ...props }) => {
    const [newIrb, setIrb] = useState('')
    const [selectedIrb, setSelectedIrb] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const { pid } = props.match.params
    const [dimmer, setDimmer] = useState(false)

    const onChange = (e) => setIrb(e.target.value)

    const handleClose = (name) => {
        onClose(name)
        setSelectedIrb('')
        setError('')
    }

    const submit = () => {
        if (!selectedIrb?.protocol_number) {
            setMessage({ text: 'Protocol number not registered correctly, please contact administrator', type: 'error' })
        } else {
            setDimmer(true)
            axios({
                method: 'POST',
                url: `/api/projects/${pid}/irb-add/`,
                data: qs.stringify({
                    irb_number: selectedIrb?.protocol_number
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": getCookieValue('csrftoken'),
                }
            }).then(res => {
                setDimmer(false)
                callback(res)
                handleClose(name)
            }).catch(err => {
                setDimmer(false)
                setMessage({ text: 'No matching IRB number found in database, please contact an administrator', type: 'error' })
            })
        }
    }

    const deleteIrb = () => {
        axios({
            method: 'DELETE',
            url: `/api/projects/${pid}/irb-disconnect/`,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": getCookieValue('csrftoken'),
            }
        }).then(res => {
            handleClose(name)
            callback(res)
        }).catch(err => {
            setMessage({ text: 'No matching IRB number found in database, please contact an administrator', type: 'error' })
        })
    }

    const renderMessage = () => {
        return message.type === 'error' ? <Message header='Error' content={message.text} color='red' /> : <Message header='Success' content={message.text} color='green' />
    }

    const displayIrbInfo = () => {
        return (
            <Segment>
                <Message
                    positive
                    size='tiny'
                    header={`IRB #${selectedIrb.protocol_number} Match found`}
                />
                <Divider />
                <List>
                    <List.Item>
                        <Label icon='folder outline' content='Protocol Title' horizontal />
                        <strong>{selectedIrb.protocol_title}</strong>
                    </List.Item>
                    <List.Item>
                        <Label icon='building outline' content='Department' horizontal />
                        <strong>{selectedIrb.protocol_department}</strong>
                    </List.Item>
                    <List.Item>
                        <Label icon='clock' content='Creation Date' horizontal />
                        <strong>{truncateDate(selectedIrb.created_at)}</strong>
                    </List.Item>
                </List>
            </Segment>
        )
    }

    const renderIRBSearch = () => {
        return (
            <>
                <Message info>
                    <Message.List>
                        <Message.Header>Notice</Message.Header>
                        <Message.Item>Enter your IRB number below to determine whether we have access to its protocol information</Message.Item>
                        <Message.Item>Please note that only fully approved IRBs are stored within Stanford's IRB database </Message.Item>
                        <Message.Item>If your IRB does not exist or has only recently been submitted, please contact an administrator</Message.Item>
                    </Message.List>
                </Message>
                {error &&
                    <Message
                        size='tiny'
                        onDismiss={() => setError('')}
                        negative
                        header={error}
                    />
                }

                <Divider></Divider>
                <Form>
                    <Form.Field width='8'>
                        <Form.Input
                            onChange={onChange}
                            placeholder='e.g. 54321'
                            action={{
                                onClick: (e) => search(e),
                                icon: 'search'
                            }}
                        />
                    </Form.Field>
                </Form>
                {selectedIrb && displayIrbInfo()}
            </>
        )
    }

    const search = () => {
        const isCorrectFormat = /^\d+$/.test(newIrb)

        if (isCorrectFormat) { //check whether or not the formatting for irb is adequate
            const cookieValue = getCookieValue('csrftoken');
            const params = new URLSearchParams(); //need to serialize parameters, different than other requests
            params.append('number', newIrb);
            setDimmer(true)
            axios.post('/api/irb/search/', params, {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": cookieValue,
                    "content-type": "application/x-www-form-urlencoded"
                },
            })
                .then((res) => {
                    setSelectedIrb(res.data)
                    setError('')
                    setDimmer(false)
                })
                .catch(() => {
                    setError(`IRB ${newIrb} does not match any entry in our database`)
                    setSelectedIrb('')
                    setDimmer(false)
                })
        } else {
            setError('Incorrect IRB format, please ensure the IRB # is entered correctly with no spaces or extra characters')
            setSelectedIrb('')
        }
    }

    if (irb) {
        return (
            <Modal size='large' closeIcon onClose={() => handleClose(name)} open={open}>
                <Modal.Header>Delete IRB</Modal.Header>
                <Modal.Content>
                    {message && renderMessage()}
                    <Form>
                        <Form.Field>
                            <Form.Input
                                name='protocol_number'
                                label='Protocol Number'
                                value={irb.protocol_number}
                                onChange={onChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Form.Field>
                        <Button
                            onClick={deleteIrb}
                            color='red'
                            content="Delete"
                        />
                    </Form.Field>
                </Modal.Actions>
            </Modal>
        )
    } else {
        return (
            <Modal size='large' closeIcon onClose={() => handleClose(name)} open={open}>
                <Dimmer active={dimmer} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Modal.Header>Add IRB to current project</Modal.Header>
                <Modal.Content>
                    {renderIRBSearch()}
                </Modal.Content>
                <Modal.Actions>
                    <Form.Field>
                        <Button
                            disabled={selectedIrb ? false : true}
                            onClick={submit}
                            primary
                            content="Add"
                        />
                    </Form.Field>
                </Modal.Actions>
            </Modal>
        )
    }
}

IRBModal.propTypes = {
    open: PropTypes.bool,
    irb: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onClose: PropTypes.func,
    name: PropTypes.string,
    callback: PropTypes.func

}