import React, { Component } from 'react';
import {
    Message, List, Button, Segment, Divider, Label
} from 'semantic-ui-react';
import axios from 'axios';
import { getCookieValue, parseError, truncateStringEllipses } from '../../../../utils';
import { SearchBar } from '../../../../components/SearchBar'
import PropTypes from 'prop-types'


const style = {
    content: {
        maxHeight: "calc(69vh - 10em)",
        overflowY: "scroll",
        overflowX: "hidden",
        backgroundColor: "white",
        border: '1px solid rgb(192,192,192)',
        borderRadius: '7px',
        padding: '10px'
    }
}

//Step 3 - search user 
export class WizardUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            projectList: []
        }
    }

    componentDidMount() {
        //On page refresh / new project creation, set IRB to null
        this.props.updateProgress(50)
    }

    /**
     * Searches projects by username, updates projectList upon completion
     * @param {Object} data - customData field returned by searchBar component, contains user data
     */
    handleUserSelection = (data) => {
        let suid = data.value
        if (suid) {
            axios.get(`/api/users/${suid}/projects/`)
                .then(res => {
                    res.data.length ? this.setState({ projectList: res.data, error: '' }) : this.setState({ error: 'User does not have any projects', projectList: [] })
                })
                .catch(err => this.setState({ error: 'User does not have any projects', projectList: [] }))
        } else {
            this.setState({ error: 'No SUID Present for this user' })
        }
    }

    addExistingProject = ({ target }) => {
        if (target.id) {
            const cookieValue = getCookieValue('csrftoken');
            axios({
                method: 'post',
                url: `/api/projects/${target.id}/request-access/`,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    "X-CSRFToken": cookieValue,
                }
            }).then((res) => {
                this.props.history.replace(`/home`)
            }) //redirect after permission granted
                .catch((err) => {
                    this.setState({ error: parseError(err) })
                });
        }
    }

    renderProjects = (projectList) => {
        projectList.sort((a, b) => (a.project_name).toLowerCase().trim() > (b.project_name).toLowerCase().trim() ? 1 : -1)
        const projectMap = projectList.map((val) => (
            <List.Item key={val.id}>
                <List.Content floated='right'>
                    <Button id={val.id} onClick={this.addExistingProject}>Add</Button>
                </List.Content>
                <List.Content>
                    <List.Header>{val.project_name}</List.Header>
                    <List.Description>{truncateStringEllipses(val.project_description, 512)}</List.Description>
                </List.Content>
            </List.Item>
        ))

        return (
            <Segment placeholder >
                <Message
                    info
                    content={
                        <div style={{color:'black'}}>
                            <p>The following list of R2P2 Projects were found:</p>
                            <p>If one of these projects matches your project, please click <Label as='span' size='tiny' content='Add' /> to acquire access.</p>
                            <p>If none of these projects match, click  <Label as='span' color='blue' size='tiny' content='Skip' />  to continue project registration.</p>
                            {/* Clicking <Label size='tiny' content='Add' /> will link this user's project under your home page and notify them of your accessibility */}
                        </div>
                    }
                />
                <div style={style.content}>
                    <List divided relaxed='very'>
                        {projectMap}
                    </List>
                </div>
            </Segment>
        )

    }

    render() {
        const { error, projectList } = this.state

        return (
            <>
            
                {/* <Message>
                    <Message.List>
                        <Message.Item>
                            In an effort to prevent duplicate projects in our system we
                            strongly recommend you search existing projects by user
                        </Message.Item>
                        <Message.Item>In the search bar below please enter a name or SUID to begin linking a project</Message.Item>
                        <Message.Item>
                            If you are certain your project does not already exist, please click skip to create a brand new project.
                        </Message.Item>
                    </Message.List>
                </Message> */}
                <h3>Has someone else already registered your project here?</h3>
                <p>To prevent project duplication, please search by name a collaborator that may have already registered this project</p>
                <p>Or if you're certain your project has not been registered yet, you may skip this step</p>
                <Divider></Divider>
                <p><strong>Please enter the name of your project's PI or administrator to search or press skip:</strong></p>
                {error ? <Message negative size='tiny'>{error}</Message> : ''}

                <SearchBar callback={this.handleUserSelection} />

                {projectList.length > 0 ? this.renderProjects(projectList) : ''}

                <br />
                <div>
                    <Button
                        floated='right'
                        labelPosition='right'
                        icon='arrow right'
                        primary
                        content='Skip'
                        onClick={this.props.nextStep}
                    />
                    <Button
                        floated='left'
                        labelPosition='left'
                        icon='arrow left'
                        color='red'
                        content='Back'
                        onClick={() => this.props.goToStep(1)}
                    />

                </div>
            </>
        )
    }
}

WizardUser.propTypes = {
    goToStep: PropTypes.func,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func
}
