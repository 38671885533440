import React, {Component} from 'react';
import './Page.css';
import axios from "axios";
import Header from "../../components/Header";
import DOMPurify from 'isomorphic-dompurify';

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: '',
            content: '',
        }
    }


    componentDidMount() {
        var uri = window.location.href.substr(window.location.protocol.length + window.location.hostname.length + 2)
        axios
            .get('/api/pages' + uri + '/', {withCredentials: false})
            .then(res => this.setState({content: res.data.content}))
            .catch(err => console.log(err))
    }

    render() {


        return (
            <div>
                <Header handleLogout={this.handleLogout} {...this.props}/>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.content)}} />

            </div>

        );
    }
}

export default Page;