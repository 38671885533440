import React from 'react';
import PropTypes from 'prop-types'
import {Comment} from 'semantic-ui-react'
import {truncateDate} from '../../utils'

export const BasicComment = ({comment}) => {
    const nestedComments = (comment.children || []).map(comment => { //Recursively generate comments given children
        return <BasicComment key={comment.id} comment={comment} />
    })
    
    if(!comment.jsdPublic) //Skip and do not render anything if private
        return null

    const extract = (body) => {
        let author
        
        // If body includes a name, parse it
        if(body.includes('(*') && body.includes('*)')) { 
            author = body.substring(
                body.indexOf("(*") + 2, 
                body.lastIndexOf("*)")
            );    
            //remove author from body
            body = body.slice(0, body.indexOf("(*")) + body.slice(body.lastIndexOf("*)") + 2)
            
        }
        
        // If body includes a SOW title, remove it from comment
        if(body.includes('[*') && body.includes('*]')) {
            body = body.slice(0, body.indexOf("[*")) + body.slice(body.lastIndexOf("*]") + 2)
        }
        
        return [author, body]
    }
    let [author, text] = extract(comment.body)
    return (
            <Comment>
                <Comment.Content>
                    <Comment.Author as='a'>{author ? author : comment?.author?.displayName}</Comment.Author>
                    <Comment.Metadata>
                        <span>{truncateDate(comment.created)}</span>
                    </Comment.Metadata>
                    <Comment.Text>{text}</Comment.Text>
                </Comment.Content>
                {
                nestedComments.length > 0 && //If children exist, surround them in a group tag
                    <Comment.Group>
                        {nestedComments}
                    </Comment.Group>
                }
            </Comment>
    )
}

BasicComment.propTypes = {
    comment: PropTypes.object,
}