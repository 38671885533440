import React, { Component } from 'react';
import './Header.css';
import {
    Menu, Icon, Dropdown
} from "semantic-ui-react";
import axios from 'axios'
import { isDevelopment } from '../../utils';
import { GlobalContext } from '../../context';
import PropTypes from 'prop-types';
import { withWindowDimension } from '../HOC/useWindowDimension';

const style = {
    icon: {
        top: '2px',
        left: '31px',
        color: 'black',
        backgroundColor: 'yellow'
    },

    menu: {
        borderRadius: "0px",
        height: "48px",
        fontSize: '13px'
    },

    listItem: {
        padding: '3px',
    }
}

class Header extends Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: '',
            // screenWidth: window.innerWidth
        }
    }

    redirectSSO = () => {
        let host = window.location.host
        let clientInfo = {
            client_id: 'https://rit-portal.med.stanford.edu/',
            redirect_uri: window.location.protocol + '//' + window.location.host + '/auth/',
            authorization_endpoint: 'https://idp-proxy.med.stanford.edu/auth/realms/med-all/protocol/openid-connect/auth'
        };
        if (isDevelopment && host.toLowerCase().indexOf('stanford.edu') === -1) {
            clientInfo = {
                client_id: 'account',
                redirect_uri: window.location.protocol + '//' + window.location.host + ':8000/auth/',
                authorization_endpoint: 'https://idp-proxy-stage.med.stanford.edu/auth/realms/projects-portal/protocol/openid-connect/auth'
            };
        }

        // Set client info and redirect
        window.OIDC.setClientInfo(clientInfo);
        window.OIDC.restoreInfo();
        window.OIDC.login({
            scope: 'openid profile email',
            response_type: 'code'
        });
    }

    handleLogout = () => {
        axios
            .get('/logout/')
            .then(res => {
                this.context.removeAuthentication();
                this.props.history.replace('/');
            })
            .catch(err => console.log(err))
    }

    renderDesktopView = (style) => {
        const { userInfo } = this.context
        if (userInfo) {
            const adminLinks = () => {
                return (
                    <>
                        <Menu.Item link target="_blank" href="/admin/"><Icon name="adn"></Icon>Admin Panel</Menu.Item>,
                        <Menu.Item link target="_blank" href="/approved"><Icon name="adn"></Icon>Admin SOW</Menu.Item>
                    </>
                )

            }

            return (
                <Menu size='mini' style={style.menu}>
                    <Menu.Item header fitted href="https://med.stanford.edu/researchit.html">
                        <img
                            style={{ width: '180px', marginLeft: '10px' }}
                            src={'https://rit-portal.med.stanford.edu/static/app/images/web-logo-color-filled-shield.png'}
                            alt={'Stanford Medicine Logo'}
                        />
                    </Menu.Item>

                    {userInfo?.is_admin && adminLinks()}
                    <Menu.Item link href="/home"><Icon name='home'></Icon>My Projects</Menu.Item>
                    <Menu.Item link href="/support"><Icon name='question'></Icon>Support</Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item style={{ color: "black" }}>
                            <Icon style={{ color: 'black' }} name='user' />
                            {userInfo?.first_name + ' ' + userInfo?.last_name}
                        </Menu.Item>
                        <Menu.Item style={{ color: "black" }} onClick={this.handleLogout}>
                            <Icon style={{ color: 'black' }} name='sign-out' />
                            Logout
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            )
        } else {
            return (
                <Menu size='mini' style={style.menu}>
                    <Menu.Item header fitted href="https://med.stanford.edu/researchit.html">
                        <img
                            style={{ width: '180px', marginLeft: '10px' }}
                            src={'https://rit-portal.med.stanford.edu/static/app/images/web-logo-color-filled-shield.png'}
                            alt={'Stanford Medicine Logo'}
                        />
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item style={{ color: "black" }} onClick={this.redirectSSO}>
                            <Icon style={{ color: 'black' }} name='sign-in' />
                            Login
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            )
        }
    }

    renderMobileView = (style) => {
        const { userInfo } = this.context

        if (userInfo) {
            return (
                <Menu size='mini' style={style.menu}>
                    <Menu.Item header fitted href="https://med.stanford.edu/researchit.html">
                        <img
                            style={{ width: '180px', marginLeft: '10px' }}
                            src={'https://rit-portal.med.stanford.edu/static/app/images/web-logo-color-filled-shield.png'}
                            alt={'Stanford Medicine Logo'}
                        />
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Dropdown
                            direction='left'
                            scrolling
                            item
                            icon='bars'
                        >
                            <Dropdown.Menu>
                                {userInfo?.is_admin && <Menu.Item link target="_blank" href="/admin/"><Icon name="adn"></Icon>Admin Panel</Menu.Item>}
                                <Menu.Item link href="/home"><Icon name='home'></Icon>My Projects</Menu.Item>
                                <Menu.Item link href="/support"><Icon name='question'></Icon>Support</Menu.Item>
                                <Menu.Item style={{ color: "black" }} onClick={this.handleLogout}>
                                    <Icon style={{ color: 'black' }} name='sign-out' />
                                    Logout
                                </Menu.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>
            )
        } else {
            return (
                <Menu size='mini' style={style.menu}>
                    <Menu.Item header fitted href="https://med.stanford.edu/researchit.html">
                        <img
                            style={{ width: '180px', marginLeft: '10px' }}
                            src={'https://rit-portal.med.stanford.edu/static/app/images/web-logo-color-filled-shield.png'}
                            alt={'Stanford Medicine Logo'}
                        />
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item style={{ color: "black" }} onClick={this.redirectSSO}>
                            <Icon style={{ color: 'black' }} name='sign-in' />
                            Login
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            )
        }
    }
    render() {
        const { screenWidth } = this.props
        if (screenWidth < 768) { // is mobile view
            return this.renderMobileView(style)
        } else {
            return this.renderDesktopView(style)
        }
    }
}

export default withWindowDimension(Header);

Header.propTypes = {
    history: PropTypes.object, //React router history
    redirectSSO: PropTypes.func
}
