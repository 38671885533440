import React from 'react'
import {
    Button, Form, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types'

//Step 3
export const StepReject = ({onChange, submission_comment, manageSOW}) => {
    
    const triggerCallback = () => {
        manageSOW(4) //4 = status code for reject
    }
    
    return (
        <div>
            <Message warning>
                <Message.Header>Note:</Message.Header>
                <p>
                    Rejecting a statement of work will remove it from our queue permanently. <br></br>
                    Please choose the `revise` option in the previous step if you require information changed rather than deleting and creating anew.
                </p>
            </Message>
            <h4>Please enter a reason for rejecting this statement of work</h4>   
            <Form>
                <Form.Field>
                    <Form.TextArea 
                        name = 'submission_comment' 
                        onChange={onChange} 
                        value = {submission_comment} 
                        label={'Comment'}
                        placeholder={'Eg: We no longer are interested in RIT services ...'}
                    />
                </Form.Field>
                <Button onClick={triggerCallback} primary>Submit</Button>
            </Form>
        </div>
    )
}

StepReject.propTypes = {
    onChange: PropTypes.func,
    submission_comment: PropTypes.string,
    manageSOW: PropTypes.func

}
