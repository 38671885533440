import React, {Component} from 'react';
import {
    Button, Segment, Grid, Divider, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types'

//Step 1
export class WizardHome extends Component {
    componentDidMount() {
        //On page refresh / new project creation, set IRB to null
        this.props.update('irb', '')
        this.props.updateProgress(0)
    }

    render() {
        return (
            <div>
                <Segment 
                    basic
                    content={
                        <div>
                            <h3>Welcome to the R2P2 project registration wizard</h3>
                            <p>The goal of this wizard is to find or create a record that represents your research endeavor</p>
                            <p>This record acts as an umbrella covering all questions, support requests, statements of work, professional services, consultations, and more</p>
                            <p>If you have a particular question, you must first register your research project by completing the wizard, and then you will have the opportunity either file a support ticket or request a research consultation</p>
                            <p>Lets get started!</p>
                            {/* <Message info>
                                <Message.Header>Welcome to the Project Wizard!</Message.Header>
                                <Message.List>
                                    <Message.Item>
                                        All Interactions with RIT are linked to your research projects. 
                                    </Message.Item>
                                    <Message.Item>
                                        Please complete the next few steps to create or link a project under your account     
                                    </Message.Item>
                                </Message.List>
                            </Message> */}
                            {/* IRB functionality taken out for now */}
                            <Divider></Divider>
                            <h3>
                                Does your project have an IRB number?
                            </h3>
                            <p>R2P2 is linked with the Stanford IRB protocol system. </p>
                            <p>If you know your IRB number, we can pull your project information automatically</p>
                        </div>
                    }
                />
                    <Segment placeholder>
                        <Grid columns={2} stackable textAlign='center'>
                            <Button size='large' name='Search' onClick={this.props.nextStep} primary content='Yes - I know my IRB #'   icon='check'/>
                            <Divider vertical>Or</Divider>
                            <Button name='IRB'  size='large' onClick={()=>this.props.goToStep(3)} primary content='No' icon='x'/>
                        </Grid>
                    </Segment>
            </div>
        );
    }
}

WizardHome.propTypes = {
    update: PropTypes.func,
    updateProgress: PropTypes.func,
    performTransition: PropTypes.func,
    goToStep: PropTypes.func,
    nextStep: PropTypes.func
}