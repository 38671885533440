import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GlobalContext } from '../../context';

export default function PrivateRoute({ children, ...props }) {
  const context = useContext(GlobalContext)
  return (
    <Route
      {...props}
      render={() => {
        return context?.userInfo?.is_admin === true ? (
          props.render(props) //pass props to child component
        ) : (
          <Redirect to="/home" />
        );
      }}
    />
  );
}