import React, { Component } from 'react';
import {
    Message, List, Button, Segment,
    Divider, Form,
    Modal, Table, Checkbox, Label
} from 'semantic-ui-react';
import axios from 'axios';
import { getCookieValue, truncateDate } from '../../../../utils';
import * as _ from 'underscore'
import PropTypes from 'prop-types'
import './WizardIrb.css';


export class WizardIrb extends Component {
    constructor(props) {
        super(props)
        this.state = {
            irb_search: '',
            irb: '',
            error: '',
            //column sort
            column: null,
            direction: null,
            //modal
            open: false,
            selectedID: '',
            confirmModal: false,
            disabled: [],

            loading: false
        }
    }

    componentDidMount() {
        this.props.updateProgress(25)
    }

    update = (e) => {
        this.props.update(e.target.name, e.target.value);
    }

    onChange = ({ target }) => {
        this.setState({ irb_search: target.value })
    }

    onDismiss = () => {
        this.setState({ error: '' })
    }

    irbFound = (res) => {
        const irb = res.data
        const availableProjects = irb.projects.filter((obj) => {
            return obj.is_user_in_project === false
        })

        irb.projects = availableProjects //Filter out all projects not joined
        this.setState({
            error: '',
            irb: irb,
            loading: false
        })
    }

    irbNotFound = (err) => {
        this.props.update('irb', -1) //update form data to not found needed for transition
        this.setState({
            irb: -1,
            error: `IRB # ${this.state.irb_search} yielded no results in our system, please try a different number.`,
            loading: false
        })
    }

    createNewUnderIRB = (res) => {
        this.props.update('irb', this.state.irb) //update form data from parseInt
        this.props.goToStep(4) //transition to next step
    }

    onClick = () => {
        const { irb_search } = this.state
        const isCorrectFormat = /^\d+$/.test(irb_search)
        this.setState({ loading: true })

        if (isCorrectFormat) { //check whether or not the formatting for irb is adequate
            const cookieValue = getCookieValue('csrftoken');
            const params = new URLSearchParams(); //need to serialize parameters, different than other requests
            params.append('number', irb_search);

            axios.post('/api/irb/search/', params, {
                withCredentials: true,
                headers: {
                    "X-CSRFToken": cookieValue,
                    "content-type": "application/x-www-form-urlencoded"
                },
            })
                .then(res => this.irbFound(res))
                .catch(err => this.irbNotFound(err))
        } else {
            this.setState({
                error: 'Incorrect IRB format, please ensure the IRB # is entered correctly with no spaces or extra characters',
                irb: '',
                loading: false
            })
        }
    }

    handleSort = (clickedColumn) => () => {
        var { column, direction, irb } = this.state

        if (column !== clickedColumn) { //If clicking different column or first-time
            var sorted = _.sortBy(irb.projects, (obj) => {
                switch (clickedColumn) {
                    case 'project_name':
                        return obj.project_name.toUpperCase() //upper and lower case have different ASCII val
                    case 'project_description':
                        return obj.project_description.toUpperCase()
                    case 'project_created_at':
                        return obj.project_created_at
                    default:
                        return obj.project_name.toUpperCase()
                }
            })

            irb.projects = sorted

            this.setState({ column: clickedColumn, irb: irb, direction: 'ascending' })
        } else {
            var reversed = irb.projects.reverse()
            irb.projects = reversed
            this.setState({
                irb: irb,
                direction: direction === 'ascending' ? 'descending' : 'ascending',
            })
        }
    }

    /* Modal functionality */

    //Run upon Add project click
    displayModal = (e) => {
        this.state.open ? this.setState({ open: false, confirmModal: false })
            : this.setState({ open: true, selectedID: e.target.value, confirmModal: false })
    }

    //Run upon confirm checkbox change
    confirmModal = () => {
        !this.state.confirmModal ? this.setState({ confirmModal: true }) : this.setState({ confirmModal: false })
    }

    //Run upon clicking add after verfiying project correctness 
    continueModal = () => {
        //upon completion : disable the button so user cant project a second time 
        const { selectedID } = this.state
        const cookieValue = getCookieValue('csrftoken');

        //Post user information in order to link to project
        axios({
            method: 'post',
            url: `/api/projects/${selectedID}/request-access/`,
            headers: {
                // 'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": cookieValue,
            }
        }).then((res) => {
            var disabledButtons = [...this.state.disabled, parseInt(selectedID)]
            this.setState({ disabled: disabledButtons, open: false })
        })
            .catch(err => console.log(err))

    }

    //Called when IRB has been found, and we need to render associated projects
    displayProjectTable = () => {
        const { irb } = this.state
        if (irb && irb.projects.length > 0) { //If there are projects to display to user
            const { column, direction, disabled } = this.state
            const projects = this.state.irb.projects.map((val) => {
                return (
                    <Table.Row key={val.id}>
                        <Table.Cell>
                            {disabled.includes(val.id) ? <Label horizontal size='tiny' color='green'>Added</Label> : ""}
                            {val.project_name}
                        </Table.Cell>
                        <Table.Cell>{val.project_description}</Table.Cell>
                        <Table.Cell>{truncateDate(val.project_created_at)}</Table.Cell>
                        <Table.Cell>
                            <Button
                                disabled={disabled.includes(val.id)}
                                floated='left'
                                primary
                                value={val.id}
                                onClick={this.displayModal}>Join</Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })
            //add option to create a brand new project on the last row of table: 
            projects.push(
                <Table.Row key='NewProject'>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell><Button onClick={this.createNewUnderIRB} positive>New +</Button></Table.Cell>
                </Table.Row>
            )

            return (
                <div>
                    <Message
                        size='tiny'
                        content={
                            <>
                               <p>Below is a list of previously created projects registered under this IRB:</p>
                                <p>If you are a collaborator on one or more of the listed projects, use the <Label as='span' size='tiny' color='blue'>Join</Label> button to add yourself as a user and gain access on your home page.</p>
                                <p>If you would like to create a brand new project under this IRB please click <Label as='span' size='tiny' color='green'>New +</Label></p>
                            </>
                        }
                    />
                    <Table celled sortable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    sorted={column === 'project_name' ? direction : null}
                                    onClick={this.handleSort('project_name')}
                                >Project Name</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'project_description' ? direction : null}
                                    onClick={this.handleSort('project_description')}
                                >Project Description</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'project_created_at' ? direction : null}
                                    onClick={this.handleSort('project_created_at')}
                                >Creation Date</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {projects}
                        </Table.Body>
                    </Table>

                    {/* Modal information upon clicking add project */}

                    <Modal size='large' closeIcon open={this.state.open} onClose={this.displayModal}>
                        <Modal.Header>Add project to portal</Modal.Header>
                        <Modal.Content>
                            <Message
                                header="NOTE:"
                                info
                                content={
                                    <div>
                                        <p>This process will link this project under your account home page and provide basic access
                                            to its features</p>
                                        <p>Adding a project will also notify the owner of your accessibility</p>
                                    </div>
                                }
                            />
                            <br />
                            <Checkbox
                                onChange={this.confirmModal}
                                label={'By checking this you confirm this project is correct'}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content='Join'
                                onClick={this.continueModal}
                                disabled={!this.state.confirmModal}
                                positive
                            />
                        </Modal.Actions>

                    </Modal>
                </div>
            )
        } else { //Otherwise show placeholder
            return (
                <>
                    <Message
                        size='small'
                        content={
                            <>
                                <h4>There are no current R2P2 projects registered under this IRB</h4>
                                <p>If this IRB information matches your project click <Label as='span' size='tiny' color='green'>New +</Label> to register a new project under this IRB in R2P2</p>
                                <p>Otherwise, click <Label as='span' size='tiny' color='blue'>Skip</Label> to search projects by user</p>
                            </>
                        }
                    />
                    <Segment size='mini' basic clearing>
                        <Button onClick={this.createNewUnderIRB} floated='right' positive>New +</Button>
                    </Segment>

                </>
            )
        }
    }

    //Render all column elements depending on IRB status
    displayIrbInfo = () => {
        const { irb } = this.state
        return (
            <Segment>
                <Message
                    positive
                    size='tiny'
                    header={`IRB #${irb.protocol_number} Match found`}
                />
                <Divider />
                <List>
                    <List.Item
                        content={
                            <div>
                                <Label icon='folder outline' content='Protocol Title' horizontal />
                                <strong>{irb.protocol_title}</strong>
                            </div>
                        }
                    />
                    <List.Item
                        content={
                            <div>
                                <Label icon='building outline' content='Department' horizontal />
                                <strong>{irb.protocol_department}</strong>
                            </div>
                        }
                    />
                    <List.Item
                        content={
                            <div>
                                <Label icon='clock' content='Creation Date' horizontal />
                                <strong>{truncateDate(irb.created_at)}</strong>
                            </div>
                        }
                    />
                </List>
                {this.displayProjectTable()}
            </Segment>
        )
    }

    render() {
        return (
            <div>
                <h3>Please enter your IRB number: </h3>
                <p>If no IRB is found within Stanford's IRB database, please click skip</p>
                {/* <Message info>
                    <Message.List>
                        <Message.Header>Notice</Message.Header>
                        <Message.Item>
                            Please enter your IRB number below to determine whether we can access your project information
                        </Message.Item>
                        <Message.Item>If no IRB is found within Stanford's IRB database, please click skip to try searching projects by user</Message.Item>
                    </Message.List>
                </Message> */}
                {this.state.error ?
                    <Message
                        size='tiny'
                        onDismiss={this.onDismiss}
                        negative
                        header={this.state.error}
                        content={
                            <div>
                                Cant find a match? Skip this step.
                            </div>
                        }
                    />
                    : ''
                }

                <Divider></Divider>
                <Form>
                    <Form.Group>
                        <Form.Field width={16} className = {this.state.loading ? 'hideSearch' : ''}>
                            <Form.Input
                                className='irbInput'
                                loading={this.state.loading}
                                onChange={this.onChange}
                                placeholder='e.g. 54321'
                                action={{
                                    onClick: (e) => this.onClick(e),
                                    icon: 'search'
                                    
                                }}

                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {this.state.irb && this.state.irb !== -1 ? this.displayIrbInfo() : ''}
                <br />
                <Button
                    content='Back'
                    floated='left'
                    labelPosition='left'
                    icon='arrow left'
                    color='red'
                    onClick={this.props.previousStep}
                />
                <Button
                    floated='right'
                    content='Skip'
                    primary
                    labelPosition='right'
                    name='Create'
                    icon='arrow right'
                    onClick={this.props.nextStep}
                />
            </div>
        );
    }
}

WizardIrb.propTypes = {
    update: PropTypes.func,
    updateProgress: PropTypes.func,
    goToStep: PropTypes.func,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func
}