import React, { useState } from 'react'
import {
    Modal, Button, Form, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types'

/**
 * 
 * @prop {Array} - sow: Statement of work array of objects
 * @prop {Function} showModal - Function that alters state of modal to open/close
 * @prop {Function} addPTA - Callback function that handles form submit
 * @prop {Boolean} open - Variable that represents opened/closed modal
 */
export const CreateFinanceModal = ({ open, addPTA, showModal, sow }) => {
    const options = sow?.map((val) => ({ text: `${val.title} (#${val.id})`, value: val.id }))
    const [formData, setData] = useState('')
    const [error, setError] = useState(options.length ? '' : 'All current Statements of work on this project have been assigned a PTA, please use the edit feature to change a PTA rather than create a new one')

    const handleModalState = (e, arg) => {
        const newData = { ...formData }
        newData[arg.name] = arg.value
        setData(newData)
    }
    
    const validate = (stripped) => {
        const req = [
            stripped.length > 0,
        ]
        return req.indexOf(false) == -1
    }

    const onSubmit = () => {
        const { sow_id, pta_charge_number } = formData
        const stripped = pta_charge_number.trim()
        
        if (validate(stripped) && sow_id) {
            addPTA({'sow_id': sow_id, 'pta_charge_number': stripped})
        } else {
            setError('One of the following fields has not been completed')
        }
    }

    return (
        <Modal size='small' closeIcon open={open} value={'add_open'} onClose={showModal} >
            <Modal.Header>Add a new PTA number</Modal.Header>
            <Modal.Content>
                {error ? <Message error content={error} /> : ''}
                
                <Form>
                    <Form.Select
                        options={options}
                        placeholder='Please choose from the following...'
                        label='Statement of Work'
                        name='sow_id'
                        onChange={handleModalState}
                        disabled={!options.length}
                    />
                    <Form.Field>
                        <Form.Input
                            label='PTA number'
                            placeholder='Eg: 1234567890'
                            name='pta_charge_number'
                            maxLength={64}
                            onChange={handleModalState}
                            disabled={!options.length}

                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Create'
                    onClick={onSubmit}
                    primary
                    disabled={!options.length}

                />
            </Modal.Actions>
        </Modal>
    )
}

CreateFinanceModal.propTypes = {
    open: PropTypes.bool,
    addPTA: PropTypes.func,
    showModal: PropTypes.func,
    sow: PropTypes.array
}
