import React, {useEffect, useState} from 'react'
import { Modal } from 'semantic-ui-react'
import { ChargeTable } from '../../../../views/Admin/Charges'
import axios from 'axios'

export default function UserChargeModal({open, onChange, sow}){
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const forceClose = () => {
        onChange()
    }

    useEffect(() => {
        axios
            .get(`/api/projects/sow/${sow.id}/charges/`, { withCredentials: true })
            .then((res) => {
                setData(res?.data ? res.data : [])
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })

    }, [])
    return (
        <Modal size='fullscreen' open={open} onClose={forceClose} closeIcon>
            <Modal.Content>
                <ChargeTable 
                    view='user'
                    sow={sow}
                    parentLoading={loading}
                    previousCharges={data}

                />
            </Modal.Content>
        </Modal>
    )
}
