import React, { useMemo, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Popup, Form, Input, Button, Message } from 'semantic-ui-react';

export default function BasicPopup({ title, content, onSubmit, ...props }) {
    const [date, setDate] = useState('')
    const [description, setDescription] = useState('')
    const [cost, setCost] = useState('')
    const [count, setCount] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const defaultContent = useMemo(() => {
        const setDateValue = (e) => {
            let conv = new Date(e).toISOString().slice(0, 10)
            setDate(conv)
        }
        const submitData = (e) => {
            e.preventDefault()
            const requirements = [
                date.length > 0,
                description.length > 0,
                cost.length > 0,
                /^\d*\.?\d+$/.test(cost), //Check if string contains only digits with/without decimal
                count < 13,
            ]

            if (requirements.indexOf(false) === -1) { //Passed checks, submit information
                onSubmit({
                    start_date: date,
                    description: description,
                    amount: cost,
                    count: count
                })
                setIsOpen(false)
                setCount('')
                setCost('')
                setDescription('')
                setDate('')
            }
        }

        return (
            <Form>
                <Message info content="This popup will create multiple table items for you automatically" />
                <Form.Field>
                    <label>Charge Description</label>
                    <Input required value={description} onChange={(e) => setDescription(e.target.value)} placeholder='A suffix will automatically be appended at the end of description' />
                </Form.Field>
                <Form.Field>
                    <label>Total Cost</label>
                    <Input required onChange={(e) => setCost(e.target.value)} placeholder='This number will be divided by the number of charges ' />
                </Form.Field>
                <Form.Field>
                    <label>Start Date</label>
                    <DatePicker
                        required
                        selected={new Date()}
                        popperProps={{ strategy: 'fixed', placement: 'auto' }}
                        value={date}
                        onChange={setDateValue}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Number of Charges</label>
                    <Input required onChange={(e) => setCount(e.target.value)} type='number' max="12" placeholder='e.g. 4' />
                </Form.Field>
                <Button size='tiny' color='green' onClick={submitData}>Generate</Button>
            </Form>
        )
    }, [date, description, count, cost, onSubmit])

    return (
        <div>
            <Popup
                trigger={<Button color='green' size='tiny' icon='add' content={title} />}
                content={content ?? defaultContent}
                on='click'
                flowing
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                {...props}
            />
        </div>
    )
}