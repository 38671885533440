import React, { Component } from 'react';
import axios from 'axios';
import {
    Button, Icon, Segment, Message,
} from 'semantic-ui-react';
import DataTable from 'react-data-table-component';
import qs from 'qs';
import { getCookieValue, truncateDate } from '../../../utils';
import { TicketModal } from './Modals';
import { IssueDetailModal } from './IssueDetailModal';
import { TemplateNotification } from '../../TemplateNotification';
import { TableLoader } from '../../TableLoader';
import { NoDataComponent } from '../../NoDataComponent';
import { GlobalContext } from '../../../context';
import memoize from 'memoize-one';

const getConditionalRowStyles = memoize(() => [
    {
        when: row => row.status === "Resolved",
        style: {
            backgroundColor: 'rgba(105, 96, 101, 0.06)',
            color: 'grey',
        }
    }
])

const columns = memoize(displayDetailModal => [
    {
        name: 'Summary',
        selector: row => row.summary,
        sortable: true,
        // wrap: true,
        // maxWidth: '200px'
    },
    // {
    //     name: 'Description',
    //     selector: row => row.description,
    //     sortable: true,
    //     grow: 3,
    //     maxWidth: '320px'
    // },
    {
        name: 'Date Created',
        selector: row => row.date,
        sortable: true,
        // width: '175px'
    },
    // {
    //     name: 'Type',
    //     selector: row => row.type,
    //     sortable: true,
    //     hide: 'lg'
    // },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        // maxWidth: '64px'
    },
    {
        cell: row => <Button row={row} size='tiny' content='Details' onClick={displayDetailModal} />,
        width: '151px'
    }
])

class TabTickets extends Component {
    static contextType = GlobalContext
    constructor(props) {
        super(props)
        const { search } = this.props.location
        const params = new URLSearchParams(search)

        this.state = {
            data: [], //full json response, selectedIssue based on this
            tableData: [], //json formatted for dataTable
            requestTypes: [], //JIRA ticketTypes
            redcapProjects: [],
            attachedFile: '',

            add_open: false, //add-ticket modal
            detail_open: false, //detail modal

            /* Form on-change variables */
            loading: true,
            loading_comment: false,
            loading_ticket: false,
            selectedIssue: {},
            error: '',
            comment: '', //Leave JIRA comment
            users: [],

            popup_open: false,
            loading_popup: false,

            serverDetailId: params.get('id') || null

        }
    }

    //Necessary variable for user upload for filetypes 
    fileInputRef = React.createRef();

    useNull = () => null

    componentDidMount() {
        var { pid } = this.props.match.params

        axios //fetch both projects, and user information
            .all([
                axios.get('/api/jira/request-types/', { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/issues/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/linked-redcap/`, { withCredentials: true }).catch(this.useNull),
                axios.get(`/api/projects/${pid}/users/`, { withCredentials: true }).catch(this.useNull),
            ]).then(axios.spread((requestTypes, issues, redcapProjects, users) => { //will block unti both requests are complete
                this.formatTableData(requestTypes, issues, redcapProjects, users)
            })).catch(err => this.setState({error: 'Something went wrong with a network request. Please contact your administrator' }))
    }

    /**
     * Parse JSON object returned by JIRA into tabular format 
     * @param {Object} requestTypes : data returned from GET /jira/request-types/
     * @param {Object} issues : data returned from GET /api/projects/x/issues/
     * @param {Object} redcapProjects : data returned from GET /api/projects/x/linked-redcap/
     */
    formatTableData = (requestTypes, issues, redcapProjects, users) => {
        if (issues && requestTypes) {
            var fillTable = []
            let err;
            const filter = issues.data.filter(el => el?.jira?.length !== 0)
            const deleted = issues.data.filter(el => el?.jira?.length === 0)
            for (var i = 0; i < filter.length; i++) //parse issues JSON into readable table
                fillTable.push(this.createTableRow(filter[i]))


            if (deleted.length > 0) {
                let ticket_ids = deleted.map(el => el?.jira_issue_key)
                err = ticket_ids.join(', ')
            }

            if (this.state.serverDetailId) {
                let selected = this.findSelectedRow(this.state.serverDetailId, issues.data)
                this.setState({
                    data: issues.data,
                    redcapProjects: redcapProjects?.data ?? undefined,
                    requestTypes: requestTypes.data.values,
                    tableData: fillTable,
                    loading: false,
                    selectedIssue: selected ? selected : {},
                    detail_open: selected ? true : false,
                    users: users?.data?.results,
                    error: err ? `The following issues have been deleted from this project and not shown: ${err}, please contact your administrator` : ''
                })
            } else {
                this.setState({
                    data: issues.data, requestTypes: requestTypes.data.values,
                    redcapProjects: redcapProjects?.data ?? undefined,
                    tableData: fillTable,
                    loading: false,
                    users: users?.data?.results,
                    error: err ? `The following issues have been deleted from this project and not shown: ${err}, please contact your administrator` : ''
                })
            }


        } else {
            this.setState({ data: [], requestTypes: [], tableData: [], loading: false, redcapProjects: [] })
        }

    }

    /**
     * Returns full data object of a given row ID
     * @param {Integer} row_id 
     * @returns Object
     */
    findSelectedRow = (row_id, data = null) => {
        if (!data)
            data = this.state.data
        return data.find((el) => el.id === parseInt(row_id))
    }

    /** Creates a row object for dataTable based on passed in issue object
     * @param {Object} issue - Issue object
     * @return {Object} row : {id, summary, description, date, type, status}
     */
    createTableRow = (issue) => {
        if (issue) {
            return {
                id: issue.id,
                summary: issue.jira.fields.summary,
                description: issue.jira.fields.description,
                date: truncateDate(issue.created_at),
                type: issue.jira.fields['customfield_10010'].requestType.name, //static requestType customfield
                status: issue.jira.fields.status.name
            }
        } else {
            return {}
        }
    }


    /**
     * Manage changes from Modal content for issues
     * @param {String} value - value of variable to update to
     * @param {String} name - variable name to update state of
     */
    handleChange = (e, { value, name }) => {
        this.setState({ [name]: value })
    }

    /**
     * Get string representatino of current date + time
     */
    getCurrentTime = () => {
        var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var MM = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        var hh = String(today.getHours()).padStart(2, '0');
        var mm = String(today.getMinutes()).padStart(2, '0');
        var ss = String(today.getSeconds()).padStart(2, '0');

        return (yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss)
    }

    /**
     * Function that creates a ticket within JIRA based on current state values
     */
    addTicket = (data) => {
        const cookieValue = getCookieValue('csrftoken');
        const { pid } = this.props.match.params
        var { requestTypes } = this.state
        var issueLabel = requestTypes.find(obj => obj.id === data.type)

        this.setState({ loading_ticket: true }) //on Submit show user info is sending 
        axios({
            method: 'post',
            url: `/api/projects/${pid}/add-issue/`,
            data: qs.stringify({
                request_type: data.type,
                description: data.description, //body 
                summary: data.summary, //title
                redcap: data.redcap ?? undefined, //title
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": cookieValue,
            }
        }).then((res) => {
            var addRow = {
                id: res.data.id,
                summary: data.summary,
                description: data.description,
                date: this.getCurrentTime(),
                type: issueLabel.name ? issueLabel.name : "?",
                status: "Open"
            }

            this.setState({
                data: [...this.state.data, res.data], //update state for json data
                tableData: [...this.state.tableData, addRow], //and tableData
                add_open: false, loading_ticket: false
            })
        })
            .catch((err) => {
                this.setState({ 
                    loading_ticket: false, 
                    add_open: false,
                    error: JSON.stringify(err?.response?.data) 
                })
            })

    }

    /**
     * Function that shows or hides add-ticket modal 
     */
    displayAddModal = () => {
        if (this.state.add_open) //Clicking close
            this.setState({ add_open: false })
        else //Clicking add
            this.setState({ add_open: true })
    }

    /**
     * Function that shows or hides ticket detail modal
     * @param {Object} row : tabular data for selected row
     */
    displayDetailModal = (e, { row }) => {
        if (this.state.detail_open) { //Clicking close
            this.setState({ detail_open: false })
        } else {
            let selected = this.findSelectedRow(row.id)
            this.setState({ detail_open: true, selectedIssue: selected }) //set selectedIssue for detail render
        }
    }

    //update TextArea state based on field value
    onCommentChange = (e, { value }) => {
        this.setState({ comment: value })
    }

    // Comment callback
    onCommentSubmit = (res) => {
        var jira_id = res.data.id
        var { data } = this.state
        var updatedIndex = (data.findIndex(obj => obj.id === jira_id)) //Find index within data to update
        data[updatedIndex] = res.data
        this.setState({ data: data, selectedIssue: res.data })

    }

    //Update state with file upon selection
    onFileChange = (file) => {
        this.setState({ attachedFile: file })
        // this.setState({attachedFile: e.target.files[0]})
    }

    handlePopup = () => {
        this.state.popup_open ? this.setState({ popup_open: false, attachedFile: '' }) : this.setState({ popup_open: true })
    }

    //Callback for attachment files 
    uploadAttachment = (res) => {
        var { data } = this.state
        var updatedIndex = (data.findIndex(obj => obj.id === res.data.id)) //Find index within data to update
        data[updatedIndex] = res.data
        this.setState({ data: data, selectedIssue: res.data })
    }

    updateParticipantCallback = (data, selectedIssueId) => {
        let refresh = this.state.data
        let index = refresh.findIndex(e => e.id === selectedIssueId)
        refresh[index]['jira']['fields']['customfield_10028'] = data?.data?.values
        this.setState({data: refresh})
    }

    render() {
        const { loading, selectedIssue, requestTypes, redcapProjects, error,
            loading_ticket, add_open, users, detail_open, data
        } = this.state
        const subHeader = (
            <div>
                <Button
                    floated='right'
                    icon
                    labelPosition='left'
                    primary
                    size='small'
                    value='add'
                    onClick={this.displayAddModal}
                >
                    <Icon name='add circle' /> Add Ticket
                </Button>
            </div>
        )

        return (
            <Segment attached='bottom' style={{ height: "100%" }}>
                <TemplateNotification
                    type='support_tab'
                />
                {this.context.userInfo?.is_admin && error ? <Message color='red' content={error} /> : ''}

                <DataTable
                    title='Project tickets'
                    columns={columns(this.displayDetailModal)}
                    data={this.state.tableData}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    subHeaderComponent={subHeader}
                    subHeader={true}
                    progressComponent={<TableLoader loadingText='Loading...' active={loading} />}
                    progressPending={loading}
                    noDataComponent={<NoDataComponent text='There are no tickets currently affiliated with this project' />}
                    conditionalRowStyles={getConditionalRowStyles()}
                />
                <TicketModal
                    requestTypes={requestTypes}
                    redcapProjects={redcapProjects}
                    add_open={add_open}
                    loading_ticket={loading_ticket}
                    displayAddModal={this.displayAddModal}
                    addTicket={this.addTicket}
                />

                {/* Modal displaying detailed content on a specific JIRA ticket */}
                <IssueDetailModal
                    selectedIssue={selectedIssue}
                    detail_open={detail_open}
                    displayDetailModal={this.displayDetailModal}
                    attachmentCallback={this.uploadAttachment}
                    commentCallback={this.onCommentSubmit}
                    users={users}
                    fullData={data}
                    updateParticipantCallback={this.updateParticipantCallback}
                    {...this.props}
                />
            </Segment>
        )
    }
}

export default TabTickets;
