import React, { useState, useEffect } from 'react'
import {
    Modal, Form, Button,
    Dimmer, Loader, Segment, List,
    Label, Grid, Popup, Feed, Header, Icon
} from 'semantic-ui-react';
import axios from 'axios'
import qs from 'qs'
import DOMPurify from 'isomorphic-dompurify';
import { getCookieValue, truncateStringEllipses, truncateDate } from '../../../../utils'
import './ConsultModal.css';
import Participant from '../../../Participant';
import PropTypes from 'prop-types'

export const ConsultationDetailModal = ({ open, onClose, selected, updateSelectedCallback, users, match }) => {
    const [comment, setComment] = useState('')
    const [isOpen, setOpen] = useState(false) //Popup status variables
    const [file, setFile] = useState('')
    const [feed, setFeed] = useState('') //Comment Feed
    const [attachmentFeed, setAttachmentFeed] = useState('') //Right-side attachment feed
    const [loading, setLoading] = useState(false) //Submission progress
    const [displayShort, setDisplay] = useState(true)
    var fileInputRef = React.createRef();
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => {
        setFile('')
        setOpen(false)
    }

    const onSave = () => {
        const { pid } = match.params
        const cookieValue = getCookieValue('csrftoken');
        setLoading(true) //enable loader

        axios.post(`/api/projects/${pid}/consultation/${selected.id}/add-comment/`,
            qs.stringify({ body: comment }), //have to stringify in order for it to be passed via "BODY" on django, cant pass as data
            {
                withCredentials: true,
                headers: { "X-CSRFToken": cookieValue },
            })
            .then(res => {
                setFeed(res?.data?.jira?.comments?.comments) //update UI, refresh defaults
                setLoading(false)
                setComment('')
                updateSelectedCallback(res.data)
                //update selected in table object ----------- 

            })
            .catch(err => console.log(err))
    }

    const generateCommentFeed = (comments) => { //filter by public before map
        let author
        const filter = comments.filter(el => el.body.indexOf("!attachment") && el.jsdPublic)

        const replaceJSX = (body, find) => {
            let urlBody = body?.match(find)
            if (!urlBody)
                return body
            return body?.split(find).flatMap((item, index) => {
                if (index < urlBody?.length) {
                    let decode = decodeURI(urlBody[index])
                    // let parse = decode.slice(1,-1)
                    let parse = DOMPurify.sanitize(decode.substring(
                        decode.indexOf("|") + 1,
                        decode.indexOf("]")
                    ))
                    return [item === "[Link" ? '' : item, <a key={index} href={`${parse}`}> Link </a>]
                }
            })
        }

        filter.sort((a, b) => a.updated - b.updated).reverse()

        return filter.map(obj => {
            obj.body = DOMPurify.sanitize(obj.body)
            if (obj.body.includes('(*') && obj.body.includes('*)')) {  //remove username from body
                author = obj.body.substring(
                    obj.body.indexOf("(*") + 2,
                    obj.body.lastIndexOf("*)")
                );
                //remove author from body
                obj.body = obj.body.slice(0, obj.body.indexOf("(*")) + obj.body.slice(obj.body.lastIndexOf("*)") + 2)
            }

            let quotesMatch = /\{.*?\}/g //Remove all instances of {...} styling , etc
            obj.body = obj.body.replaceAll(quotesMatch, '')

            let findLink = /\|https:\/\/[^\]]+]/g
            let replace = replaceJSX(obj.body, findLink)

            return (
                <Feed.Event key={obj.id}>
                    <Feed.Label>
                        <img alt={'avatar'} src={obj.author.avatarUrls['16x16']} />
                    </Feed.Label>
                    <Feed.Content style={{ overflow: 'auto' }}>
                        <Feed.Summary>
                            <Feed.User>{author ? author : obj.author.displayName}</Feed.User>
                            <Feed.Date>{"@ " + truncateDate(obj.updated)}</Feed.Date>
                        </Feed.Summary>
                        {replace}
                    </Feed.Content>
                </Feed.Event>
            )
        }
        )
    }

    const generateAttachmentFeed = (attachments) => {

        return attachments.map(obj =>
            <Feed.Event key={obj.id}>
                <Feed.Label>
                    <img alt={'avatar'} src={obj?.author?.avatarUrls['16x16']} />
                </Feed.Label>
                <Feed.Content>
                    <Feed.Summary>
                        <Feed.User>{obj?.author?.displayName}</Feed.User>
                        <Feed.Date>{"@ " + truncateDate(obj.created)}</Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra>
                        {obj.thumbnail ?
                            <a href={DOMPurify.sanitize(obj.content)}>
                                <img alt='content-thumb' style={{ 'maxWidth': '64px' }} src={DOMPurify.sanitize(obj.content)} />
                            </a>
                            :
                            <a href={DOMPurify.sanitize(obj.content)}>
                                <Icon size='big' name='file alternate' />
                            </a>
                        }
                    </Feed.Extra>
                    <Feed.Meta>{DOMPurify.sanitize(obj.filename)}</Feed.Meta>
                </Feed.Content>
            </Feed.Event>
        )
    }

    const onSubmit = () => {
        const { pid } = match.params
        const cookieValue = getCookieValue('csrftoken');
        const data1 = new FormData()
        data1.append('file', file, file.name)

        handleClose()
        setLoading(true)

        axios.post(`/api/projects/${pid}/consultation/${selected.id}/add-attachment/`,
            data1,
            {
                withCredentials: true,
                headers: { "X-CSRFToken": cookieValue },
            })
            .then(res => {
                setAttachmentFeed(res?.data?.jira?.fields?.attachment) //update UI, refresh defaults
                setLoading(false)
                setFile('')
                updateSelectedCallback(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        const comments = selected?.full?.jira?.fields?.comment?.comments
        const attachments = selected?.full?.jira?.fields?.attachment.map(obj => obj.filename) //substring with names only

        const filtered = comments ? comments.filter((obj) => (
            attachments.every((val) => obj.body.indexOf(val) === -1) //if comment body contains any attachment name, filter it out
        ))
            : []

        setFeed(filtered)
        setAttachmentFeed(selected?.full?.jira?.fields?.attachment)
    }, [selected]);

    const handleDescription = () => displayShort ? setDisplay(false) : setDisplay(true)

    const ellipses = selected?.description.length > 125 ? '...' : ''

    return (
        <Modal size='fullscreen' closeIcon open={open} onClose={onClose} >
            <Modal.Header >
                Consultation Request - [{selected?.full?.jira_ticket_id}]
            </Modal.Header>
            <Modal.Content>
                <Segment style={{ minHeight: '175px' }}>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={12}>
                                <List size='medium'>
                                    <List.Item>
                                        <Label horizontal>Creation Date</Label>
                                        {selected.date}
                                    </List.Item>
                                    <List.Item>
                                        <Label horizontal>Status</Label>
                                        {selected?.full?.jira?.fields?.customfield_10010?.currentStatus?.status}
                                    </List.Item>
                                    <List.Item>
                                        {displayShort ?
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selected?.description.substring(0, 125)) + ellipses }} /> :
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selected?.description) }} />
                                        }
                                        <br />
                                        {
                                            selected?.description.length > 125 &&
                                            <Button
                                                hidden={true}
                                                icon={displayShort ? 'angle down' : 'angle up'}
                                                color='blue'
                                                size='mini'
                                                onClick={handleDescription}
                                                content={displayShort ? 'Show' : 'Hide'}
                                            />
                                        }

                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Participant
                                    selectedConsultation={selected}
                                    updateParticipantCallback={updateSelectedCallback}
                                    users={users}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>


                <Segment>
                    <Grid divided>
                        <Grid.Row columns={2}>
                            <Grid.Column width={10}>
                                <strong><h4>Comments</h4></strong>
                                <hr />
                                <Feed style={{ height: '400px', overflowY: 'auto' }}>
                                    {feed.length > 0 ? generateCommentFeed(feed) : <Label content='Your consultation has no current comments.' icon='file text' />}
                                </Feed>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <strong><h4>Attachments</h4></strong>
                                <hr />
                                <Feed style={{ height: '400px', overflowY: 'auto' }}>
                                    {attachmentFeed.length > 0 ? generateAttachmentFeed(attachmentFeed) : <Label content='Your consultation has no current attachments.' icon='unlink' />}
                                </Feed>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Form>
                    <Form.Field>
                        <Dimmer active={loading} inverted>
                            <Loader inverted className='workaround' inline content='Submitting' />
                        </Dimmer>
                        <Form.TextArea
                            value={comment}
                            onChange={(e, { value }) => setComment(value)}
                            placeholder={`Add a comment ... \nComments will be submitted on behalf of an rit-support account`}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Popup
                            style={{ width: '300px' }}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={isOpen}
                            position='top right'
                            wide
                            trigger={<Button icon='attach' positive content="Attach" />}
                            on='click'
                            content={
                                <div>
                                    <Segment placeholder
                                        content={
                                            <div>
                                                <Header as='h5' textAlign='center' icon>
                                                    <Icon name='file outline' />
                                                    {file ? truncateStringEllipses(file.name, 20) : 'Choose File to Upload'}
                                                </Header>
                                                <Button
                                                    primary
                                                    content='Add File'
                                                    onClick={() => fileInputRef.current.click()}
                                                />
                                            </div>
                                        }
                                    />
                                    <Button
                                        fluid
                                        content='Submit'
                                        floated="left"
                                        icon="upload"
                                        positive
                                        disabled={file ? false : true}
                                        onClick={onSubmit}
                                    />
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        hidden
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </div>
                            }
                        />
                        <Button
                            primary
                            content='Submit'
                            onClick={onSave}
                            disabled={comment.length > 0 ? false : true}
                        />

                    </Form.Field>
                </Form>
            </Modal.Actions>
        </Modal>
    )
}

ConsultationDetailModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selected: PropTypes.object,
    match: PropTypes.object,
    updateSelectedCallback: PropTypes.func,
    users: PropTypes.array
}

