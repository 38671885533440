import React from 'react'
import {
    Modal
} from 'semantic-ui-react';
import {BasicTable} from '../../../BasicTable'

import PropTypes from 'prop-types'

export const UnlinkedRedcap = ({open, onClose, data, columns, subHeaderComponent, customSort}) => {
    if(data) {
        return (
            <Modal size='fullscreen' closeIcon open={open} onClose={onClose} >
                <Modal.Content>
                    <BasicTable
                        title="Add a new REDCap project link"
                        subHeaderComponent = {subHeaderComponent}
                        columns={columns}
                        data={data}
                        sortFunction={customSort}
                    />
                </Modal.Content>
            </Modal>
        )
    } else {
        return ''
    }
}

UnlinkedRedcap.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    unlinked_projects : PropTypes.array,
    columns: PropTypes.array,
    subHeaderComponent: PropTypes.element,
    customSort: PropTypes.func
}