import React, { Component } from 'react';
import {Container, Card, Progress} from 'semantic-ui-react';
import Header from '../../components/Header';
import {Redirect} from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import {WizardHome} from './Steps/WizardHome'
import {WizardIrb} from './Steps/WizardIrb'
import {WizardUser} from './Steps/WizardUser'
import {WizardCreateProject} from './Steps/WizardCreateProject'
import PropTypes from 'prop-types'
import { GlobalContext } from '../../context';


class ProjectWizard extends Component{
    static contextType = GlobalContext

    constructor(props){
        super(props);
        this.state = {
            irb : '',
            form: {},
            sections: ['Home'],
            progress: '0'
        }
    }

    updateForm = (key, value) => {
        const { form } = this.state;
        form[key] = value;
        this.setState({ form });
    }

    updateProgress = (percentage) => {
        this.setState({progress: percentage})
    }

    render(){
        if(!this.context.isAuthenticated)
            return (<Redirect to='/'/>)
        
        const parentProps = {
            updateProgress: this.updateProgress,
            update: this.updateForm,
            form: this.state.form
        }

        return (
            <>
                <Header {...this.props}/>
                <Container>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>Project Wizard</Card.Header>
                        </Card.Content>
                        <Progress attached='top' percent={this.state.progress} success></Progress>
                        <Card.Content>
                            <StepWizard isLazyMount initialStep={1}>
                                {/* isLazyMount prevents each child component from being constructed and mounted immediately */}
                                {/* form must be passed explicitly to these steps to recall prior entered information */}
                                <WizardHome 
                                    step={1} 
                                    {...parentProps}
                                    {...this.props} 
                                />
                                <WizardIrb 
                                    step={2} 
                                    {...parentProps}
                                    // update={this.updateForm} 
                                    // form={this.state.form} 
                                    {...this.props}  
                                />
                                <WizardUser 
                                    step = {3} 
                                    {...parentProps}
                                    // form={this.state.form} 
                                    // update = {this.updateForm} 
                                    {...this.props}
                                />
                                <WizardCreateProject
                                    step = {4} 
                                    {...parentProps}
                                    // update = {this.updateForm} 
                                    // form={this.state.form}
                                    {...this.props}  
                                />
                            </StepWizard>
                        </Card.Content>
                    </Card>
                </Container>
            </>
        );
    }
}

ProjectWizard.propTypes = {
    Auth: PropTypes.object,
    history: PropTypes.object,
}



export default ProjectWizard;