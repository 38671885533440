import React, { Component } from 'react';
import {
    Message, Button,
    Segment, Label, Input
} from 'semantic-ui-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { DetailModal } from './Modals'
import { getCookieValue } from '../../../utils'
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import { truncateDate, usFormatDate } from '../../../utils'
import './TabStatement.css'
import qs from 'qs';
import memoize from 'memoize-one';
import { TableLoader } from '../../TableLoader';
import { NoDataComponent } from '../../NoDataComponent';
import UserChargeModal from './Modals/userChargeModal';


class TabStatement extends Component {
    constructor(props) {
        super(props)
        const { search } = this.props.location
        const params = new URLSearchParams(search)

        this.state = {
            loading: true,
            loading_modal: false,
            searchValue: '',
            sow: '',
            error: '',

            pta_number: '',
            signature: '',
            submission_comment: '', //used for reject, approve and revise
            selected_sow_id: '',
            cachedData: [],
            // Modal functionality 
            detail_modal_open: false,
            charge_modal_open: false, 
            data: [], //tabular data to display
            table: [],
            fullData: [], //Full data json

            detailId: parseInt(params.get('id')) || null
        }
    }

    componentDidMount() {
        var { pid } = this.props.match.params

        axios.get(`/api/projects/${pid}/sow/`, { withCredentials: true })
            .then(res => {
                this.formatTableData(res.data)
            })
            .catch(err => {
                if (err.response.status !== 403)
                    this.setState({ loading: false, error: err.message }) //Render actual error text
                else
                    this.setState({ loading: false }) //forbidden, users do not have access, display nothing
            })
    }

    /** Creates a row object for dataTable based on passed in statement of work object
     * @param sow - Statement of work object
     * @return {id, title, status, date, update}
     */
    createTableRow = (sow) => {
        if (sow) {
            return {
                id: sow.id,
                title: sow.title,
                status: this.renderStatusText(sow.status),
                date: truncateDate(sow.created_at),
                update: sow.updated_at ? truncateDate(sow.updated_at) : 'N/A',
                start_date: sow.start_date ? usFormatDate(sow.start_date) : 'N/A',
                end_date: sow.end_date ? usFormatDate(sow.end_date) : 'N/A',
                full: sow
            }
        } else {
            return {}
        }

    }

    /** Callback function that parses the return of the SOW GET endpoint and sets corresponding state
     * @param data - Object returned from axios get SOW endpoint
     */
    formatTableData = (data) => {
        var fillTable = []
        for (var i = 0; i < data.length; i++) { //parse through SOW's
            let row = this.createTableRow(data[i])
            fillTable.push(row)
        }

        let exists = data.filter((e) => parseInt(e.id) === this.state.detailId)
        if (this.state.detailId && exists.length > 0) {
            this.setState({ data: fillTable, loading: false, fullData: data, cachedData: fillTable, detail_modal_open: true, selected_sow_id: this.state.detailId })
        }
        else
            this.setState({ data: fillTable, loading: false, fullData: data, cachedData: fillTable })
    }

    /** Function that opens / closes Detail Modal via state update
     * @param selected - Statement of work Object represented selected onClick
     */
    showDetailModal = (e, arg) => {
        if (!arg) { //Closing the modal
            this.setState({
                detail_modal_open: false,
                submission_comment: '',
                signature: '',
                // pta_number: ''
            })
        } else { //Otherwise open and pass selected id
            this.setState({ detail_modal_open: true, selected_sow_id: arg.selected.id })
        }
    }

    showChargeModal = (e, arg) => {
        if (!arg){
            this.setState({charge_modal_open: false, selected_sow_id : ''})
        } else {
            this.setState({charge_modal_open: true, selected_sow_id: arg.selected.id})
        }
    }

    /** Function that manages the state of input fields on children
     * @param {value} : the value of [name] to be updated to
     * @param {name} : the name of the variable to be updated
     */
    onChange = (e, { value, name }) => {
        this.setState({ [name]: value })
    }

    // /** Returns a label with a corresponding message depending on SOW status int
    //  * @param status : integer representing status
    //  * @param title : title message to display
    //  */
    // renderLabel = (status, title) => {
    //     switch (status) {
    //         case 0:
    //             return <Label color='yellow'>{title}</Label>
    //         case 1:
    //             return <Label color='green'>{title}</Label>
    //         case 2:
    //             return <Label color='red'>{title}</Label>
    //         default:
    //             return <Label color='yellow'>{title}</Label>
    //     }
    // }

    /** Returns a status text based on integer
     * @param status : integer representing status
     * possible options:
     *      PENDING = 1
     APPROVED = 2
     REQUEST_REVISION = 3
     DENIED = 4
     DONE = 5
     *
     */
    renderStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Pending'
            case 2:
                return 'Approved' //Pending development
            case 3:
                return 'Revision Requested'
            case 4:
                return 'Denied'
            case 5:
                return 'Done'
            case 6:
                return 'Approved' //Active development
            case 7:
                return 'Approved for Maintenence'
            case 8:
                return 'Internal Review'
            default:
                return 'Pending'
        }
    }

    /**
     * Callback function that submits a ajax for an SOW Event
     * @param {Integer} status : 2 (Accept) 3 (Revise) 4 (Reject)
     * @param {object} pta : {registeredPta : '', newPta : ''} registered will be the ID of a finance object, new will be the pta # (only one will be set)
     */
    manageSOW = (status, pta='') => {
        var { pid } = this.props.match.params
        const { signature, selected_sow_id, submission_comment } = this.state
        const cookieValue = getCookieValue('csrftoken');
        axios({
            method: 'post',
            url: `/api/projects/${pid}/sow/${selected_sow_id}/approve/`,
            data: qs.stringify({
                status: status,
                pta: pta?.newPta ?? undefined,
                registered_pta: pta?.registeredPta ?? undefined,
                signature: signature,
                comment: submission_comment
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken": cookieValue,
            }
        }).then((res) => {
            this.updateStatementRow(res?.data)
        })
            .catch((err) => {
                this.setState({
                    error: err.response.data.message,
                    detail_modal_open: false,
                    loading_modal: false
                })
            })

    }


    updateStatementRow = (res) => {
        let { data, fullData } = this.state
        let findx = fullData.findIndex((obj) => obj.id === res.id)
        let indx = data.findIndex(obj => obj.id === res.id)

        data[indx] = this.createTableRow(res) //create new row for data array for table
        fullData[findx] = res //update the state with the new record

        this.setState({
            fullData: fullData,
            data: [...data],
            detail_modal_open: false,
            loading_modal: false,
            signature: ''
        })
    }

    /**
     * Function called on SOW comment submission to update the fullData object
     * @param {obj} res 
     */
    updateSelectedCallback = (res) => {
        let { fullData } = this.state
        let findx = fullData.findIndex((obj) => obj.id === res.sow_id)
        fullData[findx]['comments'] = res?.jira?.comments //update the state with the new record
        this.setState({ fullData: fullData })
    }

    /** Returns a message with the following attributes
     * @param header - Header text
     * @param message - Content body
     * @param type - type of message, determines color, likely 'error'
     */
    renderMessage = (header, message, type) => {
        return (
            <Message
                header={header ? header : ''}
                content={message}
                {...{ [type]: true }} //add type to props
            />
        )
    }

    searchTable = (e, arg) => {
        const filterText = e.target.value.toLowerCase()
        const filtered = this.state.cachedData.filter(entry =>
            (entry.title && entry.title.toLowerCase().includes(filterText)) ||
            (entry.status && entry.status.toLowerCase().includes(filterText)) ||
            (entry.date && entry.date.toLowerCase().includes(filterText)) ||
            (entry.update && entry.update.toLowerCase().includes(filterText)) ||
            (entry.start_date && entry.start_date.toLowerCase().includes(filterText)) ||
            (entry.end_date && entry.end_date.toLowerCase().includes(filterText)))
        this.setState({ data: filtered })
    }


    getColumns = memoize(() => 
    [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            maxWidth: '100px',
            hide: 'md'
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Creation Date',
            selector: row => row.date,
            sortable: true,
            wrap: true
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,
            sortable: true,
            hide: 'md'
        },
        {
            cell: row => 
                <>
                    <Button selected={row} size='mini' content='Details' onClick={this.showDetailModal} />
                    {row?.full?.charge_item_sow_id__charge_id__count > 0 && <Button selected={row} size='mini' content='Charges' onClick={this.showChargeModal}/>}
                </>,
            compact: true
        }
    ])

    getStyle = memoize(() => [
        {
            when: row => row.status === 'Pending',
            style: {
                backgroundColor: '#FFF7D2',
                color: 'black',
            }
        },

    ])

    render() {
        const {
            detail_modal_open, loading, signature, loading_modal, data,
            error, submission_comment, selected_sow_id, fullData,
            charge_modal_open
        } = this.state
        return (
            <Segment attached='bottom'>
                {error ? this.renderMessage('Error', error, 'error') : ''}
                <div>
                    <Input
                        onChange={this.searchTable}
                        placeholder='Filter ...'
                        icon='search'
                        name='search-sow'
                    />
                </div>

                <DataTable
                    title="Statements of Work"
                    defaultSortField="date"
                    defaultSortAsc={false}
                    columns={this.getColumns()}
                    data={data}
                    pagination
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    progressComponent={<TableLoader loadingText='Loading...' active={loading} />}
                    progressPending={loading}
                    conditionalRowStyles={this.getStyle()}
                    noDataComponent={<NoDataComponent text='There are no Statements of Work currently affiliated with this project' />}
                />

                <DetailModal
                    open={detail_modal_open}
                    loading_modal={loading_modal}
                    showModal={this.showDetailModal}
                    onChange={this.onChange} //need to pass down for stepWizard forms

                    manageSOW={this.manageSOW}
                    //Fields for accept Step
                    signature={signature}

                    //Fields for reject Step
                    submission_comment={submission_comment}

                    data={fullData.find((val) => val.id === selected_sow_id)} //Full data json

                    //Extraneous functions
                    renderStatusText={(data) => this.renderStatusText(data)}
                    handleParentModalState={this.props.handleParentModalState}
                    updateSelectedCallback={this.updateSelectedCallback}
                    {...this.props}
                />
                {selected_sow_id && 
                    <UserChargeModal 
                        open={charge_modal_open}
                        onChange={this.showChargeModal}
                        sow={fullData.find((val)=>val.id === selected_sow_id)}
                    />
                }
            </Segment>
        )
    }
}

export default TabStatement

TabStatement.propTypes = {
    match: PropTypes.object,
    handleParentModalState: PropTypes.func
}

