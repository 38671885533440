import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Loader, Dimmer, Message, Dropdown, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getCookieValue } from '../../../utils';
import axios from 'axios';
import qs from 'qs';

export const GlobalTicketModal = ({ open, onClose, callback, handleLoader}) => {
    const [summary, setSummary] = useState('')
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')
    const [projects, setProjects] = useState([]) //List of all projects by user
    const [redcapProjects, setRedcapProjects] = useState([]) //List all redcap projects by user
    const [project, setProject] = useState('') //project id
    const [redcap, setRedcap] = useState('') //redcap project id
    const [options, setOptions] = useState('')
    const [dimmer, setDimmer] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        axios.all([
            axios.get('/api/jira/request-types/'),
            axios.get('/api/projects/'),
            axios.get(`/api/redcap/`)
        ]).then(axios.spread((types, projects, redcapProjects) => {
            setOptions(types?.data?.values)
            //Create non-applicable options for users, make all fields mandatory
            let notApplicable = { project_id: 0, id: 0, dropdownInfo: 'Not applicable', project_name: 'Not applicable' }
            setProjects([notApplicable, ...projects?.data])
            if (redcapProjects?.data) {
                let redcapProjectChoices = []
                redcapProjectChoices.push(notApplicable) //Add not applicable option to redcap project dropdown
                for (let instance in redcapProjects.data) {
                    for (let project of redcapProjects.data[instance]) {
                        project.dropdownInfo = `(${project.project_id}) ${project.project_name}`
                        redcapProjectChoices.push(project)
                    }
                }

                setRedcapProjects(redcapProjectChoices)
            }
            handleLoader()

        })).catch(err => {
			setError('Error: Something went wrong with your fetch requests, linking functionality will temporarily be disabled')
			setDimmer(false)
			handleLoader()
        })


    }, []);


    const onExit = () => {
        setSummary('')
        setType('')
        setProject('')
        setDescription('')
        setRedcap('')
        setError('')
        onClose()
    }

    const onInputChange = (e, { name, value }) => {
        switch (name) {
            case 'summary':
                setSummary(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'ticketType':
                setType(value);
                break;
            case 'project':
                setProject(value);
                break;
            case 'redcap':
                setRedcap(value);
                break;
            default:
                break;
        }
    }

    const submitIssue = () => {
        const cookieValue = getCookieValue('csrftoken');
        const url = project && project !== 0 ? `/api/projects/${project}/add-issue/` : `/api/issues/add-issue/` //Url has to change if project is requested
        const findRedcapProject = redcapProjects.find(el => el.project_id === redcap.toString())
        const check = [
            type.length ? true : false,
            summary.length ? true : false,
            description.length ? true : false,
            project !== '',
            redcap !== ''
        ]

        if (check.indexOf(false) !== -1) {
            setError('Please select an option for each field')
        } else {
            setDimmer(true)

            axios({
                method: 'post',
                url: url,
                data: qs.stringify({
                    'request_type': type,
                    'summary': summary,
                    'description': description,
                    'redcap': redcap ? redcap : undefined,
                    'redcap_name': findRedcapProject ? findRedcapProject['project_name'] : undefined
                }),
                headers: {
                    "X-CSRFToken": cookieValue,
                    "content-type": "application/x-www-form-urlencoded"
                }
            })
                .then((res) => {
                    setDimmer(false)
                    callback(res)
                })
                .catch(err => {
                    setError(JSON.stringify(err?.response?.data))
                    setDimmer(false)
                })
        }


    }

    const renderDropdowns = () => {
        return (
            <>
                <Form.Dropdown
                    required
                    name='project'
                    options={(projects || []).map(val => ({ text: val.project_name, value: val.id }))}
                    selection
                    label='Affiliated R2P2 Project'
                    placeholder='Select a R2P2 project this issue pertains to if applicable'
                    onChange={onInputChange}
                    value={project}
                    clearable
                    search
                    disabled={projects.length ? false : true}
                />
                <Form.Dropdown
                    style={{ marginRight: '25px' }}
                    name={'redcap'}
                    options={(redcapProjects || []).map(val => ({ text: val.dropdownInfo, value: val.project_id }))}
                    selection
                    clearable
                    search
                    required
                    disabled={!redcapProjects.length}
                    label={'Affiliated REDCap Project'}
                    onChange={onInputChange}
                    value={redcap}
                    placeholder='Select a REDCap project this issue pertains to if applicable'
                />
            </>
        )
    }
    return (
        <Modal size='large' closeIcon open={open} onClose={onExit} >
            <Modal.Header>Submit a support ticket</Modal.Header>
            <Modal.Content>
                {error && <Message error content={error} />}
                <Form >
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label>Summary / Title</label>
                            <Input
                                fluid
                                name={'summary'}
                                onChange={onInputChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Issue Type</label>
                            <Dropdown

                                fluid
                                name={'ticketType'}
                                options={(options || []).map(val => ({ text: val.name, value: val.id }))}
                                selection
                                onChange={onInputChange}
                                width={6}
                                value={type}
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.TextArea
                        name={'description'}
                        onChange={onInputChange}
                        label={'Detailed description of issue'}
                        required
                    />
                    <Form.Group widths='equal'>
                        {renderDropdowns()}
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Dimmer active={dimmer} inverted>
                    <Loader inverted>Submitting</Loader>
                </Dimmer>
                <Button
                    primary
                    content='Submit'
                    onClick={submitIssue}
                />
            </Modal.Actions>
        </Modal>
    )
}

GlobalTicketModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    callback: PropTypes.func,
    dimmerOpen: PropTypes.bool
}
